import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { useDispatch, useSelector } from "react-redux";
import { setIpPayload, setPayloadForTimeAndIp } from "../../../../redux/slice/IPSlice";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../../services/storage/Storage";
import Loader from "../../../../layouts/component/Loader";
import { getUsersList } from "../../../dashboard/user/user-dashboard/groups/modal/ListOfUserHelperFile";
import { getUserGroup } from "./IpRestrictionUtils";
import { columnsIp, groupColumnsIp } from "./SelectIpTableColumns";
import SuccessMessageModal from "../IpTimeSuccessModal";
import CloseConfirmationModal from "../Model/CloseConfirmationModal";
import GroupAndUserNecessaryModal from "../Model/GroupAndUserNecessaryModal";
import { setListOfRestrictionModal } from "../../../../redux/slice/Users/UserDashboardUpdateSlice";
import AccessManagerBackNavigationArrow from "../AccessManagerBackNavigationArrow";
import SelectIPAddForm from "./SelectIPAddForm";
import SelectIPEditForm from "./SelectIPEditForm";
import "./selectIP.scss";

const SelectIP = () => {
  const isActiveEdit = true;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const createIpRestrictionApi = useGetApiRequests("createIpBasedRestriction", "POST");
  const getUserList = useGetApiRequests("userManagement", "POST");
  const updateIpRestriction = useGetApiRequests("updateIpRestriction", "PUT");
  const getGroupsList = useGetApiRequests("getGroupsList", "GET");

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const isEditFromUrl = params.get("isEdit");
  const type = params.get("type");
  const navigatedFrom = params.get("from");
  const editIpDetails = useSelector((state: any) => state?.IpRestrictionSlice?.editRecordDetails);
  const ipFullDetails = useSelector((state: any) => state?.IpRestrictionSlice?.getParticularIpInfo);
  const savedPayloadForIp = useSelector((state: any) => state?.IpRestrictionSlice?.storePayload);
  const deviceRestrictionList: any = useSelector((state: any) => state?.DeviceRestrictionSlice?.deviceRestrictionList);

  const [loader, setLoader] = useState<boolean>(false);
  const [authorizationType, setAuthorizationType] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [usersSearchList, setUsersSearchList] = useState<any>([]);
  const [selectedObjectList, setSelectedObjectList] = useState<any>([]);
  const [listOfUsers, setListOfUsers] = useState<any>([]);
  const [updatedUserList, setUpdatedUserList] = useState<any>([]);
  const [updatedUser, setUpdatedUser] = useState<any>([]);
  const [deletedUsers, setDeletedUsers] = useState<any>([]);
  const [groupData, setGroupData] = useState<any>([]);
  const [userSearchGroupData, setUserSearchGroupData] = useState<any>([]);
  const realmId = retrieveData("realmId", true);
  const [editGroupRecords, setEditGroupRecords] = useState<any>([]);
  const [ipErrorMessage, setIpErrorMessage] = useState<any>("");
  const [assignToValue, setAssignToValue] = useState("");
  const [ipSuccessModal, setIpSuccessModal] = useState(false);
  const [ipSuccessMessage, setIpSuccessMessage] = useState<any>();
  const [openCloseModal, setOpenCloseModal] = useState(false);
  const [atLeastOneModal, setAtLeastOneModal] = useState(false);
  const [removedIp, setRemovedIp] = useState<any>([]);

  const [initialValues, setInitialValues] = useState<any>({
    name: "",
    description: "",
    authorizationType: "Whitelist",
    ipDetails: [{ ipRange: "", IPv4: "", id: "", IPv6: "" }],
    assignTo: navigatedFrom ? "users" : "",
  });

  const pageSize = 10;
  const currentPage = 0;

  useEffect(() => {
    if (isEditFromUrl === "true") {
      setIsEdit(true);

      setInitialValues(editIpDetails);
      setAuthorizationType(editIpDetails?.authorizationType);

      if (type === "groups") {
        getUserGroup(setLoader, getGroupsList, setGroupData, setUserSearchGroupData, editIpDetails?.groupIds, true, setEditGroupRecords);
      } else if (type === "users") {
        const editUserList = editIpDetails?.userId?.map((item: any) => ({
          key: item,
        }));
        getUsersList(currentPage, pageSize, "", false, setLoader, realmId, getUserList, setUsersSearchList, true, editUserList, "", setListOfUsers, true, setEditGroupRecords);
      }
    } else if (savedPayloadForIp && Object.keys(savedPayloadForIp)?.length > 0) {
      setInitialValues(savedPayloadForIp);
      setAuthorizationType(savedPayloadForIp?.authorizationType);
      setAssignToValue(savedPayloadForIp?.assignTo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseSuccessModalIp = () => {
    setIpSuccessModal(false);
  };
  const handleOpenCloseModal = () => {
    setOpenCloseModal(true);
  };
  const handleCloseConfirmationModal = () => {
    setOpenCloseModal(false);
  };

  const calculateIpInIpRange = (values: any) => {
    let newIpInfo = "";
    let oldIpInfo = "";

    const transformData = values.map((elm: any) => {
      if (elm.ipRange === "range16") {
        newIpInfo = `${elm.IPv4}.0.0/16`;
        oldIpInfo = `${elm.IPv4}.0.0/16`;
      } else if (elm.ipRange === "range24") {
        newIpInfo = `${elm.IPv4}.0/24`;
        oldIpInfo = `${elm.IPv4}.0/24`;
      } else {
        newIpInfo = `${elm.IPv4}`;
        oldIpInfo = `${elm.IPv4}`;
      }
      return {
        ipRange: elm.ipRange !== "exactIp",
        newIpInfo: newIpInfo,
        oldIpInfo: oldIpInfo,
        ipInfoV6: elm.IPv6.length !== 0 ? `${elm.IPv6}` : null,
      };
    });
    return transformData;
  };
  const calculateIpInIpRange1 = (values: any) => {
    let ipInfo = "";

    const transformData = values.map((elm: any) => {
      if (elm.ipRange === "range16") {
        ipInfo = `${elm.IPv4}.0.0/16`;
      } else if (elm.ipRange === "range24") {
        ipInfo = `${elm.IPv4}.0/24`;
      } else {
        ipInfo = `${elm.IPv4}`;
      }
      return {
        ipRange: elm.ipRange !== "exactIp",
        ipInfo: ipInfo,

        ipInfoV6: elm.IPv6?.length !== 0 ? `${elm.IPv6}` : null,
      };
    });
    return transformData;
  };
  const splitIp = (initIpDetails: any) => {
    const transformData = initIpDetails.map((item: any) => {
      let ipInfo = "";
      if (item.new !== true) {
        if (item.ipRange === "range16") {
          ipInfo = `${item.IPv4}.0.0/16`;
        } else if (item.ipRange === "range24") {
          ipInfo = `${item.IPv4}.0/24`;
        } else {
          ipInfo = `${item.IPv4}`;
        }
        return {
          ipRange: item.ipRange !== "exactIp",
          ipInfo: ipInfo,
          ipInfoV6: item.IPv6.length !== 0 ? `${item.IPv6}` : null,
        };
      }
    });
    return transformData;
  };
  function calculateIpInfoAndIpRange(values: any, initIpDetails: any) {
    let transformData: any;
    if (values.length !== 0 && values?.ipDetails?.length !== 0) {
      const transformData = values.ipDetails.map((item: any) => {
        const oldIp = values.oldIp.find((elm: any) => elm.id === item.id);

        // Determine the new and old IP information
        let newIpInfo = "";
        let oldIpInfo = "";
        if (!item.new) {
          if (item.ipRange === "range16") {
            newIpInfo = `${item.IPv4}.0.0/16`;
            oldIpInfo = oldIp ? `${oldIp.IPv4}.0.0/16` : "";
          } else if (item.ipRange === "range24") {
            newIpInfo = `${item.IPv4}.0/24`;
            oldIpInfo = oldIp ? `${oldIp.IPv4}.0/24` : "";
          } else {
            newIpInfo = `${item.IPv4}`;
            oldIpInfo = oldIp ? `${oldIp.IPv4}` : "";
          }

          // Construct the transformed object
          const obj = {
            ipRange: item.ipRange !== "exactIp",
            newIpInfo: newIpInfo,
            oldIpInfo: oldIpInfo,
            ipInfoV6: item.IPv6.length !== 0 ? `${item.IPv6}` : null,
          };

          return obj;
        }
        //

        if (item.new) {
          if (item.ipRange === "range16") {
            newIpInfo = `${item.IPv4}.0.0/16`;
            oldIpInfo = oldIp ? `${oldIp.IPv4}.0.0/16` : "";
          } else if (item.ipRange === "range24") {
            newIpInfo = `${item.IPv4}.0/24`;
            oldIpInfo = oldIp ? `${oldIp.IPv4}.0/24` : "";
          } else {
            newIpInfo = `${item.IPv4}`;
            oldIpInfo = oldIp ? `${oldIp.IPv4}` : "";
          }

          // Construct the transformed object
          const obj = {
            ipRange: item.ipRange !== "exactIp",
            newIpInfo: newIpInfo,
            oldIpInfo: oldIpInfo,
            ipInfoV6: item.IPv6.length !== 0 ? `${item.IPv6}` : null,
            new: true,
          };

          return obj;
        }
      });
      return transformData;
    } else {
      transformData = splitIp(initIpDetails);
    }

    return transformData;
  }
  const filterRangeType = (elm: any) => {
    const filteredData = elm.map((item: any) => {
      let ipInfo = "";
      if (item.ipRange === "range16") {
        ipInfo = `${item.IPv4}.0.0/16`;
      } else if (item.ipRange === "range24") {
        ipInfo = `${item.IPv4}.0/24`;
      } else {
        ipInfo = `${item.IPv4}`;
      }
      return {
        ipInfo: ipInfo,
        ipInfoV6: item.IPv6.length !== 0 ? item.IPv6 : null,
        ipRange: item.ipRange !== "exactIp",
      };
    });
    return filteredData;
  };
  // to set old and new ip while edit
  const removeCommonIPv4 = (arr1: any, arr2: any) => {
    const ipv4Set1: any = new Set(arr1.map((item: any) => item.IPv4));
    const ipv4Set2 = new Set(arr2.map((item: any) => item.IPv4));

    const commonIPv4 = new Set([...ipv4Set1].filter((ip) => ipv4Set2.has(ip)));

    const oldIp = arr1.filter((item: any) => {
      const correspondingItem = arr2.find((i: any) => i.IPv4 === item.IPv4);
      return !commonIPv4.has(item.IPv4) || (correspondingItem && item.IPv6 !== correspondingItem.IPv6);
    });

    const newIp = arr2.filter((item: any) => {
      const correspondingItem = arr1.find((i: any) => i.IPv4 === item.IPv4);
      return !commonIPv4.has(item.IPv4) || (correspondingItem && item.IPv6 !== correspondingItem.IPv6);
    });

    return {
      oldIp: oldIp,
      ipDetails: newIp,
    };
  };

  const filterCommonIPv4 = (arr1: any, arr2: any) => {
    // Create a set of IPv4 values from arr1
    const ipv4Set = new Set(arr1.map((item: any) => item.IPv4));

    // Filter arr2 to include only those objects whose IPv4 is in the ipv4Set
    const commonIpDetails = arr2.filter((item: any) => ipv4Set.has(item.IPv4));

    return commonIpDetails;
  };
  const hasIsPresent = (ipDetails: any) => {
    return ipDetails.filter((item: any) => item !== null && item !== undefined && item?.new !== true && item.oldIp !== "") || [];
  };
  const calculateIpInIpRange2 = (values: any) => {
    let newIpInfo = "";

    const transformData = values.map((elm: any) => {
      if (elm.ipRange === "range16") {
        newIpInfo = `${elm.newIpInfo}.0.0/16`;
      } else if (elm.ipRange === "range24") {
        newIpInfo = `${elm.newIpInfo}.0/24`;
      } else {
        newIpInfo = `${elm.newIpInfo}`;
      }
      return {
        ipRange: elm.ipRange,
        ipInfo: newIpInfo,
        ipInfoV6: elm.ipInfoV6 && elm.ipInfoV6.trim() !== "" ? elm.ipInfoV6 : null,
      };
    });
    return transformData;
  };
  const calculateIpInIpRange3 = (values: any) => {
    let newIpInfo = "";

    const transformData = values.map((elm: any) => {
      if (elm.ipRange === "range16") {
        newIpInfo = `${elm.IPv4}.0.0/16`;
      } else if (elm.ipRange === "range24") {
        newIpInfo = `${elm.IPv4}.0/24`;
      } else {
        newIpInfo = `${elm.IPv4}`;
      }
      return {
        ipRange: elm.ipRange !== "exactIp",
        ipInfo: newIpInfo,
        ipInfoV6: elm.IPv6?.length !== 0 ? `${elm.IPv6}` : null,
      };
    });
    return transformData;
  };

  const editIpApi = async (values: any) => {
    const uncommonIp = removeCommonIPv4(editIpDetails.ipDetails, values.ipDetails);
    const allocatedUsers = listOfUsers.map((item: any) => item.value);
    const allocatedGroups = groupData.map((item: any) => item.key);
    const newObjects = values.ipDetails.filter((item: any) => item.new === true);
    const convertedData: any = filterRangeType(newObjects);
    if ((type === "users" && listOfUsers?.length === 0) || (type === "groups" && groupData?.length === 0)) {
      setAtLeastOneModal(true);
    } else {
      setLoader(true);
      const ipDetails = calculateIpInfoAndIpRange(uncommonIp, values.ipDetails);
      const oldIpDetails = calculateIpInIpRange1(editIpDetails.ipDetails);
      const val = filterCommonIPv4(editIpDetails.ipDetails, values.ipDetails);
      const unEditedValues = calculateIpInIpRange(val);
      const filteredDataUpdated = hasIsPresent(ipDetails);
      const addAndEditVal = calculateIpInIpRange3(val);

      dispatch(setPayloadForTimeAndIp(values));
      const combinedIds = initialValues?.userGroupRestrictions.flatMap((item: any) => item?.akkuUserIpBasedAuthorizationIds);
      const filteredData = filteredDataUpdated.filter((item: any) => !item.ipInfo && item.oldIpInfo !== "");
      const addAndEdit = calculateIpInIpRange2(filteredData);
      const conCoatData = [...filteredData, ...unEditedValues];
      const removeDuplicate = [...addAndEdit, ...addAndEditVal];
      const uniqueIpData = removeDuplicate.filter((item, index, self) => index === self.findIndex((t) => t.ipInfo === item.ipInfo));
      const seen = new Set();
      const filteredIpDetails: any = [];
      conCoatData.forEach((item: any) => {
        if (seen.has(item.newIpInfo)) {
          return;
        }
        seen.add(item.newIpInfo);
        filteredIpDetails.push(item);
      });
      const result = allocatedUsers.filter((item: any) => !updatedUser.includes(item));
      const resultGroup = updatedUser.filter((item: any) => !allocatedGroups.includes(item));
      const data = resultGroup.length !== 0 ? resultGroup : allocatedGroups;

      const removeIp = removedIp.filter((item: any) => item !== "");

      let payload: any = {
        akkuUserIpBasedAuthorizationDto: {
          name: values.name,
          description: values.description,
          authorizationType: values.authorizationType,
          ipInfos: uncommonIp.oldIp.length !== 0 ? uniqueIpData : oldIpDetails,
          active: ipFullDetails.isActive,
          realmId: realmId,

          [result.length !== 0 ? "userEntityId" : "keycloakGroupId"]: result.length !== 0 ? result : data,
        },
        ipRestrictionDashboardDto: {
          realmId: realmId,
          authorizationType: values.authorizationType,
          status: ipFullDetails.isActive,
          userRestricted: type === "users",
          groupRestricted: type === "groups",
          organisationRestriction: type === "organization",
          akkuUserIpBasedAuthorizationIds: combinedIds,
        },
        removeIpAddress: removeIp,
        addIpRestriction: updatedUser,
        removeIpRestriction: deletedUsers,
        addIpAddress: convertedData,
        updateIpInfos: filteredIpDetails.length !== 0 ? filteredIpDetails : oldIpDetails,
      };

      if (ipDetails[0] === undefined) {
        payload.updateIpInfos = [];
      }
      if (ipDetails[0]?.new && filteredIpDetails?.length === 0) {
        payload.updateIpInfos = [];
      }

      if (type === "organization") {
        payload.akkuUserIpBasedAuthorizationDto.keycloakGroupId = [];
        payload.akkuUserIpBasedAuthorizationDto.ipInfos = [];
      }

      try {
        const response: any = await updateIpRestriction(payload);
        const status = response.status;
        if (status === 200) {
          setLoader(false);
          setIpSuccessModal(true);
          setIpSuccessMessage(response?.data?.message);
          setTimeout(() => {
            handleCloseSuccessModalIp();
            navigate("/access-manager");
          }, 3000);
        }
      } catch (err: any) {
        setTimeout(() => {
          setLoader(false);
          handleRequestError(err);
        }, 2000);
      }
    }
  };
  const isValidIP = (ip: any) => {
    let segments = ip?.split(".");
    for (let i = 0; i < segments?.length; i++) {
      let num = Number(segments[i]);
      if (isNaN(num) || num < 0 || num > 255) {
        return false;
      }
    }

    return true;
  };
  const handleNewIpSubmission = async (values: any, payload: any) => {
    if (navigatedFrom) {
      const ipRestrictedUser = deviceRestrictionList?.map((item: any) => item?.key);
      payload.userEntityId = ipRestrictedUser;
      payload.realmId = realmId;
    }
    try {
      const response: any = await createIpRestrictionApi(payload);
      const status = response.status;
      if (status === 200) {
        setLoader(false);
        setIpSuccessModal(true);
        setIpSuccessMessage(response?.data?.message);
        setTimeout(() => {
          handleCloseSuccessModalIp();
          navigate("/access-manager");
        }, 3000);
      }
    } catch (err: any) {
      setTimeout(() => {
        setLoader(false);
        handleRequestError(err);
      }, 2000);
    }
  };

  const handlePayloadDispatch = (payload: any, assignTo: string) => {
    dispatch(setIpPayload(payload));
    if (assignTo === "groups") {
      navigate("/access-manager/groups?parameter=ip");
    } else if (assignTo === "users") {
      navigate("/access-manager/assignUsers?parameter=ip");
    }
  };

  const onSubmit = async (values: any) => {
    const validIp = isValidIP(values?.IP);
    if (!validIp) {
      setIpErrorMessage("IP is invalid");
      return;
    }

    if (isEdit && validIp) {
      editIpApi(values);
      return;
    }

    setLoader(true);
    const ipDetails = calculateIpInfoAndIpRange([], values.ipDetails);
    dispatch(setPayloadForTimeAndIp(values));

    let payload: any = {
      name: values.name,
      description: values.description,
      authorizationType: values.authorizationType,
      ipInfos: ipDetails,
      active: true,
      realmId: realmId,
    };
    if (!navigatedFrom) {
      switch (values?.assignTo) {
        case "groups":
        case "users":
          handlePayloadDispatch(payload, values.assignTo);
          break;
        case "organization":
        default:
          await handleNewIpSubmission(values, payload);
          break;
      }
    } else {
      handleNewIpSubmission(values, payload);
    }
  };
  const returnToBack = () => {
    navigate(-1);
  };
  const returnToAccessManager = () => {
    navigate("/access-manager");
  };
  const returnToUserManagement = () => {
    dispatch(setListOfRestrictionModal(true));
    navigate("/user");
  };

  const chooseIpType = (value: any, setFieldValue: any) => {
    if (value === "Blacklist") {
      setFieldValue("assignTo", "organization");
      setAssignToValue("organization");
    } else {
      setFieldValue("assignTo", "");
      setAssignToValue("");
    }
    setFieldValue("authorizationType", value);
    setAuthorizationType(value);
  };
  const chooseAssigningTo = (value: any, setFieldValue: any) => {
    setFieldValue("assignTo", value);
    setAssignToValue(value);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleChangeUsers = (value: string) => {
    setSelectedValues(value);
    const previousValue = [...selectedObjectList];
    const missingIds = previousValue.filter((item) => !value.includes(item.value)).map((item) => item.value);
    const listOfUsersDetails = [...listOfUsers];
    const needToDelete = listOfUsersDetails.filter((item) => !missingIds.includes(item.value));
    setUpdatedUserList(needToDelete);
    setUpdatedUser(value);
  };
  const handleChangeGroups = (value: string) => {
    setSelectedValues(value);
    const previousValue = [...selectedObjectList];
    const missingIdsForGroups = previousValue.filter((item) => !value.includes(item.value)).map((item) => item.value);
    const listOfGroupDetails = [...groupData];
    const needToDelete = listOfGroupDetails.filter((item) => !missingIdsForGroups.includes(item.value));
    setUpdatedUserList(needToDelete);
    setUpdatedUser(value);
  };
  const handleAddUsers = () => {
    if (selectedValues.length > 0) {
      const selectedObjects = usersSearchList.filter((item: any) => selectedValues.includes(item.value));
      setSelectedObjectList(selectedObjects);
      const mergedArray = [...updatedUserList, ...selectedObjects];
      const uniqueMergedArray = Array.from(new Set(mergedArray.map((obj) => obj.value))).map((value) => {
        return mergedArray.find((obj) => obj.value === value);
      });
      setListOfUsers(uniqueMergedArray);
      setDropdownVisible(false);
    } else {
      setDropdownVisible(false);
      setListOfUsers(editGroupRecords);
    }
  };
  const handleAddGroups = () => {
    if (selectedValues?.length > 0) {
      const selectedObjects = userSearchGroupData?.filter((item: any) => selectedValues?.includes(item.value));
      setSelectedObjectList(selectedObjects);
      const mergedArray = [...updatedUserList, ...selectedObjects];
      const uniqueMergedArray = Array.from(new Set(mergedArray?.map((obj) => obj?.value))).map((value) => {
        return mergedArray.find((obj) => obj?.value === value);
      });
      setGroupData(uniqueMergedArray);
      setDropdownVisible(false);
    } else {
      setDropdownVisible(false);
      setGroupData(editGroupRecords);
    }
  };
  const handleUserLabelClick = (event: any, value: any) => {
    event.stopPropagation();
    const newValue: any = [...selectedValues];
    if (newValue.includes(value)) {
      newValue.splice(newValue.indexOf(value), 1);
    } else {
      newValue.push(value);
    }
    setSelectedValues(newValue);
  };
  const filterOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const unassignUser = () => {
    if (type === "groups") {
      const shallowGroupData = [...groupData];
      const reformedGroupList = shallowGroupData?.filter((item: any) => !selectedRowKeys?.includes(item.key));
      const selectedAuthorizationGroupIds = ipFullDetails?.userGroupRestrictions?.filter((item: any) => selectedRowKeys.includes(item?.keycloakGroupId));
      const keycloakGroupIds = selectedAuthorizationGroupIds.map((item: any) => item.akkuUserIpBasedAuthorizationIds);
      const flatArray = keycloakGroupIds.flat();
      const mergeDeleteUserArray = [...deletedUsers, ...flatArray];
      setDeletedUsers(mergeDeleteUserArray);
      setGroupData(reformedGroupList);
    } else {
      const shallowListOfUsers = [...listOfUsers];
      const filteredReformedListNew = shallowListOfUsers?.filter((item: any) => !selectedRowKeys?.includes(item.key));
      const filteredReformedListNew1 = shallowListOfUsers?.filter((item: any) => selectedRowKeys?.includes(item.key));

      const userEntityIds = new Set(filteredReformedListNew1.map((item) => item.value));

      // Filter the first array based on the userEntityId values in the Set
      const filteredArray = initialValues.userGroupRestrictions.filter((item: any) => userEntityIds.has(item.userEntityId));
      const akkuUserIpBasedAuthorizationIds = filteredArray.map((user: any) => user.akkuUserIpBasedAuthorizationIds);

      // Flatten the array of arrays into a single array (if needed)
      const flattenedIds = akkuUserIpBasedAuthorizationIds.flat();

      setDeletedUsers(flattenedIds);
      setListOfUsers(filteredReformedListNew);
    }

    setSelectedRowKeys([]);
  };

  const handleCloseNecessaryModal = () => {
    setAtLeastOneModal(false);
  };
  const getIpSuffix = (ipRange: any) => {
    if (ipRange === "range16") {
      return ".0.0/16";
    } else if (ipRange === "range24") {
      return ".0/24";
    }
    return "";
  };

  const handleRemoveList = (index: any, remove: any, val: any) => {
    const removed = getIpSuffix(val.ipRange);
    remove(index);
    setRemovedIp((prev: any) => [...prev, `${val.IPv4 + removed}`]);
  };

  const getClassName = (index: any) => {
    return index === 0 ? "" : "lg:opacity-0";
  };
  return (
    <div className="akku-container">
      <div className="main-container">
        <div className="dashboard-container h-full select-ip-wrapper">
          <ul className="flex breadcrumb">
            {!navigatedFrom ? (
              <li className="font-Inter pr-1 cursor-pointer" onClick={returnToAccessManager}>
                Access Manager /
              </li>
            ) : (
              <li className="font-Inter pr-1 cursor-pointer" onClick={returnToUserManagement}>
                User Management /
              </li>
            )}
            <li className="font-Inter pr-1 active">{!isEdit ? "Select IP" : "IP Restriction"}</li>
          </ul>
          <div className={`mb-14 mt-4 ${isActiveEdit ? "selectIpEdit-title" : ""}`}>
            <AccessManagerBackNavigationArrow title={!isEdit ? "Select IP" : "Edit - IP Restriction"} />
          </div>
          {!isEdit && (
            <SelectIPAddForm
              props={{
                isEdit: isEdit,
                initialValues: initialValues,
                onSubmit: onSubmit,
                chooseIpType: chooseIpType,
                assignToValue: assignToValue,
                chooseAssigningTo: chooseAssigningTo,
                handleOpenCloseModal: handleOpenCloseModal,
                ipErrorMessage: ipErrorMessage,
                navigatedFrom: navigatedFrom,
              }}
            />
          )}
          {isEdit && (
            <SelectIPEditForm
              props={{
                isEdit: isEdit,
                initialValues: initialValues,
                onSubmit: onSubmit,
                type: type,
                authorizationType: authorizationType,
                chooseIpType: chooseIpType,
                getClassName: getClassName,
                ipErrorMessage: ipErrorMessage,
                setDropdownVisible: setDropdownVisible,
                dropdownVisible: dropdownVisible,
                handleChangeGroups: handleChangeGroups,
                filterOption: filterOption,
                userSearchGroupData: userSearchGroupData,
                selectedValues: selectedValues,
                handleUserLabelClick: handleUserLabelClick,
                handleChangeUsers: handleChangeUsers,
                usersSearchList: usersSearchList,
                groupData: groupData,
                listOfUsers: listOfUsers,
                selectedRowKeys: selectedRowKeys,
                rowSelection: rowSelection,
                groupColumnsIp: groupColumnsIp,
                columnsIp: columnsIp,
                handleRemoveList: handleRemoveList,
                unassignUser: unassignUser,
                handleAddGroups: handleAddGroups,
                handleAddUsers: handleAddUsers,
              }}
            />
          )}
        </div>
      </div>
      {loader && <Loader />}
      {atLeastOneModal && <GroupAndUserNecessaryModal handleCloseNecessaryModal={handleCloseNecessaryModal} openNecessaryModal={atLeastOneModal} type={type === "groups" ? "group" : "user"} />}
      {openCloseModal && <CloseConfirmationModal closeModalOpen={openCloseModal} handleProceed={returnToBack} handleCancel={handleCloseConfirmationModal} />}
      {ipSuccessModal && <SuccessMessageModal open={ipSuccessModal} handleModalClose={handleCloseSuccessModalIp} responseMessage={ipSuccessMessage} />}
    </div>
  );
};

export default SelectIP;
