import { Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import CustomButtonNext from "../../../../../layouts/component/CustomButton";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";

const ViewCertificateModal = (props: any) => {
  const { openModal, handleClose, certificateDetail } = props;
  const [open, isOpen] = useState<boolean>(false);

  const handleCloseModal = () => {
    handleClose();
  };
  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: "success",
      content: "Copied!",
    });
  };
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(certificateDetail).then(() => {
      success();
    });
  };
  useEffect(() => {
    isOpen(openModal);
  }, [openModal]);
  return (
    <Modal className="p-16  show-notification  _suspended rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleCloseModal}>
      <div className="modal-body  p-4 ">
        <p>{certificateDetail}</p>
      </div>
      <div className="modal-footer ">
        <div className="w-full flex justify-center">
          <CustomButtonBack text={"Close"} onClick={handleCloseModal} />

          <CustomButtonNext onClick={handleCopyToClipboard} text={"Copy"} />
        </div>
        {contextHolder}
      </div>
    </Modal>
  );
};

export default ViewCertificateModal;
