import * as Yup from "yup";

export const getStrengthMessage = (value: number) => {
  switch (value) {
    case 9:
      return "Good";
    case 10:
      return "Strong";
    case 11:
      return "Recommended";
    case 12:
      return "Super Strong";
    case 13:
      return "Unnecessarily Strong";
    default:
      return "";
  }
};

export const passwordManagerValidationSchema = Yup.object().shape({
  loginAttempts: Yup.number().required("Login attempt is required"),
});

export const attemptsLists = [
  { label: "3 (Recommended)", value: 3 },
  { label: "5", value: 5 },
  { label: "7", value: 7 },
];
