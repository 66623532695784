import React, { useState } from "react";
import { Modal } from "antd";
import ImageView from "../../../../layouts/component/ImageView";
import CustomButtonBack from "../../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../../layouts/component/CustomButton";
import { images } from "../../../../assets/images/ImagePaths";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { triggerNotification } from "../../../../layouts/toast/ToastBar";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { useDispatch, useSelector } from "react-redux";
import { setAppRender } from "../../../../redux/slice/appsRenderSlice";

const DeleteProvisioningApp = (props: any) => {
  const deleteProvisioningApp = useGetApiRequests("deleteProvisioningApp", "DELETE");
  const deleteAppList = useGetApiRequests("deleteAppList", "DELETE");

  const { handleClose, connectorId, open, items } = props;
  const [loader, setLoader] = useState<boolean>(false);
  const dispatch = useDispatch();
  const reloadAppStore = useSelector((state: any) => state?.provisioning?.reloadAppStore);

  const reInitiateApps = useSelector((state: any) => state?.appsRenderSlice?.appRender);

  const handleDelete = async () => {
    setLoader(true);

    try {
      if (items?.akkuProvisioningConnectorDto?.akkuProvisioningConnectorId) {
        await handleDeleteProvision();
      }

      if (items?.akkuClientDto?.akkuClientId) {
        await handleDeleteSso();
      }

      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const handleDeleteProvision = async () => {
    const deleteProvisioning = {
      connectorId: connectorId,
    };
    try {
      handleClose();
      const response: any = await deleteProvisioningApp("", {}, deleteProvisioning);
      const status = response?.status;
      if (status === 200) {
        const data = response?.data;
        // setLoader(false);
        dispatch(setAppRender(!reInitiateApps));
        triggerNotification("success", "", data?.message, "topRight");
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const handleDeleteSso = async () => {
    const akkuClientId = items?.akkuClientDto?.akkuClientId;
    try {
      handleClose();
      const response: any = await deleteAppList("", "", [akkuClientId]);
      const status = response?.status;
      if (status === 200) {
        const data = response?.data;
        triggerNotification("success", "", data?.message, "topRight");
        dispatch(setAppRender(!reInitiateApps));
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const handleCloseModal = () => {
    handleClose();
  };

  return (
    <Modal className=" show-notification  _suspended rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleCloseModal}>
      <div className="modal-body  pt-4 ">
        <div className="flex w-full justify-center items-center flex-col">
          <ImageView src={images.notificationDelete} alt="notificationDone" className="delete-apps" />
          <h5 className="text-[18px] font-Inter pt-3 font-semibold text-center">Are you sure you want to delete the provisioning and SSO connector?</h5>
        </div>
      </div>
      <div className="modal-footer pt-10">
        <div className="w-full flex justify-center">
          <CustomButtonBack onClick={handleCloseModal} text={"No"} data-testid="No" />
          <CustomButtonNext onClick={handleDelete} text={"Yes"} loader={loader} />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteProvisioningApp;
