import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { retrieveData } from "../../../../services/storage/Storage";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Table } from "antd";
import SearchInput from "../../../../layouts/component/SearchField";
import CustomButtonBack from "../../../../layouts/component/CustomButtonBack";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { debounce } from "lodash";
import "../../../../../src/pages/dashboard/user/user-dashboard/groups/layout/Groups.scss";
import "../../../../pages/dashboard/user/user-dashboard/device-restriction/DeviceBasedRestriction.scss";
import { accessManagerUserColumns } from "./AccessManagerUserTableColumns";
import SuccessMessageModal from "../IpTimeSuccessModal";

const AccessManagerUserTable = () => {
  const ipPayload = useSelector((state: any) => state?.IpRestrictionSlice?.ipRestrictionPayload);
  const realmId = retrieveData("realmId", true);
  const getListOfUsers = useGetApiRequests("userManagement", "POST");
  const createIpRestrictionApi = useGetApiRequests("createIpBasedRestriction", "POST");
  const createTimeRestrictionApi = useGetApiRequests("createTimeBasedRestriction", "POST");
  const [searchValue, setSearchValue] = useState<any>("");
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [userData, setUsersData] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState<number | undefined>();
  const [fromAccessManager, setFromAccessManager] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [creationModal, setCreationModal] = useState(false);
  const [creationSuccessMessage, setCreationSuccessMessage] = useState<any>();
  const { search } = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(search);
  const parameterType = params.get("parameter");

  useEffect(() => {
    const pathName = window.location.pathname;
    if (pathName.includes("/access-manager")) {
      setFromAccessManager(true);
    }
    getListOfUsersApi();
  }, []);

  const getListOfUsersApi = async (searchValue?: string, toLoad?: boolean) => {
    const payload = {
      realmId: realmId,
      sorting: "name_asc",
      statusFilter: "active",
      searchFilter: searchValue ?? "",
      startValue: 0,
      limitValue: retrieveData("totalUserRecords", true),
    };
    if (toLoad) {
      setLoader(false);
    } else {
      setLoader(true);
    }
    try {
      const response: any = await getListOfUsers(payload);
      const status = response.status;
      const responseData = response?.data;
      if (status === 200) {
        const reformedListOfUsers = responseData?.data?.map((item: any) => ({
          value: item?.userData?.userId,
          name: item?.userData?.name,
          group: item?.userData?.groupNames,
          email: item?.userData?.email,
          key: item?.userData?.userId,
        }));
        setTotalRecords(reformedListOfUsers?.length);
        setUsersData(reformedListOfUsers);
      }
      setLoader(false);
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };
  const onSelectRow = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRow(newSelectedRowKeys);
  };
  const recordSelection = {
    selectedRowKeys,
    onChange: onSelectRow,
  };

  const handleUserSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event?.target?.value;
    setSearchValue(searchValue); // Update state immediately

    debouncedGetListOfUsersApi(searchValue); // Call the debounced API function
  };

  const debouncedGetListOfUsersApi = useCallback(
    debounce((searchValue) => {
      getListOfUsersApi(searchValue, true);
    }, 800),
    [],
  );

  const handleBackToAccessManager = () => {
    navigate("/access-manager");
  };
  const ipRestrictionCreation = async () => {
    setLoader(true);
    if (parameterType === "ip") {
      const payload = {
        ...ipPayload,
      };
      payload.userEntityId = selectedRow;
      payload.realmId = realmId;
      try {
        const response: any = await createIpRestrictionApi(payload);
        const status = response.status;
        if (status === 200) {
          setLoader(false);
          setCreationModal(true);
          setCreationSuccessMessage(response?.data?.message);
          setTimeout(() => {
            handleCloseCreationModal();
            navigate("/access-manager");
          }, 3000);
        }
      } catch (err: any) {
        handleRequestError(err);
        setLoader(false);
      }
    } else if (parameterType === "time") {
      const finalPayload = {
        ...ipPayload,
      };
      finalPayload.userTimeBasedAuthorizationKeyValueDto = {
        userIdList: selectedRow,
      };

      try {
        const response: any = await createTimeRestrictionApi(finalPayload);
        const status = response.status;
        if (status === 200) {
          setLoader(false);
          setCreationModal(true);
          setCreationSuccessMessage(response?.data?.message);
          setTimeout(() => {
            handleCloseCreationModal();
            navigate("/access-manager");
          }, 3000);
        }
      } catch (err: any) {
        handleRequestError(err);
        setLoader(false);
      }
    }
  };
  const handleCloseCreationModal = () => {
    setCreationModal(false);
  };
  return (
    <div className="akku-container">
      <div className="main-container">
        <div className="dashboard-container h-full rounded  assign-user">
          <ul className="flex breadcrumb">
            <li
              className="font-Inter pr-1 cursor-pointer"
              onClick={() => {
                navigate("/access-manager");
              }}
            >
              Access Manager /
            </li>
            <li className="font-Inter pr-1 cursor-pointer" onClick={() => navigate(-1)}>
              {parameterType === "ip" ? "Select IP /" : "Time Parameters /"}{" "}
            </li>
            <li className="font-Inter pr-1 active">Assign User </li>
          </ul>
          <div className="pt-4 pb-4 tileWithBack flex items-center">
            <span className="cursor-pointer material-icons-outlined" onClick={() => navigate(-1)}>
              arrow_upward
            </span>
            <h3>Assign User</h3>
          </div>
          <SearchInput search={searchValue} placeholder="Search user" onChange={handleUserSearch} />
          <div className="w-full pt-10 ">
            <div className="w-full">
              <div className="w-full flex mb-5">
                <div className="flex">
                  <p className="users-total pr-5 text-[18px]">Total Users: {totalRecords} </p>
                </div>
              </div>
            </div>

            <Table
              pagination={{
                showSizeChanger: true,
              }}
              loading={loader}
              rowSelection={recordSelection}
              columns={accessManagerUserColumns}
              dataSource={userData}
            />
            {fromAccessManager && (
              <div className="flex justify-end w-full mt-4 pb-7">
                <CustomButtonBack text={"Cancel"} onClick={handleBackToAccessManager} />
                <Button disabled={selectedRow.length === 0} className="btn bg-[#5441DA] w-[192px] h-[55px] font-Inter text-[20px]" type="primary" onClick={ipRestrictionCreation}>
                  Save
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {creationModal && <SuccessMessageModal open={creationModal} handleModalClose={handleCloseCreationModal} responseMessage={creationSuccessMessage} />}
    </div>
  );
};

export default AccessManagerUserTable;
