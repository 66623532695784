import React, { useEffect, useState } from "react";
import { Collapse, CollapseProps, Input, Tooltip } from "antd";
import { ErrorMessage, Field, FieldArray } from "formik";
import { staticMessage } from "../../../../constant/StaticContent";
import ImageUpload from "../../../../layouts/component/ImageUpload";
import { retrieveData } from "../../../../services/storage/Storage";

const GeneralSettingForm = (props: any) => {
  const { setCommonDetails, value, values, setImageData, appCredential, isError } = props;
  const [activeKey, setActiveKey] = useState<string>("");

  const [apiKey, setApiKey] = useState<boolean>(false);
  const [uploadJson, setUploadJson] = useState<boolean>(false);
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setCommonDetails((prevDetails: any) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Define a function to set states based on app name
  const setAppTypeStates = (appName: any) => {
    const name = appName?.toLowerCase();
    if (name?.includes("jira")) {
      setApiKey(true);
      setUploadJson(false);
    } else if (name === "google") {
      setUploadJson(true);
      setApiKey(false);
    }
  };
  useEffect(() => {
    const configureApp = retrieveData("configureApp", true);
    const appDetailsData = retrieveData(configureApp === "configure" ? "singleAppDetails" : "editAppDetails", false);
    const appDetails = JSON?.parse(appDetailsData);
    // Determine which details to use based on the configuration state
    if (configureApp === "configure") {
      setAppTypeStates(appDetails?.name);
    } else if (configureApp === "edit") {
      setAppTypeStates(appDetails?.akkuMasterClient?.name);
    }
  }, []); // Add more dependencies if there are other variables used inside useEffect that might change over time.

  useEffect(() => {
    if (isError) {
      setActiveKey("1");
    }
  }, [isError]);
  const handleImageUpload = (file: File) => {
    const formData = new FormData();
    formData.append(file.name, file);
    setImageData(formData);
  };
  const handleImageDelete = (file: File) => setImageData("");
  const handleCollapseChange = (key: any) => {
    setActiveKey(key);
  };
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: <p className="text-[#000] font-Inter text-[18px] font-normal">1 . General Settings</p>,
      children: (
        <FieldArray name="common">
          {({ insert, remove, push }) => (
            <div className="flex w-full justify-between flex-wrap">
              {values?.saml?.length > 0
                ? values.saml.map((samlConfig: any, index: number) => (
                    <>
                      <div className=" w-[45%] mb-0 ">
                        <p className="text-[#1D1D24] font-Inter text-[18px]  flex items-center font-medium">
                          Client Type*
                          <Tooltip title={staticMessage.app.clientId}>
                            <span className="material-symbols-outlined ml-2 cursor-pointer rotate-180 ">error</span>
                          </Tooltip>
                        </p>
                        <Field as={Input} type="text" name="protocol" readOnly onChange={handleChange} value={value} className=" text-[18px] h-[56px] font-medium " />
                        <ErrorMessage name="protocol" component="div" className="error-message custom-error" />
                      </div>
                      <div className="mb-0 w-[45%]">
                        <p className="text-[#1D1D24] font-Inter text-[18px]  flex items-center font-medium">
                          Client ID*
                          <Tooltip title={staticMessage.app.type}>
                            <span className="material-symbols-outlined ml-2 cursor-pointer rotate-180 ">error</span>
                          </Tooltip>
                        </p>

                        <Field as={Input} className="h-[56px] font-medium text-[18px]" type="text" name={`saml.${index}.clientId`} />
                        <ErrorMessage name={`saml.${index}.clientId`} component="div" className="custom-error error-message " />
                      </div>
                      {/*  */}

                      {apiKey && (
                        <div className="mb-0 w-[45%] pt-5 ">
                          <p className="text-[#1D1D24] text-[18px] font-Inter flex items-center font-medium">Api Key</p>
                          <Field name={`saml.${index}.apiKey`} as={Input} type="text" className="h-[56px] font-medium text-[18px]" />
                          <ErrorMessage name={`saml.${index}.apiKey`} component="div" className="error-message" />
                        </div>
                      )}
                      {uploadJson && (
                        <>
                          <div className="mt-5 ">
                            <p className="text-[#1D1D24] text-[18px] font-Inter flex items-center font-medium">Upload File</p>
                            <ImageUpload
                              onImageDelete={handleImageDelete}
                              imageUrl={appCredential}
                              onImageUpload={handleImageUpload}
                              allowedTypes={["application/json"]}
                              maxFileSize={1024 * 1024 * 3}
                              className="common-upload"
                            />
                          </div>
                          <div className="mb-0 mt-5 w-[45%]  ">
                            <p className="text-[#1D1D24] text-[18px] font-Inter flex items-center font-medium">Delegated User</p>
                            <Field as={Input} name={`saml.${index}.delegatedUser`} className="h-[56px] font-medium text-[18px]" type="text" />
                            <ErrorMessage component="div" name={`saml.${index}.delegatedUser`} className="error-message custom-error" />
                          </div>
                        </>
                      )}
                    </>
                  ))
                : values?.openId.map((samlConfig: any, index: number) => (
                    <>
                      <div className="mb-0 w-[45%]">
                        <p className="text-[#1D1D24] text-[18px] font-Inter flex items-center font-medium">
                          Client Type*
                          <Tooltip title={staticMessage.app.clientId}>
                            <span className="material-symbols-outlined    cursor-pointer rotate-180 ml-2">error</span>
                          </Tooltip>
                        </p>

                        <Field as={Input} readOnly onChange={handleChange} value={value} type="text" className="h-[56px] font-medium text-[18px]" name="protocol" />
                        <ErrorMessage name="protocol" component="div" className="error-message custom-error" />
                      </div>
                      <div className="mb-0 w-[45%]">
                        <p className="text-[#1D1D24] text-[18px] font-Inter flex items-center font-medium">
                          Client ID*{" "}
                          <Tooltip title={staticMessage.app.type}>
                            <span className="material-symbols-outlined  cursor-pointer rotate-180 ml-2">error</span>
                          </Tooltip>
                        </p>

                        <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`openId.${index}.clientId`} />
                        <ErrorMessage name={`openId.${index}.clientId`} component="div" className="error-message custom-error" />
                      </div>

                      {apiKey && (
                        <div className="mb-0 w-[45%] pt-5 ">
                          <p className="text-[#1D1D24] text-[18px] font-Inter flex items-center font-medium">Api Key</p>
                          <Field name={`openId.${index}.apiKey`} as={Input} type="text" className="h-[56px] font-medium text-[18px]" />
                          <ErrorMessage name={`openId.${index}.apiKey`} component="div" className="error-message" />
                        </div>
                      )}
                      {uploadJson && (
                        <>
                          <div className="mt-5 ">
                            <p className="text-[#1D1D24] text-[18px] font-Inter flex items-center font-medium">Upload File</p>
                            <ImageUpload
                              className="upload-json"
                              onImageUpload={handleImageUpload}
                              onImageDelete={handleImageDelete}
                              imageUrl={appCredential}
                              maxFileSize={1024 * 1024 * 3}
                              allowedTypes={["application/json"]}
                            />
                          </div>
                          <div className="mb-0 w-[45%] mt-5 ">
                            <p className="text-[#1D1D24] text-[18px] font-Inter flex items-center font-medium">Delegated User</p>
                            <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`openId.${index}.delegatedUser`} />
                            <ErrorMessage name={`openId.${index}.delegatedUser`} component="div" className="error-message custom-error" />
                          </div>
                        </>
                      )}
                    </>
                  ))}
            </div>
          )}
        </FieldArray>
      ),
    },
  ];

  return (
    <div className={` w-full`}>
      <Collapse accordion activeKey={activeKey} onChange={handleCollapseChange} items={items} className="w-[80%] custom-header  bg-[#fff]" expandIconPosition={"end"} />
    </div>
  );
};

export default GeneralSettingForm;
