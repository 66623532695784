import React, { ReactNode, useEffect, useState } from "react";
import { Modal, Select } from "antd";
import CustomButtonBack from "../../../layouts/component/CustomButtonBack";
import UsersSearchDropdown from "../layout/UsersSearchDropDown";
import { getUsersListForDeviceManagement } from "./UserListModalHelper";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../services/storage/Storage";
import Loader from "../../../layouts/component/Loader";
import CustomButtonNext from "../../../layouts/component/CustomButton";
import { setReloadDeviceManagement } from "../../../redux/slice/device-management/deviceManagementSlice";
import { useDispatch } from "react-redux";

const { Option } = Select;
const AssignUsersToDeviceModal = (props: any) => {
  const { handleCloseModal, modalOpen, deviceData, setResponseMessage, setSuccessModal } = props;
  const realmId = retrieveData("realmId", true);

  const dispatch = useDispatch();

  const getUserList = useGetApiRequests("userManagement", "POST");
  const deviceManagerAddNewUsers = useGetApiRequests("deviceManagerAddNewUsers", "PUT");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [usersList, setUsersList] = useState<any>([]);
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [tempSelectedValues, setTempSelectedValues] = useState<any>([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getUsersListForDeviceManagement(setLoader, getUserList, false, setUsersList, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!dropdownVisible) {
      setTempSelectedValues([]);
    } else {
      setTempSelectedValues(selectedValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownVisible]);
  const onSubmit = () => {
    createPayload();
  };
  const capitalizeFirstLetter = (string: any) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  const createPayload = () => {
    const payload = {
      serialNumber: deviceData?.serialNumber,
      deviceUuid: deviceData?.deviceUUID,
      deviceType: capitalizeFirstLetter(deviceData?.deviceType),
      addUserIdList: selectedValues,
      realmId: realmId,
    };
    const pathParams: object = {
      serialNumber: deviceData?.serialNumber,
    };
    apiCallForAssignUsers(payload, pathParams);
  };
  const apiCallForAssignUsers = async (payload: any, pathParams: any) => {
    try {
      const response: any = await deviceManagerAddNewUsers(payload, {}, pathParams);
      const status = response.status;
      if (status === 200) {
        setLoader(false);
        handleCloseModal();
        setSuccessModal(true);
        setResponseMessage(response.data.message);

        setTimeout(() => {
          dispatch(setReloadDeviceManagement(true));
          setSuccessModal(false);
        }, 2000);
      }
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const filterOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleLabelClick = (event: any, value: any) => {
    try {
      event.stopPropagation();
      const newValue: any = [...tempSelectedValues];
      if (newValue?.includes(value)) {
        newValue?.splice(newValue?.indexOf(value), 1);
      } else {
        newValue?.push(value);
      }
      setTempSelectedValues(newValue);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddApps = () => {
    setSelectedValues(tempSelectedValues);
    setDropdownVisible(false);
  };

  const dropdownRender = (menu: ReactNode) => {
    return (
      <div>
        <div>{menu}</div>
        <UsersSearchDropdown handleAddApps={handleAddApps} setDropdownVisible={setDropdownVisible} />
      </div>
    );
  };
  return (
    <div className="add-device-restriction-modal">
      <Modal
        className=" rounded-lg  _suspended show-notification add-device-restriction-modal assign-users-modal"
        closable={true}
        centered
        open={modalOpen}
        footer={false}
        onCancel={handleCloseModal}
        maskClosable={false}
      >
        <div className=" rounded-lg rounded-b-none border-b-2 bg-[#F0F0FA] py-5 pl-16 flex items-center">
          <div className="pl-2">
            <span className="text-[#171717] font-bold text-[28px] font-Inter">Assign Users</span>
          </div>
        </div>
        <div className="pt-4 modal-body pl-20 mt-8">
          <div className="w-[90%] flex flex-wrap justify-between gap-5">
            <div className="mb-6 relative w-[100%] ">
              <div className="w-[100%]">
                <Select
                  mode="tags"
                  className="h-14 w-full mb-5 custom-dropdown"
                  filterOption={filterOption}
                  tokenSeparators={[","]}
                  placeholder={"Search and Select"}
                  optionLabelProp="label"
                  onDropdownVisibleChange={(visible) => setDropdownVisible(visible)}
                  open={dropdownVisible}
                  dropdownRender={dropdownRender}
                  value={selectedValues}
                >
                  {usersList?.map((option: any) => (
                    <Option key={option?.value} value={option?.value} label={option?.name}>
                      <input
                        id={option?.value}
                        type="checkbox"
                        className="dropdown-list w-5 device-management-user-list-checkbox"
                        name={option?.name}
                        onChange={(e) => handleLabelClick(e, option?.value)}
                        checked={tempSelectedValues?.includes(option?.value)}
                      />
                      <label htmlFor={option.name} className="flex flex-col mb-3 h-14 pointer-events-none justify-center">
                        {option.name} <span className="absolute top-[32px] text-[#747577]">{option?.email}</span>
                      </label>
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="p-8 modal-footer mt-7">
            <div className="flex justify-end w-full">
              <CustomButtonBack text={"Cancel"} onClick={handleCloseModal} />
              <CustomButtonNext text={"Save"} onClick={onSubmit} active={!selectedValues?.length} />
            </div>
          </div>
        </div>
        {loader && <Loader />}
      </Modal>
    </div>
  );
};

export default AssignUsersToDeviceModal;
