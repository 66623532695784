export const ErrorMessages = {
  somethingWentWrong: "Oops! something went wrong",
  invalidEmail: "Invalid email",
  ldapFailMessage: "Invalid ldap connection credentials",
  sessionExpired: "Your session has expired; Please log in again.",
  unableToReceiveUserList: "Unable to retrieve the list of users",
  accessDenied: "Access Denied",
  deviceUnauthorized: "Your device is unauthorized",
  restartAgent: "Please restart the agent",
  loggedOut: "You are successfully logged out",
  deviceRestriction: "Device restriction already enabled",
  allocateUser: "Please allocate user to this group",
  deallocationNotPossible: "De-allocation cannot possible for these user(s)",
  selectPlansOrProduct: "Please select a plan or choose individual products to activate for this customer",
  noPlans: "No plans available",
  domainExist: "Domain already exists",
  appNameEmpty: "App name and app description are required",
  appNameTooLong: "App name must be 30 characters or less.",
  confirmationMessage: "Are you sure you want to close this tab? Any unsaved changes will be lost.",
  blackListIPNotAllowed: "IP address not allowed",
  activateUser: "Are you sure you want to reactivate the user(s) ?",
  activeUser: "Are you sure you want to enable this user ?",
  inActiveUser: "Are you sure you want to disable this user ?",
  testPassword: "testpassword",
  deleteDisplayContent: "Are you sure you want to delete the user(s) from the device restriction ?",
  IPRestrictionEnabled: "IP Restriction enabled",
  IPRestrictionDisabled: "IP Restriction disabled",
  TimeRestrictionEnabled: "Time Restriction enabled",
  TimeRestrictionDisabled: "Time Restriction disabled",
  deviceRestrictionWarning: "Devices are already restricted. No additional restrictions can be applied.",
  unexpectedError: "Unexpected error occurred. Please try again later.",
  inCorrectCurrentPassword: "Current password is incorrect",
  timeZoneFetchingError: "There was a problem with the fetch operation",
  ipNotAllowed: "IP address not allowed",
  notAuthenticated: "Not authenticated",
  failedToFetchKeycloakConfig: "Failed to fetch Keycloak configuration",
  initializeKeyCloakFailed: "Initializing Keycloak failed",
  errorSettingUpKeycloak: "Error setting up Keycloak",
  failedToRefreshToken: "Failed to refresh the token",
  tokenRefreshFailed: "Token refresh failed during interval",
  uploadCsvFile: "You can only upload CSV files!",
  csvFileLimit: "CSV file must be smaller than 20MB!",
};
