import React, {  useState } from "react";
import ImageView from "../../../../../layouts/component/ImageView";
import { images } from "../../../../../assets/images/ImagePaths";

const ConnectorCard = ({handleAddConnector}:any) => {
  const connectors = ["Akku_Connector"];
  const [selectedConnector, setSelectedConnector] = useState<string>("");
  const handleSelectConnector = (connector: string) => {
    if (connector === "Akku_Connector") {
      setSelectedConnector(connector);
      handleAddConnector(connector);
      // dispatch(setSelectedSourceConnector(connector));
    }
  };

  return (
    <div className="flex gap-10">
      {connectors?.map((item, index) => {
        return (
          <div className="w-[33%] select-connector" key={item} role="none">
            <div
              role="none"
              onClick={() => {
                handleSelectConnector(item);
              }}
              className={` ${item === "Akku_Connector" ? " cursor-pointer " : "cursor-not-allowed"}`}
            >
              <div className={`${selectedConnector === item ? "bordered" : ""} connector-card`}>
                <div className="connector-header ">
                  <div className="w-full h-full flex items-center justify-center">
                    <div className="w-11 ">
                      <ImageView src={images.link} alt="Akku_ConnectorImage" className="pt-1" />
                    </div>
                    <p className="card-title">{item}</p>
                  </div>
                </div>

                <p className="px-[14px] pt-[14px] description">The Akku connector provisions users from Akku to target apps, managing accounts, permissions, and data for seamless, secure access.</p>

                <p className="flex items-center px-[14px]">
                  <ImageView src={images.greenCheck} alt="connectedImage" className="pt-1 pr-2" /> Connected
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ConnectorCard;
