export const themeConfig = {
  token: { colorPrimary: "#5441DA" },
  components: {
    Form: {
      labelColor: "#171717",
      labelFontSize: 16,
    },
    Input: {
      colorTextPlaceholder: "#9EA2AA",
      controlHeight: 40,
      fontFamily: "'Lato', sans-serif",
      colorText: "#171717",
    },
    Select: {
      colorBorder: "#9EA2AA",
      optionHeight: 40,
      optionSelectedColor: "#171717",
      optionFontSize: 16,
    },
  },
};

export const themeImages = {
  brandLogo: "brandLogo.png",
  footerLogo: "akku-copyright.png",
  banner: "loginBanner1.png",
  themeBackground: "theme_bg.png",
  userDashbordLogo: "userDashbordLogo.png",
};
export const themeCss = {
  themePath: "theme.css",
};

export const staticThemeUrl = {
  imagesUrl: "https://storage.googleapis.com/akku-theme/basetheme/img/",
  cssUrl: "https://storage.googleapis.com/akku-theme/basetheme/css/",
};