import React, { useEffect, useState } from "react";
import { Tabs, TabsProps } from "antd";
import RiskAssessmentTable from "./RiskAssessmentTable";
import { retrieveData } from "../../../services/storage/Storage";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
import { formatDateTime } from "../RiskAssessmentHelperFile";

const RiskAssessmentTabs = () => {
  const tabKeyFromDashboard = retrieveData("riskAssessmentTabKey", true)?.toString();
  const realmId = retrieveData("realmId", true);
  const getRiskAssessmentDetails = useGetApiRequests("getRiskAssessmentDetails", "POST");
  const [selectedTabKey, setSelectedTabKey] = useState(tabKeyFromDashboard);
  const [loader, setLoader] = useState(false);
  const [dataSource, setDataSource] = useState<any>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [sizeChanger, setSizeChanger] = useState<any>(10);

  useEffect(() => {
    gettingRiskAssessmentDetails(selectedTabKey, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeChanger, currentPage]);

  const gettingRiskAssessmentDetails = async (tabKey: string, fromTabChange: boolean) => {
    setLoader(true);
    setDataSource([]);
    const payload = createPayload(tabKey, fromTabChange);
    try {
      const response: any = await getRiskAssessmentDetails(payload);
      if (response?.status === 200) {
        const data = response?.data;

        if (!data || !data?.data) {
          setDataSource([]);
          setTotalCount(0);
        } else {
          const reformedData = formatResponseData(data?.data, tabKey);
          setDataSource(reformedData);
          setTotalCount(data?.meta?.totalRecords || 0);
        }

        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const createPayload = (tabKey: string, fromTabChange: boolean) => ({
    realmId: realmId,
    start: fromTabChange ? 0 : currentPage,
    limit: fromTabChange ? 10 : sizeChanger,
    widgetType: getWidgetType(tabKey),
  });

  const getWidgetType = (tabKey: string) => {
    switch (tabKey) {
      case "1":
        return "FAILED_LOGIN";
      case "3":
        return "LOCKED_USERS";
      default:
        return "SUSPICIOUS_LOGIN";
    }
  };

  const formatResponseData = (data: any[], tabKey: string) =>
    data.map((item: any, index: number) => ({
      name: item?.name,
      email: item?.email,
      lastLogin: item?.lastActiveLogin ? formatDateTime(item?.lastActiveLogin) : "-",
      reason: item?.userRiskAssessmentData?.eventType || "-",
      key: index + 1,
      ...getAdditionalData(item, tabKey),
    }));

  const getAdditionalData = (item: any, tabKey: string) => {
    const eventTime = item?.userRiskAssessmentData?.eventTime ? formatDateTime(item?.userRiskAssessmentData?.eventTime) : "-";
    switch (tabKey) {
      case "0":
        return { suspiciousLoginAttempt: eventTime };
      case "1":
        return { failedLoginAttempt: eventTime };
      case "3":
        return { lockedLoginAttempt: eventTime };
      default:
        return {};
    }
  };

  const itemsTabs: TabsProps["items"] = [
    {
      key: "0",
      label: "Suspicious Login",
      children: (
        <RiskAssessmentTable
          tabKey={selectedTabKey}
          riskAssessmentData={dataSource}
          parentLoader={loader}
          totalCount={totalCount}
          currentPage={currentPage}
          sizeChanger={sizeChanger}
          setSizeChanger={setSizeChanger}
          setCurrentPage={setCurrentPage}
        />
      ),
    },
    {
      key: "3",
      label: "Locked Accounts",
      children: (
        <RiskAssessmentTable
          tabKey={selectedTabKey}
          riskAssessmentData={dataSource}
          parentLoader={loader}
          totalCount={totalCount}
          currentPage={currentPage}
          sizeChanger={sizeChanger}
          setSizeChanger={setSizeChanger}
          setCurrentPage={setCurrentPage}
        />
      ),
    },
    {
      key: "1",
      label: "Failed Login",
      children: (
        <RiskAssessmentTable
          tabKey={selectedTabKey}
          riskAssessmentData={dataSource}
          parentLoader={loader}
          totalCount={totalCount}
          currentPage={currentPage}
          sizeChanger={sizeChanger}
          setSizeChanger={setSizeChanger}
          setCurrentPage={setCurrentPage}
        />
      ),
    },
  ];

  const handleTabChange = (key: string) => {
    setCurrentPage(0);
    setSizeChanger(10);
    setSelectedTabKey(key);
    gettingRiskAssessmentDetails(key, true);
  };

  return (
    <div className="risk-assessment-tabs">
      <Tabs activeKey={selectedTabKey} size="large" className="bg-[#fff]" type="card" items={itemsTabs} onChange={handleTabChange} data-testid="risk-tabs" />
    </div>
  );
};

export default RiskAssessmentTabs;
