import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  isActive: boolean;
  generalDetails: {};
  authentication: {};
  provisioning: {};
  deProvisioning: {};
  activeStep: number;
  endPointResponseDtosLen: number;
  isActiveEdit: boolean;
  akkuProvisioningConnectorId: string;
  isBackButtonEnabled: boolean;
  connectorDetailDirty: boolean;
  generalDetailsEdit: {};
  isDuplicate: boolean;
  mappingAttributes: {};
  selectedSourceConnector: string;
  endPointResponseDtos: any;
  mappingAttributesPayloadCreation: [];
  authenticationTestButtonClicked: false;
  provisioningBackButtonClicked: false;
  deProvisioningBackButtonClicked: false;
  akkuProvisioningConnectorEndpointConfigId: string;
  deProvisionConnectorEndpointConfigId: string;
  duplicateProvisioningButtons: {};
  isConnectorApiCalled: boolean;
  isAuthenticationEdited: boolean; //
}
const initialState: AuthState = {
  isAuthenticationEdited: false,
  mappingAttributesPayloadCreation: [],
  activeStep: 0,
  endPointResponseDtosLen: 0,
  isActiveEdit: false,
  isDuplicate: false,
  akkuProvisioningConnectorId: "",
  generalDetailsEdit: {},
  isActive: false,
  generalDetails: {
    name: "",
    description: "",
    type: "RESTAPI",
    isDefaultSourceConnector: "yes",
    authenticationType: "",
  },
  authentication: {
    tokenApiEndpoint: "",
    userName: "",
    password: "",
    grantType: "",
    clientId: "",
    secret: "",
    apiToken: "",
    userNameEmail: "",
    tokenApiUrl: "",
  },
  provisioning: {
    userProvision: [
      {
        apiEndpointURL: "",
        methodType: "",
        authType: "",
        token: "",
        userName: "",
        password: "",
        contentType: "",
        headerParametersType: null,
        algorithm: "",
        secret: "",
        payload: "",
        apiKey: "",
        apiValue: "",
        apiAddTo: "",
        requestPayload: "",
        response: "",
        headerParam: [{ key: "", value: "" }],
        pathVariable: [{ key: "", value: "" }],
      },
    ],
    roleProvision: [
      {
        apiEndpointURL: "",
        methodType: "",
        authType: "",
        token: "",
        userName: "",
        password: "",
        contentType: "",
        algorithm: "",
        secret: "",
        payload: "",
        apiKey: "",
        apiValue: "",
        apiAddTo: "",
        requestPayload: "",
        response: "",
        headerParam: [{ key: "", value: "" }],
        pathVariable: [{ key: "", value: "" }],
      },
    ],
    groupProvision: [
      {
        apiEndpointURL: "",
        methodType: "",
        authType: "",
        token: "",
        userName: "",
        password: "",
        contentType: "",
        algorithm: "",
        secret: "",
        payload: "",
        apiKey: "",
        apiValue: "",
        apiAddTo: "",
        requestPayload: "",
        response: "",
        headerParam: [{ key: "", value: "" }],
        pathVariable: [{ key: "", value: "" }],
      },
    ],
  },
  deProvisioning: {
    userDeProvision: [
      {
        apiEndpointURL: "",
        methodType: "Delete",
        authType: "",
        token: "",
        userName: "",
        password: "",
        contentType: "",
        headerParametersType: null,
        algorithm: "",
        secret: "",
        payload: "",
        apiKey: "",
        apiValue: "",
        apiAddTo: "",
        requestPayload: "",
        response: "",
        headerParam: [{ key: "", value: "" }],
        pathVariable: [{ key: "", value: "" }],
      },
    ],
    roleDeProvision: [
      {
        apiEndpointURL: "",
        methodType: "",
        authType: "",
        token: "",
        userName: "",
        password: "",
        contentType: "",
        algorithm: "",
        secret: "",
        payload: "",
        apiKey: "",
        apiValue: "",
        apiAddTo: "",
        requestPayload: "",
        response: "",
        headerParam: [{ key: "", value: "" }],
        pathVariable: [{ key: "", value: "" }],
      },
    ],
    groupDeProvision: [
      {
        apiEndpointURL: "",
        methodType: "",
        authType: "",
        token: "",
        userName: "",
        password: "",
        contentType: "",
        algorithm: "",
        secret: "",
        payload: "",
        apiKey: "",
        apiValue: "",
        apiAddTo: "",
        requestPayload: "",
        response: "",
        headerParam: [{ key: "", value: "" }],
        pathVariable: [{ key: "", value: "" }],
      },
    ],
  },
  isBackButtonEnabled: false,
  connectorDetailDirty: false,
  mappingAttributes: { forms: [{ source: "", target: "", userType: "", id: "" }] },
  selectedSourceConnector: "",
  endPointResponseDtos: [
    {
      akkuProvisioningConnectorEndpointConfigId: "",
      endpointDescription: "",
      attributeMappings: {},
      headerParameters: {},
    },
  ],
  authenticationTestButtonClicked: false,
  provisioningBackButtonClicked: false,
  deProvisioningBackButtonClicked: false,
  akkuProvisioningConnectorEndpointConfigId: "",
  deProvisionConnectorEndpointConfigId: "",
  duplicateProvisioningButtons: {
    connectorDetails: false,
    connectorAuthentication: false,
    connectorProvisioning: false,
    connectorDeprovisioning: false,
    connectorMappingAttributes: false,
    connectorMappingAttributesTarget: false,
  },
  isConnectorApiCalled: false,
};

const provisioningSlice: any = createSlice({
  name: "navigateCourseTab",
  initialState,
  reducers: {
    setIsActive: (state, action: PayloadAction<boolean>) => {
      state.isActive = action.payload;
    },
    setGeneralDetails: (state, action: PayloadAction<any>) => {
      state.generalDetails = action.payload;
    },
    setAuthentication: (state, action: PayloadAction<any>) => {
      state.authentication = action.payload;
    },
    setProvisioning: (state, action: PayloadAction<any>) => {
      state.provisioning = action.payload;
    },
    setDeProvisioning: (state, action: PayloadAction<any>) => {
      state.deProvisioning = action.payload;
    },
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload;
    },
    setEndPointResponseDtosLen: (state, action: PayloadAction<number>) => {
      state.endPointResponseDtosLen = action.payload;
    },
    setEditStatusToggle: (state, action: PayloadAction<boolean>) => {
      state.isActiveEdit = action.payload;
    },
    setGeneralDetailsEdit: (state, action: PayloadAction<any>) => {
      state.generalDetailsEdit = action.payload;
    },

    setConnectorId: (state, action: PayloadAction<string>) => {
      state.akkuProvisioningConnectorId = action.payload;
    },
    setAuthenticationEdited: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticationEdited = action.payload;
    },
    setIsDuplicate: (state, action: PayloadAction<boolean>) => {
      state.isDuplicate = action.payload;
    },
    setIsBackButtonPressed: (state, action: PayloadAction<any>) => {
      state.isBackButtonEnabled = action.payload;
    },
    setConnectorDetailDirty: (state, action: PayloadAction<boolean>) => {
      state.connectorDetailDirty = action.payload;
    },
    setMappingAttributes: (state, action: PayloadAction<any>) => {
      state.mappingAttributes = action.payload;
    },
    setSelectedSourceConnector: (state, action: PayloadAction<any>) => {
      state.selectedSourceConnector = action.payload;
    },
    setEndPointResponseDtos: (state, action: PayloadAction<any>) => {
      state.endPointResponseDtos = action.payload;
    },
    setMappingAttributesPayloadCreation: (state, action: PayloadAction<any>) => {
      state.mappingAttributesPayloadCreation = action.payload;
    },
    setAuthenticationNextButtonClicked: (state, action: PayloadAction<any>) => {
      state.authenticationTestButtonClicked = action.payload;
    },
    setProvisioningBackButtonClicked: (state, action: PayloadAction<any>) => {
      state.provisioningBackButtonClicked = action.payload;
    },
    setDeProvisioningBackButtonClicked: (state, action: PayloadAction<any>) => {
      state.deProvisioningBackButtonClicked = action.payload;
    },
    setProvisioningConnectorEndpointConfigId: (state, action: PayloadAction<any>) => {
      state.akkuProvisioningConnectorEndpointConfigId = action.payload;
    },
    setDeProvisionConnectorEndpointConfigId: (state, action: PayloadAction<any>) => {
      state.deProvisionConnectorEndpointConfigId = action.payload;
    },
    setProvisioningButtonStatus: (state, action: PayloadAction<any>) => {
      state.duplicateProvisioningButtons = action.payload;
    },
    setIsConnectorApiCalled: (state, action: PayloadAction<boolean>) => {
      state.isConnectorApiCalled = action.payload;
    },
    clearActiveStep: (state) => {
      state.activeStep = 0;
    },
    clearAllData: () => {
      return { ...initialState };
    },
  },
});

export const {
  setIsActive,
  setGeneralDetails,
  setAuthentication,
  setProvisioning,
  setDeProvisioning,
  setActiveStep,
  clearActiveStep,
  clearAllData,
  setEditStatusToggle,
  setConnectorId,
  setGeneralDetailsEdit,
  setEndPointResponseDtosLen,
  setIsDuplicate,
  setIsBackButtonPressed,
  setConnectorDetailDirty,
  setMappingAttributes,
  setSelectedSourceConnector,
  setEndPointResponseDtos,
  setAuthenticationNextButtonClicked,
  setProvisioningBackButtonClicked,
  setDeProvisioningBackButtonClicked,
  setProvisioningConnectorEndpointConfigId,
  setDeProvisionConnectorEndpointConfigId,
  setMappingAttributesPayloadCreation,
  setProvisioningButtonStatus,
  setIsConnectorApiCalled,
  setAuthenticationEdited,
} = provisioningSlice.actions;
export default provisioningSlice.reducer;
