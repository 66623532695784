import { Route, Routes } from "react-router-dom";
import Dashboard from "../../pages/dashboard/Dashboard";
import AppStore from "../../pages/app-store/AppStore";
import CreateNewApplication from "../../pages/app-store/CreateNewApplication";
import Register from "../../pages/register/Register";
import NewRegister from "../../pages/register/NewRegister";
import Login from "../../pages/login/Login";
import UserManagement from "../../pages/dashboard/user/user-dashboard/UserManagement";
import ProtectedRoute from "../private/ProtectedRoute";
import PublicRoute from "./PublicRoute";
import VerifyEmail from "../../pages/register/new-register/VerifyEmail";
import UploadCompanyLogo from "../../pages/register/upload-logo/UploadCompanyLogo";
import AddUsers from "../../pages/dashboard/user/user-dashboard/add-user/AddUsers";
import MultiFactorAuthForgotUserName from "../../User/MultiFactorAuthentication/MultiFactorForgotUsername";
import MultiFactorAuthForgotPassword from "../../User/MultiFactorAuthentication/MultiFactorForgotPassword";
import ForgotPassword from "../../User/ForgotPassword/ForgotPassword";
import ForgotPasswordFromUrl from "../../User/ForgotPassword/ForgotPasswordFromUrl";
import SentOTPMobile from "../../User/SendOtp/SendOtpMobile";
import SentOTPEmail from "../../User/SendOtp/SendOtpEmail";
import ValidateOtp from "../../User/ValidateOtp/ValidateOtp";
import ResetPassword from "../../User/ResetPassword/ResetPassword";
import ClientDashboard from "../../User/UserDashboard/ClientDashboard";
import DeviceBasedRestriction from "../../pages/dashboard/user/user-dashboard/device-restriction/DeviceBasedRestriction";
import CreateGroups from "../../pages/dashboard/user/user-dashboard/groups/CreateGroups";
import AddGroupDetails from "../../pages/dashboard/user/user-dashboard/groups/AddGroupDetails";
import DeviceLogout from "../../pages/DeviceLogout/DeviceLogout";
import AccessManager from "../../pages/access-manager/AccessManager";
import EditLDAPConfiguration from "../../pages/edit-ldap-configuration/EditLDAPConfiguration";
import DeviceRestriction from "../../pages/access-manager/device-restriction/DeviceRestriction";
import EditUserManagement from "../../pages/dashboard/user/edit-user/EditUserManagement";
import RoleManagement from "../../pages/role-management/RoleManagement";
import AddRoleAndPermission from "../../pages/role-management/layout/add-role/AddRoleAndPermission";
import ConnectionDirectory from "../../pages/connection-directory/ConnectionDirectory";
import AccessManagerUserTable from "../../pages/access-manager/create/UsersTable/AccessManagerUsersTable";
import AppProvisionConfiguration from "../../pages/app-store/layout/new-app-configuration/AppProvisionConfiguration";
import SelectIP from "../../pages/access-manager/create/select-ip/SelectIP";
import TimeParameters from "../../pages/access-manager/create/time-parameters/TimeParametersRestriction";
import CSVUpload from "../../pages/dashboard/user/user-dashboard/upload-csv/CSVUpload";
import CredentialManagement from "../../pages/credential-management/layout/CredentialManagement";
import PasswordManager from "../../pages/password-management/PasswordManager";
import UpdateDeviceBasedRestriction from "../../pages/dashboard/user/user-dashboard/device-restriction/UpdateDeviceBasedRestriction";
import EditKerberosDirectory from "../../pages/kerberos/EditKerberosDirectory";
import EditTenentDetails from "../../pages/dashboard/tenent/EditTenentDetails";
import PageNotFound from "../../pages/page-notfound/PageNotFound";
import RiskAssessment from "../../pages/risk-assessment/RiskAssessment";
import DeviceManagement from "../../pages/device-management/layout/DeviceManagement";
import DeviceManagementCSVUpload from "../../pages/device-management/layout/DeviceManagementCSVUpload";
function AppRouter() {
  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />}></Route>
        <Route path="/user-register" element={<NewRegister />}></Route>
        <Route path="/verify-email" element={<VerifyEmail />}></Route>
        <Route path="/upload-logo" element={<UploadCompanyLogo />}></Route>

        {/* User Routes */}

        <Route path="/forgot-username" element={<MultiFactorAuthForgotUserName />}></Route>
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route path="/setpassword" element={<ForgotPasswordFromUrl />}></Route>
        <Route path="/multi-factor-authentication" element={<MultiFactorAuthForgotPassword />}></Route>
        <Route path="/sent-otp-mobile" element={<SentOTPMobile />}></Route>
        <Route path="/sent-otp-email" element={<SentOTPEmail />}></Route>
        <Route path="/validate-otp" element={<ValidateOtp />}></Route>
        <Route path="/reset-password" element={<ResetPassword />}></Route>
        <Route path="/set-password" element={<ResetPassword />}></Route>
        <Route path="/device-logout" element={<DeviceLogout />}></Route>
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path="/dashboard" element={<ClientDashboard />}></Route>
        <Route path="/admin-dashboard" element={<Dashboard />}></Route>
        <Route path="/user" element={<UserManagement />}></Route>
        <Route path="/user/edit" element={<EditUserManagement />}></Route>
        <Route path="/user/csv-upload" element={<CSVUpload />}></Route>
        <Route path="/user/add-user" element={<AddUsers />}></Route>
        <Route path="/app-store" element={<AppStore />}></Route>
        <Route path="/app-store/configure-app" element={<CreateNewApplication />}></Route>
        <Route path="/user/device-restriction" element={<DeviceBasedRestriction />}></Route>
        <Route path="/access-manager/device-restriction" element={<DeviceBasedRestriction />}></Route>
        <Route path="/access-manager/device-based-restriction" element={<UpdateDeviceBasedRestriction />}></Route>
        <Route path="/user/groups" element={<CreateGroups />}></Route>
        <Route path="/user/create-groups" element={<AddGroupDetails />}></Route>
        <Route path="/access-manager" element={<AccessManager />}></Route>
        <Route path="/access-manager/device-restriction" element={<DeviceRestriction />}></Route>
        <Route path="/access-manager/select-ip" element={<SelectIP />}></Route>
        <Route path="/access-manager/time-parameters" element={<TimeParameters />}></Route>
        <Route path="/access-manager/groups" element={<CreateGroups />}></Route>
        <Route path="/access-manager/assignUsers" element={<AccessManagerUserTable />}></Route>
        <Route path="/role-management" element={<RoleManagement />}></Route>
        <Route path="/edit-connected-directory" element={<EditLDAPConfiguration />}></Route>
        <Route path="/role-management/configure-role" element={<AddRoleAndPermission />}></Route>
        <Route path="/directory/configure-directory" element={<ConnectionDirectory />}></Route>
        <Route path="/account-provision" element={<AppProvisionConfiguration />}></Route>
        <Route path="/credential-manager" element={<CredentialManagement />}></Route>
        <Route path="/password-management" element={<PasswordManager />}></Route>
        <Route path="/edit-kerberos-directory" element={<EditKerberosDirectory />}></Route>
        <Route path="/edit-tenant" element={<EditTenentDetails />}></Route>
        <Route path="/risk-assessment" element={<RiskAssessment />}></Route>
        <Route path="/user/select-ip" element={<SelectIP />}></Route>
        <Route path="/user/time-parameters" element={<TimeParameters />}></Route>
        <Route path="/device-management" element={<DeviceManagement />}></Route>
        <Route path="/device-management/csv-upload" element={<DeviceManagementCSVUpload />}></Route>
        {/* User Routes */}
        {/* <Route path="/user-dashboard" element={<UserDashboard />}></Route> */}
      </Route>
      {/* Page Not Found Route */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
export default AppRouter;
