import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../../../layouts/breadcrumb/Breadcrumb";
import { Table } from "antd";
import { prerequisitesTable } from "../../../../../constant/prerequisites/prerequisites";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../../../layouts/component/CustomButton";
import SendAuthorizeMailModal from "./modal/SendAuthorizeMailModal";
import DeviceRestrictionTableHeader from "./DeviceRestrictionTableHeader";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../../../../layouts/component/SearchField";
import { debounce } from "lodash";
import { deviceRestrictionColumns } from "./DeviceRestrictionTableColumns";
import AccessManagerBreadcrumbs from "./BreadCrumbsAccessManager";
import { retrieveData } from "../../../../../services/storage/Storage";
import "./DeviceBasedRestriction.scss";
const DeviceBasedRestriction = () => {
  const realmId = retrieveData("realmId", true);

  const deviceBasedSingleUser = useGetApiRequests("deviceBasedRestrictionForSingleUser", "POST");
  const deviceBasedMultipleUser = useGetApiRequests("deviceBasedMultipleUser", "POST");
  const getDeviceRestrictionList = useGetApiRequests("getAccessManagerDetails", "GET");
  const getUserList = useGetApiRequests("userManagement", "POST");

  const deviceRestrictionList: any = useSelector((state: any) => state?.DeviceRestrictionSlice?.deviceRestrictionList);
  const [userList, setUserList] = useState<any>([]);
  const [userListStable, setUserListStable] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [fromAccessManager, setFromAccessManager] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<any>("");
  const navigate = useNavigate();

  useEffect(() => {
    const pathName = window.location.pathname;
    if (pathName.includes("/access-manager")) {
      setFromAccessManager(true);
      getDeviceRestrictionListOfUsers();
    } else {
      setUserList(deviceRestrictionList);
      const selectedRows = deviceRestrictionList?.map((item: any) => item?.userId);
      setSelectedRowKeys(selectedRows);
      setSelectedRow(selectedRows);
    }
  }, [deviceRestrictionList]);

  const getDeviceRestrictionListOfUsers = async () => {
    setLoader(true);
    const queryParam = { realmId };
    try {
      const response: any = await getDeviceRestrictionList("", queryParam);
      if (response.status === 200) {
        const unMappedUserList = response?.data?.data?.accessManagerDeviceRestrictionKvDto?.unMappedUserDtoList;
        const reformedUserList = unMappedUserList?.map((item: any) => ({ key: item?.id }));

        const payload = {
          realmId,
          startValue: 0,
          limitValue: retrieveData("totalUserRecords", true),
          sorting: "name_asc",
          statusFilter: "active",
          searchFilter: "",
        };
        try {
          const userApiResponse: any = await getUserList(payload);

          if (userApiResponse?.status === 200) {
            const responseData = userApiResponse?.data;
            const userListFromTheApi = responseData?.data?.map((item: any) => ({
              key: item?.userData?.userId,
              email: item?.userData?.email,
              name: item?.userData?.name,
              userId: item?.userData?.userId,
            }));
            const reformedKeysList = reformedUserList?.map((item: any) => item.key);
            const finalUserList = userListFromTheApi?.filter((item: any) => reformedKeysList?.includes(item.key));
            setUserListStable(finalUserList);
            setUserList(finalUserList);
            setLoader(false);
          }
        } catch (err: any) {
          setLoader(false);
          handleRequestError(err);
        }
      }
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };
  const handleCloseModal = () => {
    setSelectedRowKeys([]);
    setSelectedRow([]);
    setOpenModal(false);
    navigateToAccessManager();
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRow(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleRequestAuthorizeApi = () => {
    if (selectedRow.length === 1) {
      setLoader(true);
      const payload: object = {
        userId: selectedRow?.[0],
      };
      deviceBasedSingleUser(payload)
        .then((response: any) => {
          setLoader(false);
          setOpenModal(true);
        })
        .catch((err: any) => {
          setLoader(false);
          setOpenModal(false);
          handleRequestError(err);
        });
    } else if (selectedRow.length > 1) {
      setLoader(true);
      const commaSeparatedString: string = selectedRow?.join(",");
      const payload: object = {
        userId: commaSeparatedString,
      };
      deviceBasedMultipleUser(payload)
        .then((response: any) => {
          setLoader(false);
          setOpenModal(true);
        })
        .catch((err: any) => {
          setLoader(false);
          setOpenModal(false);
          handleRequestError(err);
        });
    }
  };
  const handleCancel = () => {
    fromAccessManager ? navigate("/access-manager") : navigate("/user");
  };
  const navigateToAccessManager = () => {
    navigate("/access-manager");
  };
  const handleSearch = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event?.target?.value;
    setSearchValue(searchValue);
    if (searchValue && searchValue !== "") {
      const filteredData = userList?.filter((item: any) => Object?.values(item)?.some((value) => String(value)?.toLowerCase()?.includes(searchValue?.toLowerCase())));
      setUserList(filteredData);
    } else if (searchValue === "") {
      setUserList(userListStable);
    }
  }, 100);
  return (
    <div className="akku-container">
      <div className="main-container">
        <div className={`dashboard-container h-full rounded  device-restriction-table ${fromAccessManager && "create create-primary"} `}>
          {fromAccessManager ? <AccessManagerBreadcrumbs /> : <Breadcrumb />}
          <div className="selectIpEdit-title pt-1">
            <h2 className="font-semibold">Device Level</h2>
          </div>
          {fromAccessManager && (
            <div className="pt-1">
              <SearchInput search={searchValue} className="w-[375px]" placeholder="Search" onChange={handleSearch} />
            </div>
          )}
          <div className={`w-full ${fromAccessManager && "pt-10"}`}>
            <div className="w-full">
              <DeviceRestrictionTableHeader
                fromAccessManager={fromAccessManager}
                userList={userList}
                selectedRows={selectedRow}
                setSelectedRow={setSelectedRow}
                setSelectedRowKeys={setSelectedRowKeys}
              />
            </div>
            {fromAccessManager ? (
              <Table
                pagination={{
                  showSizeChanger: true,
                  pageSizeOptions: prerequisitesTable.pageSizeOptions,
                }}
                rowSelection={rowSelection}
                columns={deviceRestrictionColumns}
                dataSource={userList}
                loading={loader}
              />
            ) : (
              <Table pagination={false} rowSelection={rowSelection} columns={deviceRestrictionColumns} dataSource={userList} loading={loader} />
            )}
            <div className="w-full flex justify-end pt-10">
              <CustomButtonBack text={"Cancel"} onClick={handleCancel} />
              <CustomButtonNext onClick={handleRequestAuthorizeApi} text={"Authorize"} active={selectedRow.length === 0} />
            </div>
          </div>
        </div>
      </div>
      {openModal && <SendAuthorizeMailModal openModal={openModal} handleCloseModal={handleCloseModal} />}
    </div>
  );
};

export default DeviceBasedRestriction;
