import React, { useEffect, useState } from "react";
import { Steps } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./ProvisionStepper.scss";
const ProvisionStepper = () => {
  const provisioning = useSelector((state: any) => state?.provisioning);
  const [currentStep, setCurrentStep] = useState<any>(provisioning?.activeStep);
  const navigate = useNavigate();

  const generalDetails = useSelector((state: any) => state?.provisioning.generalDetails);
  useEffect(() => {
    let step;
    if (provisioning?.activeStep > 4) {
      step = 3;
    } else if (provisioning?.activeStep === 4) {
      step = 2;
    } else if (provisioning?.activeStep < 4) {
      step = 1;
    }
    setCurrentStep(step);
  }, [provisioning?.activeStep]);
  const handleNavigate = () => {
    navigate("/app-store");
  };
  return (
    <div className="w-full stepper-provision pl-7 mt-10">
      <ul className="flex breadcrumb pt-0">
        <li className="flex !text-[16px] cursor-pointer" onClick={() => handleNavigate()} role="none">
          App Management / &nbsp;
        </li>
        <li className="active !text-[16px]"> Provisioning</li>
      </ul>
      <div className="stepper-content">
        <h1 className="capitalize">Provisioning- {generalDetails?.name}</h1>
        <div className=" provision pt-5">
          <Steps
            direction="vertical"
            current={currentStep}
            items={[
              {
                title: <p className="flex items-center text-[20px] ">Connector Details</p>,
                description: (
                  <div className="child-stepper">
                    <p className={`${provisioning?.activeStep === 0 ? "!text-[#1D1D24]" : ""} relative`}>
                      {provisioning?.activeStep === 0 ? <span className="stepper-active-dot"></span> : null}General Details
                    </p>
                    <p className={`${provisioning?.activeStep === 1 ? "!text-[#1D1D24]" : ""} relative`}>
                      {provisioning?.activeStep === 1 ? <span className="stepper-active-dot"></span> : null}Authentication
                    </p>
                    <p className={`${provisioning?.activeStep === 2 ? "!text-[#1D1D24]" : ""} relative`}>
                      {provisioning?.activeStep === 2 ? <span className="stepper-active-dot"></span> : null}Provisioning
                    </p>
                    <p className={`${provisioning?.activeStep === 3 ? "!text-[#1D1D24]" : ""} relative`}>
                      {provisioning?.activeStep === 3 ? <span className="stepper-active-dot"></span> : null}Deprovisioning
                    </p>
                  </div>
                ),
              },
              {
                title: "Select your target apps",
              },
              {
                title: <p className="flex items-center ">Mapping</p>,
                description: (
                  <div className="child-stepper">
                    <p className={`${provisioning?.activeStep === 5 ? "!text-[#1D1D24]" : ""} relative`}>
                      {provisioning?.activeStep === 5 ? <span className="stepper-active-dot"></span> : null}Attributes
                    </p>
                    {/* <p className={`${provisioning?.activeStep === 6 ? "!text-[#5441DA]" : ""}`}>Groups</p>
                    <p className={`${provisioning?.activeStep === 7 ? "!text-[#5441DA]" : ""}`}>Roles</p>
                    <p className={`${provisioning?.activeStep === 9 ? "!text-[#5441DA]" : ""}`}>User Status</p> */}
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default ProvisionStepper;
