import * as Yup from "yup";
import { passwordPolicyMessage } from "./passwordSchemaMessage";


const getPasswordValidationSchema = (apiData: any) => {
  const { passwordLength, uppercaseCharacters, lowercaseCharacters, specialCharacters, digits } = apiData;

  let passwordRules = Yup.string()
    .min(passwordLength, `Password must be ${passwordLength} characters`)
    .required("Password is required");

  if (uppercaseCharacters) {
    passwordRules = passwordRules.matches(/^(?=.*[A-Z])/, passwordPolicyMessage.uppercaseCharacters);
  }

  if (lowercaseCharacters) {
    passwordRules = passwordRules.matches(/^(?=.*[a-z])/, passwordPolicyMessage.lowercaseCharacters);
  }

  if (specialCharacters) {
    passwordRules = passwordRules.matches(/^(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?`~])/, passwordPolicyMessage.specialCharacters);
  }

  if (digits) {
    passwordRules = passwordRules.matches(/^(?=.*\d)/, passwordPolicyMessage.digits);
  }

  return passwordRules;
};

export const createValidationSchema = (apiData: any) => {
  const passwordRules = getPasswordValidationSchema(apiData);

  return Yup.object({
    password: passwordRules,
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), undefined], "Passwords must match")
      .required("Please confirm your password"),
  });
};

export const temporaryPasswordValidationSchema = (apiData: any) => {
  const passwordRules = getPasswordValidationSchema(apiData);

  return Yup.object({
    temporaryPassword: passwordRules,
  });
};

// export const createValidationSchema = (apiData: any) => {
//   const { passwordLength, uppercaseCharacters, lowercaseCharacters, specialCharacters, digits } = apiData;

//   let passwordRules = Yup.string().min(passwordLength, `Password must be ${passwordLength} characters`).required("Password is required");

//   if (uppercaseCharacters) {
//     passwordRules = passwordRules.matches(/^(?=.*[A-Z])/, passwordPolicyMessage.lowercaseCharacters);
//   }

//   if (lowercaseCharacters) {
//     passwordRules = passwordRules.matches(/^(?=.*[a-z])/, passwordPolicyMessage.lowercaseCharacters);
//   }

//   if (specialCharacters) {
//     passwordRules = passwordRules.matches(/^(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?`~])/, passwordPolicyMessage.specialCharacters);
//   }

//   if (digits) {
//     passwordRules = passwordRules.matches(/^(?=.*\d)/, passwordPolicyMessage.digits);
//   }

//   return Yup.object({
//     password: passwordRules,
//     confirmPassword: Yup.string()
//       .oneOf([Yup.ref("password"), undefined], "Passwords must match")
//       .required("Please confirm your password"),
//   });
// };
