import React, { useEffect, useState } from "react";
import { message, Modal } from "antd";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../../../services/storage/Storage";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../../../layouts/component/CustomButton";
import Loader from "../../../../../layouts/component/Loader";
export default function ViewOpenIdAndSamlModal({ isOpen, onRequestClose, url, isXml }: any) {
  const getXMLContent = useGetApiRequests("getApplicationUserList", "GET");

  const [data, setData] = useState<any>("");
  const [loader, setLoader] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const realmId = retrieveData("realmId", true);
  const init = async () => {
    try {
      setLoader(true);

      if (isXml) {
        const pathParams: any = {
          id: `${realmId}/saml/descriptor`,
        };

        try {
          const response: any = await getXMLContent("", "", pathParams);
          const status = response.status;
          if (status === 200) {
            setData(response?.data);
          }
        } catch (err: any) {
          handleRequestError(err);
        }
      } else {
        const response = await fetch(url);
        const data = await response.json(); // Assuming the response is JSON
        const prettyPrintedContent = JSON.stringify(data, null, 2); // Pretty print with 2 spaces
        setData(prettyPrintedContent);
      }

      setLoader(false);
    } catch (error) {
      handleRequestError(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, url]);

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Copied!",
    });
  };
  const handleCopyFunction = () => {
    navigator.clipboard
      .writeText(data)
      .then(() => {
        success();
      })
      .catch((err: any) => {
        console.error("Failed to copy: ", err);
      });
  };
  return (
    <Modal
      title={isXml ? "SAML Descriptor" : "OpenID Endpoint Configuration"}
      className="show-notification  max-h-[600px] w-[700px] "
      maskClosable={false}
      centered
      open={isOpen}
      footer={[
        <div key="footer-container" className="w-full flex justify-center">
          <CustomButtonBack text={"Close"} onClick={onRequestClose} />
          <CustomButtonNext onClick={handleCopyFunction} text={"Copy"} />
        </div>,
      ]}
      onCancel={onRequestClose}
    >
      <div className="min-h-550px overflow-y-auto relative  max-h-[500px]" data-testid="saml-div">
        <pre className="overflow-y-auto max-h-full min-h-full h-full whitespace-pre-wrap break-words"> {data}</pre>
      </div>
      <div className="modal-footer fixed bottom-0 flex justify-center ">{contextHolder}</div>
      {loader && <Loader />}
    </Modal>
  );
}
