import React from "react";
import { Radio, Select, Button, Input } from "antd";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { validationSchemaForIp } from "./SelectIpValidationSchema";
import UsersSearchDropdown from "../../../dashboard/user/user-dashboard/groups/UserSearchModal";
import SelectIPAssignTo from "./SelectIPAssignTo";

const SelectIPEditForm = (props: any) => {
  const {
    isEdit,
    initialValues,
    onSubmit,
    type,
    authorizationType,
    chooseIpType,
    getClassName,
    ipErrorMessage,
    setDropdownVisible,
    dropdownVisible,
    handleChangeGroups,
    filterOption,
    userSearchGroupData,
    selectedValues,
    handleUserLabelClick,
    handleChangeUsers,
    usersSearchList,
    groupData,
    listOfUsers,
    selectedRowKeys,
    rowSelection,
    groupColumnsIp,
    columnsIp,
    handleRemoveList,
    unassignUser,
    handleAddGroups,
    handleAddUsers,
  } = props.props;

  const dropdownRender = (menu: any) => {
    return (
      <div>
        <div>{menu}</div>
        <UsersSearchDropdown handleAddApps={type === "groups" ? handleAddGroups : handleAddUsers} setDropdownVisible={setDropdownVisible} />
      </div>
    );
  };
  return (
    <div className="ip-container ">
      {isEdit && (
        <div className="mx-auto selectIpEdit">
          <Formik initialValues={initialValues} validationSchema={validationSchemaForIp} onSubmit={onSubmit} enableReinitialize={true}>
            {({ handleSubmit, setFieldValue, values }) => {
              return (
                <Form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <div className="w-full flex pt-0 flex-wrap relative">
                    <div className="grid lg:grid-cols-2 2xl:grid-cols-3 gap-11 w-full">
                      <div className="relative">
                        <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Name*</p>
                        <Field as={Input} type="text" className="h-[48px] font-medium text-[18px]" name="name" maxLength={30} />
                        <ErrorMessage name="name" component="div" className="error-message" />
                      </div>
                      <div className="relative">
                        <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Description*</p>
                        <Field as={Input} type="text" className="h-[48px] font-medium text-[18px]" name="description" maxLength={100} />
                        <ErrorMessage name="description" component="div" className="error-message" />
                      </div>
                    </div>
                    <div className="grid lg:grid-cols-2 2xl:grid-cols-3 gap-11 w-full mt-5 pt-5">
                      <div className="relative">
                        <div className="">
                          <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Choose type for IP*</p>
                          <Radio.Group
                            disabled={type !== "organization"}
                            name="authorizationType"
                            value={authorizationType}
                            className="flex pt-3"
                            onChange={(e) => chooseIpType(e.target.value, setFieldValue)}
                          >
                            <Radio value={"Whitelist"}>
                              <div className="flex flex-col mr-5">
                                <p className="user-type ">Whitelist IP</p>
                              </div>
                            </Radio>
                            <Radio className="hidden" value={"Blacklist"}>
                              <div className="flex  flex-col">
                                <p className="user-type ">Blacklist IP</p>
                              </div>
                            </Radio>
                          </Radio.Group>
                        </div>
                        <ErrorMessage name="authorizationType" component="div" className="error-message" />
                      </div>
                    </div>
                    <div className="w-[70%]">
                      <FieldArray name="ipDetails">
                        {({ push, remove }) => {
                          return (
                            <>
                              {values.ipDetails.map((field: any, index: any) => {
                                let pattern = "^\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}$";
                                let placeholder = "xxx.xxx.xxx.xxx";
                                let maxLength = 15;
                                if (field.ipRange === "range16") {
                                  maxLength = 7;
                                  placeholder = "xxx.xxx";
                                  pattern = "^\\d{1,3}\\.\\d{1,3}$";
                                } else if (field.ipRange === "range24") {
                                  maxLength = 11;
                                  placeholder = "xxx.xxx.xxx";
                                  pattern = "^\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}$";
                                }
                                return (
                                  <div className="w-full relative mt-4" key={field.id}>
                                    <div className="w-full flex justify-between relative md:flex-wrap  gap-2">
                                      <div className="lg:mb-4 lg:w-[32%]  md:w-[100%] relative lg:mt-0 mt-6">
                                        <p className={`${getClassName(index)} text-[#1D1D24] text-[18px] font-Inter font-medium md:opacity-100`}>Range*</p>
                                        <Field
                                          showSearch
                                          disabled={!field.new}
                                          as={Select}
                                          suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                                          className="w-[100%] h-[48px]"
                                          value={field.ipRange}
                                          name={`ipDetails[${index}].ipRange`}
                                          onChange={(value: any) => setFieldValue(`ipDetails[${index}].ipRange`, value)}
                                          placeholder="Select"
                                          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                          data-testid="userInput-ipRange"
                                        >
                                          <Select.Option value="exactIp">Exact IP</Select.Option>
                                          <Select.Option value="range16">Range 16</Select.Option>
                                          <Select.Option value="range24">Range 24</Select.Option>
                                        </Field>
                                        <ErrorMessage name={`ipDetails[${index}].ipRange`} component="div" className="error-message" />
                                      </div>
                                      <div className="lg:mb-4 lg:w-[32%] md:w-[100%] selectIP relative lg:mt-0 mt-6">
                                        {field.ipRange !== "" && field.ipRange !== "exactIp" && <div className="selectIP-highlighted">{field.ipRange === "range16" ? ".0.0/16" : ".0/24"}</div>}
                                        <p className={`${getClassName(index)} text-[#1D1D24] text-[18px] font-Inter font-medium md:opacity-100`}>IPv4*</p>
                                        <Field
                                          placeholder={placeholder}
                                          as={Input}
                                          data-testid="ip-address"
                                          type="text"
                                          name={`ipDetails[${index}].IPv4`}
                                          className="h-[48px] text-[18px] font-medium"
                                          maxLength={maxLength}
                                          disabled={field.ipRange === ""}
                                          pattern={pattern}
                                          onChange={(event: any) => {
                                            const valueWithoutSpaces = event.target.value.replace(/\s+/g, "");
                                            setFieldValue(`ipDetails[${index}].IPv4`, valueWithoutSpaces);
                                          }}
                                        />
                                        <ErrorMessage name={`ipDetails[${index}].IPv4`} className="error-message" component="div" />
                                      </div>

                                      <div className="lg:mb-4 lg:w-[32%] md:w-[100%] selectIP relative lg:mt-0 mt-6">
                                        <p className={`${getClassName(index)} text-[#1D1D24] text-[18px] font-Inter font-medium md:opacity-100`}>IPv6</p>
                                        <Field
                                          as={Input}
                                          placeholder="xxxx:xxxx:xxxx:xxxx:xxxx:xxxx:xxxx:xxxx"
                                          type="text"
                                          disabled={field.ipRange !== "exactIp"}
                                          className="h-[48px] text-[18px] font-medium"
                                          name={`ipDetails[${index}].IPv6`}
                                          onChange={(event: any) => {
                                            const valueWithoutSpace = event.target.value.replace(/\s+/g, "");
                                            setFieldValue(`ipDetails[${index}].IPv6`, valueWithoutSpace);
                                          }}
                                          data-testid="userInput-ipv6"
                                        />
                                        <ErrorMessage name={`ipDetails[${index}].IPv6`} className="error-message" component="div" />
                                      </div>
                                    </div>
                                    <div className="flex right-[-130px] absolute top-7 items-center justify-center lg:mt-0 mt-6">
                                      <span
                                        role="none"
                                        className={`${values.ipDetails.length === 1 && "pointer-events-none"} add-custom ip remove material-symbols-outlined cursor-pointer`}
                                        onClick={() => handleRemoveList(index, remove, values.ipDetails[index])}
                                      >
                                        remove
                                      </span>
                                      {index === values.ipDetails.length - 1 && (
                                        <span
                                          role="none"
                                          className="cursor-pointer material-symbols-outlined add-custom ip"
                                          onClick={() => push({ ipRange: "", id: index, IPv4: "", IPv6: "", new: true })}
                                        >
                                          add
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          );
                        }}
                      </FieldArray>
                    </div>
                    <div className="mb-2 w-full selectIP relative mt-5 grid lg:grid-cols-1 2xl:grid-cols-3 gap-11">
                      <Button htmlType="submit" className="btn col-end-5 col-span-2 bg-[#5441DA] w-full h-[55px] text-[20px] mt-5" type="primary" disabled={ipErrorMessage !== ""}>
                        Update
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
      {isEdit && type !== "organization" && (
        <SelectIPAssignTo
          props={{
            type: type,
            setDropdownVisible: setDropdownVisible,
            dropdownVisible: dropdownVisible,
            handleChangeGroups: handleChangeGroups,
            filterOption: filterOption,
            dropdownRender: dropdownRender,
            userSearchGroupData: userSearchGroupData,
            selectedValues: selectedValues,
            handleUserLabelClick: handleUserLabelClick,
            usersSearchList: usersSearchList,
            groupData: groupData,
            listOfUsers: listOfUsers,
            selectedRowKeys: selectedRowKeys,
            rowSelection: rowSelection,
            groupColumnsIp: groupColumnsIp,
            unassignUser: unassignUser,
            columnsIp: columnsIp,
            handleChangeUsers: handleChangeUsers,
          }}
        />
      )}
    </div>
  );
};
export default SelectIPEditForm;
