import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  provisioningConfigEdit: {};
  SSOConfigEdit: {};
  provisioningEditStatus: boolean;
  SSOEditStatus: boolean;
  isConfigureButtonPressed:boolean;
}
const initialState: AuthState = {
  provisioningConfigEdit: {
    isActiveConfig: false,
  },
  SSOConfigEdit: {
    isActiveConfig: false,
  },
  provisioningEditStatus: false,
  SSOEditStatus: false,
  isConfigureButtonPressed: false,
};

const appConfigureSlice: any = createSlice({
  name: "appConfig",
  initialState,
  reducers: {
    setProvisioningConfigEdit: (state, action: PayloadAction<any>) => {
      state.provisioningConfigEdit = action.payload;
    },
  
    setSSOConfigEdit: (state, action: PayloadAction<any>) => {
      state.SSOConfigEdit = action.payload;
    },
    setProvisioningEditStatus: (state, action: PayloadAction<boolean>) => {
      state.provisioningEditStatus = action.payload;
    },
    setSSOEditStatus: (state, action: PayloadAction<boolean>) => {
      state.SSOEditStatus = action.payload;
    }, 
    setIsConfigureButtonPressed: (state, action: PayloadAction<boolean>) => {
      state.isConfigureButtonPressed = action.payload;
    },

    clearAllEditData: () => {
      return { ...initialState };
    },
  },
});

export const { setProvisioningConfigEdit, setSSOConfigEdit, setSSOEditStatus, setProvisioningEditStatus, clearAllEditData ,setIsConfigureButtonPressed} = appConfigureSlice.actions;
export default appConfigureSlice.reducer;