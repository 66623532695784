import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Button, Collapse } from "antd";
import { retrieveData } from "../../../../../../services/storage/Storage";
import useGetApiRequests from "../../../../../../services/axios/useApiRequests";
import { ConnectorConfig, FormValues, UpdateParams } from "../types/provisioningTypes";
import {
  setActiveStep,
  setEndPointResponseDtosLen,
  setProvisioning,
  setProvisioningButtonStatus,
  setProvisioningConnectorEndpointConfigId,
} from "../../../../../../redux/slice/provisioning/ProvisioningSlice";
import { connectorProvisionSchema, endPointDescriptionPayload, transformHeaderParameters } from "../../helper/connectorHelper";
import { handleRequestError } from "../../../../../../layouts/toast/ErrorNotificationMessage";
import { setCurrentStep } from "../../../../../../redux/slice/app-store/AppDetailsSlice";
import CustomButtonBack from "../../../../../../layouts/component/CustomButtonBack";
import UserProvision from "./user-provisioning/UserProvision";
import SuccessMessageModal from "../../../../../access-manager/create/IpTimeSuccessModal";

const ConnectorProvision = () => {
  const dispatch = useDispatch();
  const formInitialValues = useSelector((state: any) => state?.provisioning?.provisioning);
  const provisioning = useSelector((state: any) => state?.provisioning);
  const provisioningBackButtonPressed = useSelector((state: any) => state?.provisioning?.provisioningBackButtonClicked);
  const akkuProvisioningConnectorEndpointConfigId = useSelector((state: any) => state.provisioning?.akkuProvisioningConnectorEndpointConfigId);
  const generalDetails = provisioning?.generalDetails;
  const buttonStatus = useSelector((state: any) => state?.provisioning?.duplicateProvisioningButtons);
  const realmId = retrieveData("realmId", true);
  const provisioningConnectorConfigure = useGetApiRequests("provisioningConnectorConfigure", "POST");
  const provisioningConnectorConfigureUpdate = useGetApiRequests("provisioningConnectorConfigureUpdate", "PUT");

  const [activeKey, setActiveKey] = useState<string | string[]>("1");
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [enableTest, setEnableTest] = useState<boolean>(false);
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  // const [connectorProvisionDirty,setConnectorProvisionDirty] = useState<boolean>(false);
  useEffect(() => {
    if (buttonStatus.connectorProvisioning) {
      setEnableTest(true);
      setIsDuplicate(true);
    }
  }, [buttonStatus?.connectorProvisioning]);

  const handleCloseSuccessModal = () => {
    setSuccessModal(false);
  };

  const handleSubmit = (values: FormValues) => {
    createPayloadConnectorConfigure(values);
    dispatch(setProvisioning(values));
  };

  const createPayload = (values: FormValues): ConnectorConfig => {
    const { userProvision } = values;
    const baseConfig = {
      apiEndpointUrl: userProvision[0]?.apiEndpointURL,
      methodType: userProvision[0]?.methodType,
      endpointDescription: endPointDescriptionPayload(userProvision[0]?.methodType),
      requestPayload: userProvision[0]?.requestPayload,
      sampleResponse: userProvision[0]?.response,
      headerParameters: transformHeaderParameters(values?.userProvision[0]?.headerParam),
      isPathParameter: userProvision[0]?.headerParametersType === "true",
      realmId: realmId,
      name: generalDetails?.name,
      type: "request",
      // ...(userProvision[0]?.headerParametersType && {
      //   isPathParameter: userProvision[0]?.headerParametersType === "true",
      // }),
    };

    if (provisioning?.isActiveEdit) {
      return {
        ...baseConfig,
        akkuProvisioningConnectorEndpointConfigId: formInitialValues?.userProvision[0]?.akkuProvisioningConnectorEndpointConfigId,
      };
    }

    return baseConfig;
  };

  const createPayloadConnectorConfigure = async (values: FormValues) => {
    const payload: any = createPayload(values);
    const shouldUpdate = !buttonStatus.connectorProvisioning && ((provisioning.isActiveEdit && provisioning.endPointResponseDtosLen > 0) || provisioningBackButtonPressed);
    if (shouldUpdate) {
      payload.akkuProvisioningConnectorEndpointConfigId = akkuProvisioningConnectorEndpointConfigId || formInitialValues?.userProvision[0]?.akkuProvisioningConnectorEndpointConfigId;
      payload.isActive = provisioning.isActive;
      handleUpdateApiCall(payload);
    } else {
      handleCreateApiCall(payload);
    }
    // if(!provisioning.isDuplicate){
    //   if ((provisioning.isActiveEdit && provisioning.endPointResponseDtosLen > 0) || provisioningBackButtonPressed ) {
    //     payload.akkuProvisioningConnectorEndpointConfigId =  akkuProvisioningConnectorEndpointConfigId ===""?formInitialValues?.userProvision[0]?.akkuProvisioningConnectorEndpointConfigId :akkuProvisioningConnectorEndpointConfigId;
    //     handleUpdateApiCall(payload);
    //   } else {
    //     handleCreateApiCall(payload);
    //   }
    // }else{
    //   handleCreateApiCall(payload);
    // }
  };

  const handleApiCall = async (apiCall: (payload: ConnectorConfig, config?: any, params?: UpdateParams) => Promise<any>, payload: ConnectorConfig, params?: UpdateParams) => {
    try {
      const response = await apiCall(payload, {}, params);
      const akkuProvisioningConnectorEndpointConfigId = response?.data?.data?.akkuProvisioningConnectorEndpointConfigId;
      dispatch(setProvisioningConnectorEndpointConfigId(akkuProvisioningConnectorEndpointConfigId));
      handleApiResponse(response);
    } catch (err) {
      handleRequestError(err);
      setLoader(false);
      setEnableTest(true);
    }
  };

  const handleApiResponse = (response: any) => {
    const status = response.status;
    if (status === 200) {
      if (provisioning.endPointResponseDtosLen === 0) {
        dispatch(setEndPointResponseDtosLen(1));
      }
      setLoader(false);
      setResponseMessage(response.data.message);
      setSuccessModal(true);
      setEnableTest(false);
      setTimeout(() => {
        if (provisioning?.isDuplicate) {
          dispatch(setProvisioningButtonStatus({ ...buttonStatus, connectorProvisioning: false, connectorDeprovisioning: true }));
        }
        handleCloseSuccessModal();
        dispatch(setActiveStep(3));
      }, 3000);
    }
  };

  const handleCreateApiCall = async (payload: ConnectorConfig) => {
    await handleApiCall(provisioningConnectorConfigure, payload);
  };

  const handleUpdateApiCall = async (payload: ConnectorConfig) => {
    const params: UpdateParams = { akkuProvisioningConnectorId: provisioning.akkuProvisioningConnectorId };
    await handleApiCall(provisioningConnectorConfigureUpdate, payload, params);
  };

  const handleBack = () => {
    const updatedStepper = {
      activeStep: 1,
      childStepper: "",
    };
    dispatch(setCurrentStep(updatedStepper));
    dispatch(setActiveStep(1));
  };
  const handleNext = () => {
    if (provisioning?.isDuplicate) {
      dispatch(setProvisioningButtonStatus({ ...buttonStatus, connectorDeprovisioning: true }));
    }
    dispatch(setActiveStep(3));
  };

  const handleCollapseChange = (key: string | string[]) => {
    setActiveKey(key);
  };
  return (
    <div className="w-full px-10 pb-20 connector-provision">
      <p className="app-header py-8">Connector Details - Provisioning {provisioning?.isDuplicate ? "(Copy)" : null}</p>
      <div className="w-full mx-auto acc-provision">
        <Formik initialValues={formInitialValues} values={formInitialValues} onSubmit={handleSubmit} validationSchema={connectorProvisionSchema} enableReinitialize={true}>
          {({ values, setFieldValue, dirty }) => {
            return (
              <Form>
                <div className=" w-full flex flex-wrap flex-col gap-2  justify-center">
                  <Collapse
                    accordion
                    activeKey={activeKey}
                    onChange={handleCollapseChange}
                    items={[
                      {
                        key: "1",
                        label: <p className="font-normal text-[#000] font-Inter text-[18px]">User Provisioning</p>,
                        children: <UserProvision values={values} setFieldValue={setFieldValue} />,
                      },
                    ]}
                    className="w-[95%] custom-header bg-[#fff]"
                    expandIconPosition="end"
                  />
                </div>

                <div className="footer flex items-center absolute bottom-0 right-0 w-full bg-[#fff] h-[100px]">
                  <div className="modal-footer w-full mx-auto ">
                    <div className="w-full flex justify-end pr-5">
                      <CustomButtonBack text={"Back"} onClick={() => handleBack()} />
                      {!isDuplicate && (provisioning.isActiveEdit || provisioningBackButtonPressed) ? (
                        dirty || enableTest || provisioning.endPointResponseDtosLen === 0 ? (
                          <Button loading={loader} type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn">
                            {provisioning.endPointResponseDtosLen > 0 ? "Update" : "Next"}
                          </Button>
                        ) : (
                          <Button type="primary" onClick={handleNext} className="bg-[#5441DA] submit-btn">
                            Next
                          </Button>
                        )
                      ) : (
                        <Button loading={loader} type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn">
                          Next
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {successModal && <SuccessMessageModal open={successModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
    </div>
  );
};

export default ConnectorProvision;
