import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  appDetails: {};
  currentStep: {};
  connectorDetailsAuthType: string;
  myAppsCount: number;
}

const initialState: AuthState = {
  appDetails: {},
  currentStep: {
    activeStep: 0,
    childStepper: "",
  },
  connectorDetailsAuthType: "",
  myAppsCount: 0,
};

const courseTabSlice: any = createSlice({
  name: "navigateCourseTab",
  initialState,
  reducers: {
    setAppConfigDetails: (state, action: PayloadAction<boolean>) => {
      state.appDetails = action.payload;
    },
    setCurrentStep: (state, action: PayloadAction<boolean>) => {
      state.currentStep = action.payload;
    },
    setMyAppsCount: (state, action: PayloadAction<number>) => {
      state.myAppsCount = action.payload;
    },
    clearAppDetails: (state) => {
      state.appDetails = {};
    },
    clearCurrentStep: (state) => {
      state.currentStep = {};
    },
    setConnectorDetailsAuthType: (state, action) => {
      state.connectorDetailsAuthType = action.payload;
    },
  },
});

export const { setAppConfigDetails, clearAppDetails, setCurrentStep, clearCurrentStep, setConnectorDetailsAuthType, setMyAppsCount } = courseTabSlice.actions;
export default courseTabSlice.reducer;
