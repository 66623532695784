import React, { useEffect, useState } from "react";
import { Modal, Table } from "antd";
import { prerequisitesTable } from "../../../../../../constant/prerequisites/prerequisites";
import { resetPasswordHistoryTableColumns } from "./ResetPasswordHistoryModalHelper";

const ResetPasswordHistoryModal = (props: any) => {
  const { handleCloseModal, modalOpen, temporaryPasswordEvents } = props;

  const [customerTableData, setCustomerTableData] = useState([]);

  useEffect(() => {
    setCustomerTableData(temporaryPasswordEvents);
  }, [temporaryPasswordEvents]);

  return (
    <Modal className="rounded-lg app-details" maskClosable={false} centered open={modalOpen} footer={false} onCancel={handleCloseModal}>
      <div className="modal-header border-b">
        <p className="title">Reset Password History</p>
      </div>
      <div className="modal-body  justify-center">
        <div className="w-full ">
          <div>
            <p className="text-[#212121] text-[18px] italic font-Inter py-4 font-medium">Total Reset list :{customerTableData?.length}</p>
          </div>
          <div className="w-full">
            <Table
              pagination={{
                showSizeChanger: true,
                pageSizeOptions: prerequisitesTable.pageSizeOptions,
              }}
              columns={resetPasswordHistoryTableColumns}
              dataSource={customerTableData}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ResetPasswordHistoryModal;
