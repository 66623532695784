import React, { useEffect, useState } from "react";
import CardTitle from "../../component/CardTitle";
import batch from "../../../assets/images/icons/mfa.png";
import ChartMFA from "./Chart";
import { useSelector } from "react-redux";

const MFAChart = () => {
  const [mfaDetails, setMfaDetails] = useState<any>(null);
  const [allZero, setAllZero] = useState(false);
  const getMFADetails = useSelector((state: any) => state?.dashboardDetails?.data);
  useEffect(() => {
    const storeValue = getMFADetails?.multiFactorAuthorizationRepresentation;
    setMfaDetails(storeValue);
    const checkAllZero = storeValue?.every((obj: any) => obj?.percentageOfMfaUsed === 0);
    setAllZero(checkAllZero);
  }, [getMFADetails]);

  return (
    <div className="_card  w-full rounded-lg h-[500px] mfa-container ">
      <div className="_card-header --no-border ">
        <div className="title">
          <CardTitle title="MFA" src={batch} />
        </div>
      </div>
      {allZero ? (
        <div className="flex justify-center items-center h-[70%]">
          <p className="font-Inter text-[20px] font-medium text-[#444] pt-1 text-rwd16 text-center">No MFA available</p>
        </div>
      ) : (
        <div className="px-16 h-[430px] mfa-chart  ">
          <div className="chart-container mx-auto">{<ChartMFA data={mfaDetails} />}</div>
        </div>
      )}
    </div>
  );
};

export default MFAChart;
