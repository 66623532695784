import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu as AntMenu } from "antd";
import { images } from "../../assets/images/ImagePaths";
import ImageView from "../component/ImageView";
import { retrieveData, storeData } from "../../services/storage/Storage";
import { modulePermissions } from "../../const/RolePermissions";
import { hasPermission } from "../../redux/slice/permissions/permissionUtils";
import { useSelector } from "react-redux";
import { ignoredRoutes } from "./ignoredRoutes";
import PermissionsModal from "../permissionsModal/PermissionsModal";
import { menuList } from "../../const/Menu";
import { availableRoutes } from "../header/headerHelper";
import useGetApiRequests from "../../services/axios/useApiRequests";
import LicenseExceedsModal from "./LicenseExceedsModal/LicenseExceedsModal";
import { handleRequestError } from "../toast/ErrorNotificationMessage";
import "./sideBarStyle.scss";

const { Sider } = Layout;

type MenuItemProps = {
  item: any;
  handleNavigate: (key: any, name: string) => void;
  setPermissionsModal: (value: boolean) => void;
  hasViewPermission: boolean;
  hasViewAppStorePermission: boolean;
  hasViewAccessMangerPermission: boolean;
  hasViewPasswordMangerPermission: boolean;
  hasViewDeviceMangerPermission: boolean;
  permission: any;
};

const MenuItemComponent: React.FC<MenuItemProps> = ({
  item,
  handleNavigate,
  setPermissionsModal,
  hasViewPermission,
  hasViewAppStorePermission,
  hasViewAccessMangerPermission,
  hasViewPasswordMangerPermission,
  hasViewDeviceMangerPermission,
  permission,
}) => {
  const checkViewPermission = () => {
    switch (item.label) {
      case menuList.userManagement:
        return hasViewPermission;
      case menuList.appManagement:
        return hasViewAppStorePermission;
      case menuList.accessManager:
        return hasViewAccessMangerPermission;
      case menuList.passwordManager:
        return hasViewPasswordMangerPermission;
      case menuList.deviceManager:
        return hasViewDeviceMangerPermission;
      case menuList.roleManagement:
        return permission.admin;
      default:
        return true;
    }
  };

  const isView = checkViewPermission();
  return (
    <>
      {isView ? (
        <Link
          to={item.to}
          onClick={() => {
            handleNavigate(item.key, item.label);
          }}
        >
          {item.label}
        </Link>
      ) : (
        <span
          role="none"
          onClick={() => {
            setPermissionsModal(true);
          }}
        >
          {item.label}
        </span>
      )}
    </>
  );
};

type MenuItem = {
  key: React.Key;
  label: React.ReactNode;
  icon?: React.ReactNode;
  to?: string;
  children?: MenuItem[];
};

function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, to?: string, children?: MenuItem[]): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    to,
  };
}

const items: MenuItem[] = [
  getItem(menuList.smartAnalytics, "1", <span className="material-icons text-[#fff] --menu">home</span>, "/admin-dashboard"),
  getItem(menuList.userManagement, "2", <span className="material-icons --menu">groups</span>, "/user"),
  getItem(menuList.appManagement, "3", <span className="material-icons --menu">app_registration</span>, "/app-store"),
  getItem(menuList.roleManagement, "4", <span className="material-symbols-outlined --menu">clinical_notes</span>, "/role-management"),
  getItem(menuList.accessManager, "5", <span className="material-symbols-outlined --menu">key</span>, "/access-manager"),
  getItem(menuList?.passwordManager, "6", <span className="material-symbols-outlined --menu">encrypted</span>, "/password-management"),
  getItem(menuList.credentialManagement, "7", <span className="material-symbols-outlined --menu">badge</span>, "/credential-manager"),
  getItem(menuList?.deviceManager, "8", <span className="material-symbols-outlined">computer</span>, "/device-management"),
];

const SideBar = (props: any) => {
  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const permission = useSelector((state: any) => state?.permissionsSlice);

  const hasViewPermission = permissions && hasPermission(permissions, modulePermissions.userManagement, "view");
  const hasViewAppStorePermission = permissions && hasPermission(permissions, modulePermissions.appStore, "view");
  const hasViewAccessMangerPermission = permissions && hasPermission(permissions, modulePermissions.accessManger, "view");
  const hasViewPasswordMangerPermission = permissions && hasPermission(permissions, modulePermissions.passwordManager, "view");
  const hasViewDeviceMangerPermission = permissions && hasPermission(permissions, modulePermissions.deviceManager, "view");
  const { setCollapse } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [key, setKey] = useState<any>("1");
  const [licenseExceeds, setLicenseExceeds] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>("");
  const [adminAccessDisabled, setAdminAccessDisabled] = useState<boolean>(false);
  const [permissionsModal, setPermissionsModal] = useState<boolean>(false);
  const token = retrieveData("authToken", true);
  const currentVersion = process.env.REACT_APP_VERSION;

  const location = useLocation();
  const navigate = useNavigate();

  const realmId = retrieveData("realmId", true);
  const getLicenseCount = useGetApiRequests("getLicenseCount", "GET");

  useEffect(() => {
    const activeBar = retrieveData("key", false);
    handleResize();
    if (realmId && token && token !== "") {
      getUsersLicenseCount();
    }
    window.addEventListener("resize", handleResize);
    const matchingItem = items.find((item: any) => location.pathname === item.to || location.pathname.includes(item.to));
    if (matchingItem) {
      setKey(matchingItem.key.toString());
    } else if (activeBar) {
      setKey(activeBar.toString());
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const getUsersLicenseCount = async () => {
    const pathParam = {
      realmId: realmId,
    };

    try {
      const response: any = await getLicenseCount("", {}, pathParam);
      const status = response.status;

      if (status === 200) {
        setLicenseExceeds(false);
        setAdminAccessDisabled(false);
      }
    } catch (err: any) {
      if (err?.response?.status === 400) {
        const errorResponseMessage = err?.response?.data?.message;
        setLicenseExceeds(true);
        setResponseMessage(errorResponseMessage);
        if (errorResponseMessage?.includes("avoid suspension")) {
          setAdminAccessDisabled(true);
        } else {
          setAdminAccessDisabled(false);
        }
      } else {
        handleRequestError(err);
      }
    }
  };
  const closeLicenseExceedsModal = () => {
    setLicenseExceeds(false);
  };
  const handleResize = () => {
    let deviceWidth = window.innerWidth <= 1380;
    setCollapsed(deviceWidth);
    setCollapse(deviceWidth);
  };

  if (ignoredRoutes.includes(location.pathname)) {
    return null;
  }
  if (!availableRoutes.includes(location.pathname)) {
    return null;
  }

  const handleNavigate = (key: any, name: string) => {
    setKey(key);
    storeData("key", key, false);
  };

  const handleCollapse = (value: boolean) => {
    setCollapsed(value);
  };

  const menuPermissionsStyle = (item: any) => {
    if (item.label === menuList.userManagement) {
      return !hasViewPermission ? "permission-disabled-menu" : "";
    } else if (item.label === menuList.appManagement) {
      return !hasViewAppStorePermission ? "permission-disabled-menu" : "";
    }
    if (item.label === menuList.roleManagement) {
      return !permission.admin ? "permission-remove-menu" : "";
    }
    if (item.label === menuList.accessManager) {
      return !hasViewAccessMangerPermission ? "permission-disabled-menu" : "";
    }
    if (item.label === menuList.passwordManager) {
      return !hasViewPasswordMangerPermission ? "permission-disabled-menu" : "";
    }
    if (item.label === menuList.deviceManager) {
      return !hasViewDeviceMangerPermission ? "permission-disabled-menu" : "";
    }
  };
  const handleClickNavigation = () => {
    storeData("key", "1", false);
    navigate("/admin-dashboard");
  };

  const handleClickToggle = () => {
    const toggle: any = document.getElementsByClassName("ant-layout-sider-trigger")[0];
    toggle.click();
  };

  return (
    <>
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => handleCollapse(value)} className={!collapsed ? "isNotActiveCollapsed" : "activeCollapsed"}>
        <div className="demo-logo-vertical" />
        <img
          role="none"
          onClick={() => {
            handleClickToggle();
          }}
          className="h-8 mt-6 menu-icon cursor-pointer"
          src={images.menuIcon}
          alt="brand logo"
          data-testid="menuId"
        />

        <div role="none" className="branLogo flex justify-center pt-10 xl:pt-16  pb-8 xl:pb-10 cursor-pointer" onClick={() => handleClickNavigation()}>
          <ImageView className="" src={images.brandLogo} alt="brand logo" />
        </div>
        <AntMenu theme="dark" selectedKeys={[key || "1"]} mode="inline">
          {items.map((item) => {
            if (item.children) {
              return (
                <AntMenu.SubMenu key={item.key} icon={item.icon} title={item.label}>
                  {item.children.map((child) => (
                    <AntMenu.Item key={child.key}>{child.to ? <Link to={child.to}>{child.label}</Link> : child.label}</AntMenu.Item>
                  ))}
                </AntMenu.SubMenu>
              );
            }
            return (
              <AntMenu.Item key={item.key} icon={item.icon} className={menuPermissionsStyle(item)}>
                <MenuItemComponent
                  item={item}
                  handleNavigate={handleNavigate}
                  setPermissionsModal={setPermissionsModal}
                  hasViewPermission={hasViewPermission}
                  hasViewAppStorePermission={hasViewAppStorePermission}
                  hasViewAccessMangerPermission={hasViewAccessMangerPermission}
                  hasViewPasswordMangerPermission={hasViewPasswordMangerPermission}
                  hasViewDeviceMangerPermission={hasViewDeviceMangerPermission}
                  permission={permission}
                />
              </AntMenu.Item>
            );
          })}
        </AntMenu>
        {permissionsModal && <PermissionsModal open={permissionsModal} close={() => setPermissionsModal(false)} />}
        {!collapsed && <p className="version">Current version-{currentVersion}</p>}
      </Sider>
      {licenseExceeds && <LicenseExceedsModal openModal={licenseExceeds} closeModal={closeLicenseExceedsModal} responseMessage={responseMessage} adminAccessDisabled={adminAccessDisabled} />}
    </>
  );
};

export default SideBar;
