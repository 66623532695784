// React and third-party libraries
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button, Select, Input } from "antd";

// Redux-related imports
import { useDispatch, useSelector } from "react-redux";
import { setAppConfigDetails, setCurrentStep } from "../../../../../redux/slice/app-store/AppDetailsSlice";
import {
  setActiveStep,
  setAuthentication,
  setAuthenticationNextButtonClicked,
  setConnectorDetailDirty,
  setDeProvisionConnectorEndpointConfigId,
  setDeProvisioning,
  setDeProvisioningBackButtonClicked,
  setEndPointResponseDtosLen,
  setGeneralDetails,
  setIsActive,
  setProvisioning,
  setProvisioningBackButtonClicked,
  setProvisioningButtonStatus,
  setProvisioningConnectorEndpointConfigId,
} from "../../../../../redux/slice/provisioning/ProvisioningSlice";

// Utilities, helpers, and services
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import { createAppSchema } from "./ConnectorValidationSchema";
import { authenticationType, connectorType, connectorDetailsInitialValue, connectorAuthenticationReduxDetails } from "../helper/connectorHelper";
import { convertObjectToArray, initializeDeprovisioning, initializeProvisioning } from "./helper/ConnectorDetailsHelper";

// Custom components
import Loader from "../../../../../layouts/component/Loader";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";

const ConnectorDetails = (props: any) => {
  const generalDetails = useSelector((state: any) => state?.provisioning.generalDetails);
  const provisioning = useSelector((state: any) => state?.provisioning);
  const provisionDetails = useSelector((state: any) => state?.provisioning?.provisioning);
  const appDetails = useSelector((state: any) => state?.AppDetailsSlice?.appDetails);
  const deProvisionDetails = useSelector((state: any) => state?.provisioning?.deProvisioning);
  const isBackButtonPressed = useSelector((state: any) => state?.provisioning?.isBackButtonEnabled);
  const buttonStatus = useSelector((state: any) => state?.provisioning?.duplicateProvisioningButtons);
  const formInitialValues = useSelector((state: any) => state?.provisioning?.provisioning);

  const provisioningConnectorDetails = useGetApiRequests("provisioningConnectorDetails", "GET");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loader, setLoader] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState(connectorDetailsInitialValue);

  useEffect(() => {
    if (provisioning?.isActiveEdit && !isBackButtonPressed) {
      getProvisioningConnectorDetails();
    } else {
      dispatch(
        setGeneralDetails({
          ...generalDetails,
          name: appDetails?.name,
        }),
      );
    }
  }, []);

  useEffect(() => {
    setInitialValues(generalDetails);
  }, [generalDetails]);

  const handleSubmit = async (values: any) => {
    if (values.connectorType === "Database") {
      handleDBNext();
    } else {
      dispatch(setGeneralDetails(values));
      dispatch(setAppConfigDetails({ ...appDetails, name: values.name }));
      if (provisioning?.isDuplicate) {
        dispatch(setProvisioningButtonStatus({ ...buttonStatus, connectorAuthentication: true }));
      }
      handleNext();
    }
  };
  const handleBackToUser = () => {
    navigate("/app-store");
  };
  const handleDBNext = () => {
    const updatedStepper = {
      activeStep: 10,
      childStepper: "",
    };
    dispatch(setCurrentStep(updatedStepper));
  };
  const handleNext = () => {
    dispatch(setProvisioningButtonStatus({ ...buttonStatus, connectorDetails: true }));
    if (provisioning?.isDuplicate) {
      dispatch(setProvisioningButtonStatus({ ...buttonStatus, connectorAuthentication: true }));
    }
    dispatch(setActiveStep(1));
  };

  // Function to fetch provisioning connector details from the API
  const getProvisioningConnectorDetails = async () => {
    setLoader(true);
    let params = {
      akkuProvisioningConnectorId: provisioning.akkuProvisioningConnectorId,
    };
    try {
      const response: any = await provisioningConnectorDetails("", "", params);
      const status = response?.status;
      if (status === 200) {
        const data = response?.data?.data;

        await handleConnectorDetailsSuccess(data);
        await handlePrerequisites(data);
        // Reset button states in Redux store
        dispatch(setAuthenticationNextButtonClicked(false));
        dispatch(setProvisioningBackButtonClicked(false));
        dispatch(setDeProvisioningBackButtonClicked(false));

        // Reset endpoint configuration IDs in Redux store
        dispatch(setProvisioningConnectorEndpointConfigId(""));
        dispatch(setDeProvisionConnectorEndpointConfigId(""));
      }

      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  // Function to handle prerequisites (deprovisioning and provisioning setup)
  const handlePrerequisites = async (data: any) => {
    try {
      if (data?.endPointResponseDtos.length > 0) {
        data?.endPointResponseDtos?.forEach(async (endpoint: any) => {
          const headerParameters = convertObjectToArray(endpoint?.headerParameters);
          const pathVariable = convertObjectToArray(endpoint?.pathVariable);

          // Initialize deprovisioning process
          const initiateDeprovisioning = await initializeDeprovisioning(deProvisionDetails, endpoint, headerParameters, pathVariable);

          // Initialize provisioning process
          const initiateProvisioning = await initializeProvisioning(provisionDetails, endpoint, headerParameters, pathVariable);

          // Dispatch provisioning and deprovisioning actions to Redux store
          initiateProvisioning && dispatch(setProvisioning(initiateProvisioning));
          initiateDeprovisioning && dispatch(setDeProvisioning(initiateDeprovisioning));
        });
      }
    } catch (error) {
      console.error("Error during provisioning data setup:", error);
    }
  };

  // Function to handle the successful retrieval of connector details
  const handleConnectorDetailsSuccess = (data: any) => {
    const { name, description, type, isDefaultSourceConnector, authenticationType, isActive } = data;
    const authenticationDetails = connectorAuthenticationReduxDetails(data);

    const storeName = provisioning?.isDuplicate ? name + ` - (Copy)` : name;
    const generalDetails = {
      name: storeName,
      description,
      type,
      isDefaultSourceConnector: isDefaultSourceConnector ? "yes" : "No",
      authenticationType,
      isActive,
    };

    // Set the form's initial values and dispatch these details to Redux store
    setInitialValues(generalDetails);
    dispatch(setIsActive(isActive));
    dispatch(setGeneralDetails(generalDetails));
    dispatch(setAuthentication(authenticationDetails));
    dispatch(setEndPointResponseDtosLen(data?.endPointResponseDtos.length));
  };

  return (
    <div className="w-full px-10 pb-20 ">
      <p className="app-header pt-8">Connector Details - General Details {provisioning?.isDuplicate ? "(Copy)" : null}</p>
      <div className="w-full mx-auto overflow-y-auto">
        <Formik initialValues={initialValues} values={initialValues} enableReinitialize={true} validationSchema={createAppSchema} onSubmit={handleSubmit}>
          {({ values, setFieldValue, dirty }) => {
            dispatch(setConnectorDetailDirty(dirty));
            return (
              <Form>
                <div className="w-full pt-10  app-connector flex-wrap overflow-y-auto">
                  <div className="w-full   pb-32 xl:pb-0 app-connector flex-wrap connector-details overflow-y-auto ">
                    <div className="mb-12 w-[45%] input-container relative">
                      <p>Connector Name*</p>
                      <Field as={Input} type="text" className="form-type-box capitalize" name="name" data-testid="usersN-input" />
                      <ErrorMessage name="name" component="div" className="error-message" />
                    </div>
                    <div className="mb-12 w-[45%] input-container relative">
                      <p>Description*</p>
                      <Field as={Input} type="text" className="form-type-box" name="description" data-testid="usersDn-input" />
                      <ErrorMessage name="description" component="div" className="error-message" />
                    </div>
                    <div className="mb-12 w-[45%] input-container relative">
                      <p>Connector Type*</p>
                      <Field
                        as={Select}
                        disabled
                        placeholder="Select"
                        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                        className=" w-[100%] h-[56px]"
                        name="type"
                        value={values.type || undefined}
                        filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={(value: any) => setFieldValue("type", value)}
                      >
                        {connectorType?.map((option: any) => (
                          <Select.Option key={option.value} value={option.value}>
                            {option.label}
                          </Select.Option>
                        ))}
                      </Field>
                      <ErrorMessage name="type" component="div" className="error-message" />
                    </div>
                    {values?.type?.trim() === "RESTAPI" ? (
                      <div className="mb-12 w-[45%] input-container relative">
                        <p>Authentication Type*</p>
                        <Field
                          as={Select}
                          suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                          className="w-[100%] h-[56px]"
                          name="authenticationType"
                          value={values.authenticationType || undefined}
                          filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          onChange={(value: any) => setFieldValue("authenticationType", value)}
                          placeholder="Select"
                        >
                          {authenticationType?.map((option: any) => (
                            <Select.Option key={option.value} value={option.value}>
                              {option.label}
                            </Select.Option>
                          ))}
                        </Field>
                        <ErrorMessage name="authenticationType" component="div" className="error-message" />
                      </div>
                    ) : null}
                    {/* <div className="w-full app-radio flex">
                      <p>Is this a default data source connector?</p>
                      <div className="flex app-type">
                        <Radio.Group
                          onChange={(e) => {
                            setFieldValue("isDefaultSourceConnector", e.target.value);
                          }}
                          value={values.isDefaultSourceConnector}
                        >
                          {defaultConnector?.map((filter: any) => (
                            <div key={filter?.value}>
                              <Radio value={filter?.value}>{filter?.text}</Radio>
                            </div>
                          ))}
                        </Radio.Group>
                      </div>
                    </div> */}
                  </div>
                  {/* <>{initialValues.connectorType === "REST_API" ? <RestApiForm /> : <DBConnectorForm initialValues={initialValues} setFieldValue={setFieldValue} />}</> */}
                </div>
                <div className="footer flex items-center absolute bottom-0 right-0 w-full bg-[#fff] h-[100px]">
                  <div className="modal-footer w-full mx-auto ">
                    <div className="w-full flex justify-end pr-5">
                      <CustomButtonBack onClick={handleBackToUser} text={"Back"} />
                      <Button type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn ">
                        Next
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {loader && <Loader />}
    </div>
  );
};
export default ConnectorDetails;
