import { handleRequestError } from "../../../../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../../../../services/storage/Storage";
import { createTableObject } from "../../adduser/usertable/CustomTableUtils";

export const getUsersList = async (
  currentPage: number,
  pageSize: number | undefined,
  searchFilter?: string,
  syncButton?: boolean,
  setLoader?: any,
  realmId?: any,
  userManagement?: any,
  getUserList?: any,
  forSearchList?: boolean,
  reformedList?: any,
  setTotalRecords?: any,
  setUserList?: any,
  fromAccessManager?: boolean,
  setRecordsForEdit?: any,
) => {
  if (syncButton) {
    setLoader(false);
  } else {
    setLoader(true);
  }

  const payload = {
    realmId: realmId,
    sorting: "name_asc",
    statusFilter: "active,inactive",
    searchFilter: searchFilter ? searchFilter.toLowerCase() : "",
    startValue: currentPage,
    limitValue: !forSearchList ? pageSize : retrieveData("totalUserRecords", true),
  };
  try {
    const response: any = await userManagement(payload);
    const status = response.status;

    if (status === 200) {
      const responseData = response?.data;

      if (forSearchList) {
        const reformedListNew = responseData?.data?.map((item: any) => ({
          value: item?.userData?.userId,
          name: item?.userData?.name,
          email: item?.userData?.email,
          key: item?.userData?.userId,
          active: item?.userData?.status,
        }));

        const reformedListKeys = reformedList?.map((item: any) => item.key);
        const filteredReformedListNew = reformedListNew?.filter((item: any) => item.active === "active" || item.active === "inactive");
        getUserList(filteredReformedListNew);
        if (fromAccessManager) {
          const accessManagerUsers = reformedListNew?.filter((item: any) => reformedListKeys?.includes(item.key));
          setUserList(accessManagerUsers);
          const filteredArray = filteredReformedListNew.filter((item1: any) => !accessManagerUsers.some((item2: any) => item1.value === item2.value));
          getUserList(filteredArray);
          setRecordsForEdit(accessManagerUsers);
        }
        setLoader(false);
      } else {
        const storeTableData: any = createTableObject(responseData?.data);
        getUserList(storeTableData);
        setTotalRecords(response?.data?.meta?.totalRecords);
        setUserList(responseData);
        setLoader(false);
      }
    }
  } catch (err: any) {
    setLoader(false);
    handleRequestError(err);
  }
};

export const getListOfApps = async (realmId: any, setLoader: any, getAppList: any) => {
  const queryParams: object = {
    realmId: realmId,
  };
  setLoader(true);

  try {
    const response: any = await getAppList("", queryParams);
    const status = response.status;

    if (status === 200) {
      const data = response?.data?.data;
      const reformedOption = data
        .filter((item: any) => item?.akkuClientDto)
        .map((item: any) => ({
          label: item?.akkuClientDto?.client?.name,
          value: item?.akkuClientDto?.akkuClientId,
          logoUrl: item?.akkuMasterClientDto?.logoUrl,
          description: item?.akkuMasterClientDto?.description,
          checked: true,
        }));
      setLoader(false);

      return reformedOption;
    }
  } catch (err: any) {
    setLoader(false);
  }
};
