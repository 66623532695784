import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import TestingModal from "../modal/TestingModal";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveStep,
  setAuthentication,
  setConnectorId,
  setIsBackButtonPressed,
  setConnectorDetailDirty,
  setProvisioningButtonStatus,
  setIsConnectorApiCalled,
  setAuthenticationEdited,
} from "../../../../../redux/slice/provisioning/ProvisioningSlice";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../../../services/storage/Storage";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import { connectionAuthPayload, connectionAuthSchema } from "../helper/connectorHelper";
import { RenderAuthFields } from "./RenderAuthFields";
import { RootState } from "./AuthenticationTypes";
import { Button } from "antd";

const ConnectionAuthentication = () => {
  const dispatch = useDispatch();
  const appDetails = useSelector((state: RootState) => state?.AppDetailsSlice?.appDetails);
  const connectorDetailsAuthType = useSelector((state: RootState) => state?.provisioning?.generalDetails?.authenticationType);
  const provisioning = useSelector((state: RootState) => state?.provisioning);
  const generalDetails = useSelector((state: RootState) => state?.provisioning?.generalDetails);
  const authentication = useSelector((state: RootState) => state?.provisioning?.authentication);
  const isTestAuthentication = useSelector((state: RootState) => state?.provisioning?.authenticationTestButtonClicked);
  const akkuProvisioningConnectorId = useSelector((state: RootState) => state.provisioning?.akkuProvisioningConnectorId);
  const buttonStatus = useSelector((state: any) => state?.provisioning?.duplicateProvisioningButtons);
  const provisioningConnectorCreate = useGetApiRequests("provisioningConnector", "POST");
  const provisioningConnectorUpdate = useGetApiRequests("provisioningConnectorUpdate", "PUT");
  const realmId = retrieveData("realmId", true);

  const [enableTest, setEnableTest] = useState<boolean>(false);
  const [isActiveNext, setIsActiveNext] = useState<boolean>(false);
  const [testModal, setTestModal] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [rotate, setRotate] = useState<boolean>(false);
  const [authenticationDirty, setAuthenticationDirty] = useState<boolean>(false);

  const handleSetIsActiveNext = (provisioning: any, setIsActiveNext: (value: boolean) => void) => {
    if (provisioning?.isAuthenticationEdited && provisioning?.isBackButtonEnabled) {
      setIsActiveNext(false); // Disable "Next" button
    } else {
      setIsActiveNext(true); // Enable "Next" button
    }
  };

  useEffect(() => {
    // Active edit, no dirty details
    if (provisioning.isActiveEdit && !provisioning?.connectorDetailDirty) {
      // Check if this is a duplicate
      if (provisioning?.isDuplicate) {
        // API called and details not dirty
        if (provisioning?.isConnectorApiCalled && !provisioning?.connectorDetailDirty) {
          // IF Authentication edited and back button enabled
          handleSetIsActiveNext(provisioning, setIsActiveNext);
        } else {
          setIsActiveNext(false); // Disable "Next" button
        }
        // Authentication edited, back button enabled
      } else if (provisioning?.isAuthenticationEdited && provisioning?.isBackButtonEnabled) {
        setIsActiveNext(false); // Disable "Next" button
      } else {
        setIsActiveNext(true); // Enable "Next" button
      }
      // Active edit and dirty details
    } else if (provisioning.isActiveEdit && provisioning?.connectorDetailDirty) {
      dispatch(setAuthenticationEdited(true)); // Mark as edited
      setIsActiveNext(false); // Disable "Next" button
      // Connector API called
    } else if (provisioning?.isConnectorApiCalled) {
      // Details are dirty
      if (provisioning?.connectorDetailDirty) {
        dispatch(setAuthenticationEdited(true)); // Mark as edited
        setIsActiveNext(false); // Disable "Next" button
      } else {
        // IF Authentication edited, back button enabled
        handleSetIsActiveNext(provisioning, setIsActiveNext);
      }
      // Not editing, not duplicate, and details dirty
    } else if (!provisioning.isActiveEdit && !provisioning?.isDuplicate && provisioning?.connectorDetailDirty) {
      dispatch(setAuthenticationEdited(true)); // Mark as edited
      setIsActiveNext(false); // Disable "Next" button
      // Back button enabled, details dirty
    } else if (provisioning?.isBackButtonEnabled && provisioning?.connectorDetailDirty) {
      setIsActiveNext(false); // Disable "Next" button
    } else {
      // IF Authentication edited, back button enabled
      handleSetIsActiveNext(provisioning, setIsActiveNext);
    }
  }, [provisioning?.connectorDetailDirty]);

  // Check If this is a duplicate
  useEffect(() => {
    if (buttonStatus?.connectorAuthentication && !provisioning?.isConnectorApiCalled) {
      setEnableTest(true);
    }
  }, [buttonStatus?.connectorAuthentication]);

  const handleSubmit = async (values: any) => {
    if (isActiveNext && !authenticationDirty) {
      handleToNext();
    } else {
      handleTestAuthentication(values);
    }
  };

  const handleTestAuthentication = async (values: any) => {
    const commonPayload = {
      tokenApiEndpoint: "",
      userName: "",
      password: "",
      grantType: "",
      clientId: "",
      secret: "",
      tokenApiUrl: "",
      userNameEmail: "",
      apiToken: "",
    };
    setLoader(true);
    if (connectorDetailsAuthType === "oidc") {
      const storePayload = {
        ...commonPayload,
        tokenApiEndpoint: values.tokenApiEndpoint,
        userName: values.userName,
        password: values.password,
        grantType: values.grantType,
        clientId: values.clientId,
        secret: values.secret,
      };
      dispatch(setAuthentication(storePayload));
    } else if (connectorDetailsAuthType === "servicetoken") {
      const storePayload = {
        ...commonPayload,
        tokenApiUrl: values.tokenApiUrl,
        tokenApiEndpoint: values.tokenApiEndpoint,
        userNameEmail: values.userNameEmail,
      };
      dispatch(setAuthentication(storePayload));
    } else {
      const storePayload = {
        ...commonPayload,
        apiToken: values.apiToken,
        userNameEmail: values.userNameEmail,
      };
      dispatch(setAuthentication(storePayload));
    }
    createPayloadConnector(values);
  };

  const createPayloadConnector = (values: any) => {
    const payload = connectionAuthPayload(values, generalDetails, realmId, appDetails, connectorDetailsAuthType);
    provisioningConnectorApiCall(payload);
  };

  const handleBackToUser = () => {
    dispatch(setIsBackButtonPressed(true));
    dispatch(setActiveStep(0));
  };

  const handleToNext = () => {
    setEnableTest(false);
    if (provisioning?.isDuplicate) {
      dispatch(setProvisioningButtonStatus({ ...buttonStatus, connectorProvisioning: true }));
    }
    dispatch(setAuthenticationEdited(false));
    dispatch(setActiveStep(2));
  };

  const handleApiResponse = (response: any) => {
    if (response?.status === 200) {
      setIsActiveNext(true);
      dispatch(setAuthenticationEdited(false));
      dispatch(setIsConnectorApiCalled(true));
      dispatch(setConnectorDetailDirty(false));
      dispatch(setProvisioningButtonStatus({ ...buttonStatus, connectorDetails: false }));
      if (response?.data?.data?.akkuProvisioningConnectorId && response?.data?.data?.akkuProvisioningConnectorId !== "") {
        dispatch(setConnectorId(response?.data?.data?.akkuProvisioningConnectorId)); //setting connector id while saving the app for first time
      }
      if (provisioning?.isDuplicate) {
        dispatch(setProvisioningButtonStatus({ ...buttonStatus, connectorAuthentication: false }));
      }
      setEnableTest(false);
      setTestModal(true);
      setLoader(false);
      setTimeout(() => setRotate(false), 1000);
    }
  };

  const handleApiError = (err: any) => {
    setIsActiveNext(false);
    setTestModal(false);
    setRotate(false);
    setEnableTest(true);
    handleRequestError(err);
    setLoader(false);
  };

  const provisioningConnectorApiCall = async (payload: any) => {
    setRotate(true);
    try {
      let response: any;
      const param = {
        akkuProvisioningConnectorId: akkuProvisioningConnectorId ?? provisioning.akkuProvisioningConnectorId,
      };
      // Check if edit mode or authentication is dirty
      if (provisioning.isActiveEdit || (authenticationDirty && isTestAuthentication)) {
        // If the connector's authentication button is active and the connector API hasn't been called yet - "create"
        if (buttonStatus.connectorAuthentication && !provisioning?.isConnectorApiCalled) {
          payload.akkuMasterClientId = appDetails?.akkuMasterClientId;
          response = await provisioningConnectorCreate(payload);
          dispatch(setConnectorId(response?.data?.data?.akkuProvisioningConnectorId));
        } else {
          // if not met - "update"
          payload.isActive = provisioning.isActive;
          response = await provisioningConnectorUpdate(payload, "", param);
        }
      } else {
        // If back button is enabled and connector details are modified, and API was already called - "update"
        if (provisioning.isBackButtonEnabled && provisioning.connectorDetailDirty && provisioning?.isConnectorApiCalled) {
          payload.isActive = provisioning.isActive;
          response = await provisioningConnectorUpdate(payload, "", param);
        } else {
          // If none of the conditions are met - "create"
          payload.akkuMasterClientId = appDetails?.akkuMasterClientId;
          response = await provisioningConnectorCreate(payload);
          dispatch(setConnectorId(response?.data?.data?.akkuProvisioningConnectorId));
        }
      }
      handleApiResponse(response);
    } catch (err: any) {
      handleApiError(err);
    }
  };

  return (
    <div className="w-full px-10 pb-20 ">
      <p className="app-header pt-8">
        Connector Details - Authentication {provisioning?.isDuplicate ? "(Copy)" : null}
        {connectorDetailsAuthType !== "servicetoken" ? <span>(All fields are mandatory)</span> : null}
      </p>
      <div className="w-full mx-auto">
        {/* <pre>{JSON.stringify(formInitialValues, null, 1)}</pre> */}
        <Formik initialValues={authentication} validationSchema={connectionAuthSchema(connectorDetailsAuthType)} onSubmit={handleSubmit} enableReinitialize>
          {({ dirty, errors, values, isValid }) => {
            setAuthenticationDirty(dirty);
            return (
              <Form>
                <div className="w-full pt-10  app-connector auth flex-wrap ">
                  {RenderAuthFields(errors, connectorDetailsAuthType)}
                  <div className="footer " data-testid="tokenApiEndpoint-input">
                    <div className=" w-full mx-auto modal-footer" data-testid="userName-input">
                      <div className="pr-5 w-full flex justify-end " data-testid="password-input">
                        <CustomButtonBack text={"Back"} onClick={() => handleBackToUser()} />

                        {isActiveNext ? (
                          <Button htmlType="submit" type="primary" className="bg-[#5441DA] submit-btn">
                            {dirty ? "Test" : "Next"}
                          </Button>
                        ) : (
                          <Button loading={loader} type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn">
                            Test
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {testModal && <TestingModal enableTest={enableTest} testModal={testModal} setTestModal={setTestModal} rotate={rotate} />}
    </div>
  );
};

export default ConnectionAuthentication;
