import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import { useDispatch, useSelector } from "react-redux";
import FinalReviewUserDataAccordionAttributes from "./FinalReviewUserDataAccordion/FinalReviewUserDataAccordionAttributes";
import FinalReviewUserDataAccordionGroups from "./FinalReviewUserDataAccordion/FinalReviewUserDataAccordionGroups";
import FinalReviewUserDataAccordionRoles from "./FinalReviewUserDataAccordion/FinalReviewUserDataAccordionRoles";
import "./FinalReview.scss";
import { Button, Collapse } from "antd";
import { setActiveStep } from "../../../../../redux/slice/provisioning/ProvisioningSlice";
import { CollapseProps } from "antd/lib";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import SuccessMessageModal from "../../../../access-manager/create/IpTimeSuccessModal";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import Loader from "../../../../../layouts/component/Loader";
const FinalReviewUserData = (props: any) => {
  const provisioningMappingAttributesUpdate = useGetApiRequests("provisioningMappingAttributesUpdate", "PUT");
  const mappingAttributesPayloadCreation = useSelector((state: any) => state?.provisioning.mappingAttributesPayloadCreation);

  const [activeKey, setActiveKey] = useState<string>("1");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const provisioning = useSelector((state: any) => state?.provisioning);
  const [loader, setLoader] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [apiHitCount, setApiHitCount] = useState<number>(0);
  const [noOfApiCalls, setNoOfApiCalls] = useState();
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  useEffect(() => {
    if (apiHitCount === noOfApiCalls) {
      setOpenSuccessModal(true);
      setTimeout(() => {
        handleCloseSuccessModal();
        navigate("/app-store");
      }, 3000);
    }
  }, [apiHitCount]);

  const handleBack = () => {
    dispatch(setActiveStep(5));
  };

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const handleCollapseChange = (key: any) => {
    setActiveKey(key);
  };
  const handleNavigate = () => {
    navigate("/app-store");
  };
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: <p className="text-[#000] font-Inter text-[20px] font-normal">Attributes</p>,
      children: <FinalReviewUserDataAccordionAttributes />,
    },
  ];

  const handleAddConnection = () => {
    const transformPayload = mappingAttributesPayloadCreation;
    setNoOfApiCalls(transformPayload?.length);
    transformPayload?.map((transformPayload: any) => {
      updateProvisionAttributes(transformPayload);
    });
  };

  const updateProvisionAttributes = async (transformPayload: any) => {
    setLoader(true);
    const params = {
      akkuProvisioningConnectorEndpointConfigId: `${transformPayload?.akkuProvisioningConnectorEndpointConfigId}/attributes`,
    };
    const payload = { attributesMap: transformPayload?.attributesMap };
    try {
      const response: any = await provisioningMappingAttributesUpdate(payload, "", params);
      const status = response?.status;
      if (status === 200) {
        const message = response?.data?.message;
        setResponseMessage(message);
        setLoader(false);
        setApiHitCount((prevCount) => prevCount + 1);
      }

      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  return (
    <>
      <div className="w-full final-review-user-data p-7 bg-[#ffffff]">
        <ul className="flex breadcrumb">
          <li role="none" className="font-Inter  pr-1 cursor-pointer !text-[14px]" onClick={handleNavigate}>
            App Management {"/ "}
          </li>
          <li className="font-Inter !text-[14px]">Provisioning {"/ "}</li>
          <li className="font-Inter !text-[14px]">Mapping {"/ "}</li>
          <li className="active font-Inter !text-[14px]">Final Review</li>
        </ul>

        <div className="w-full pb-5">
          <p className="app-header pt-5">Final Review - User Data {provisioning?.isDuplicate ? "(Copy)" : null}</p>
          <p className="text-[#475467] text-[20px] font-Inter ">Please review the mapping below and confirm changes if any</p>
        </div>

        <div className=" overflow-y-auto mx-auto pt-3 pb-60 xl:pb-20 w-[100%] flex flex-col gap-2 overflow-x-auto final-review-user-data-accordions-wrappr">
          <div className="w-full final-review-user-data-wrapper pb-60 xl:pb-20">
            <Collapse accordion activeKey={activeKey} onChange={handleCollapseChange} items={items} className="w-[95%] custom-header hai bg-[#E3E3E3]" expandIconPosition="end" />
          </div>
          {/* <div className="w-full">
          <FinalReviewUserDataAccordionGroups />
        </div>
        <div className="w-full">
          <FinalReviewUserDataAccordionRoles />
        </div> */}
        </div>
        <div className="footer">
          <div className="modal-footer mx-auto w-full pr-5">
            <div className="w-full justify-end flex ">
              <CustomButtonBack onClick={handleBack} text={"Back"} />
              <Button type="primary" onClick={handleAddConnection} className="bg-[#5441DA]  submit-btn">
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </div>
      {loader && <Loader />}
      {openSuccessModal && <SuccessMessageModal open={openSuccessModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
    </>
  );
};

export default FinalReviewUserData;
