import React, { useEffect, useState } from "react";
import { Button, Checkbox, Modal } from "antd";
import ImageView from "../../../../../../layouts/component/ImageView";
import CustomButtonBack from "../../../../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../../../../layouts/component/CustomButton";
import { images } from "../../../../../../assets/images/ImagePaths";
import SearchAppsModalEdit from "../modal/SearchAppsModalEdit";
import "./ConnectedApps.scss";
const ConnectedApplicationEdit = ({ setListOfApps, listOfApps, name = "Connected Apps", styleName = "", individualApps, tempListApps }: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [appList, setAppList] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [disconnectApp, setDisconnectApp] = useState<any>(null);
  const [tempSelectedApps, setTempSelectedApps] = useState<any>([]);
  useEffect(() => {
    getListOfAllApps();
  }, [individualApps]);
  const getListOfAllApps = async () => {
    setAppList(individualApps);
  };

  const openConfirmationModal = (item: any) => {
    setDisconnectApp(item);
    setDeleteModal(true);
  };
  const handelonChange = () => {
    const appsToBeDelete = [...listOfApps];
    const filteredApps = appsToBeDelete.filter((app) => app.value !== disconnectApp);
    setListOfApps(filteredApps);
    const selectedValuesToRemoved = filteredApps.map((item: any) => item.value);
    const nonSelectedIds = tempListApps?.map((item: any) => item?.value);
    const uniqueValues = selectedValuesToRemoved.filter((value) => !nonSelectedIds.includes(value));
    setSelectedValues(uniqueValues);
    setTempSelectedApps(uniqueValues);
    handleCloseModal();
  };
  const handleAddModal = () => {
    setIsOpen(true);
  };
  const handelClose = () => {
    setIsOpen(false);
  };
  const handleCloseModal = () => {
    setDeleteModal(false);
  };
  return (
    <div className={`connected-apps pt-8 ${styleName}`}>
      <p className="text-[#171717] text-2xl font-Inter font-bold pt-2 pb-3 title">{name}</p>
      <div className="w-full flex">
        <div className="connected-apps pb-8 flex flex-wrap">
          {listOfApps &&
            listOfApps.length > 0 &&
            listOfApps.map((item: any) => (
              <div className="checkbox-container mr-3 mb-3" key={item?.value}>
                <div className="content w-full h-[125px] relative flex items-center justify-center">
                  <ImageView src={item?.logoUrl} alt="connected-apps" className="w-full object-contain bg-[#FAFAFC] rounded-lg p-2" />
                  <Checkbox
                    onChange={() => {
                      openConfirmationModal(item?.value);
                    }}
                    checked={item?.checked}
                    className="absolute left-2 top-[10px] w-4 h-4"
                  />
                </div>
              </div>
            ))}
          <Button className="upload" onClick={handleAddModal}>
            <span className="material-symbols-outlined text-[#5441DA]">add</span>
          </Button>
        </div>
      </div>

      {isOpen && (
        <SearchAppsModalEdit
          appList={appList}
          setAppList={setAppList}
          isOpen={isOpen}
          handelClose={handelClose}
          setListOfApps={setListOfApps}
          listOfApps={listOfApps}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          tempSelectedApps={tempSelectedApps}
          setTempSelectedApps={setTempSelectedApps}
          tempListApps={tempListApps}
        />
      )}
      {deleteModal && (
        <Modal className="p-10  show-notification  _suspended rounded-lg" maskClosable={false} centered open={deleteModal} footer={false} onCancel={handleCloseModal}>
          <div className="modal-body  pt-4 ">
            <div className="flex w-full justify-center items-center flex-col">
              <ImageView src={images.notificationSuspend} alt="notificationDone" className="w-20 h-20" />
              <h5 className="text-[24px] font-Inter pt-3 font-semibold"> Disconnect App</h5>

              <p className="text-[20px] pt-4 text-center font-Inter font-medium text-[#475467]">Are you sure you want to disconnect this app from this group ?</p>
            </div>
          </div>
          <div className="modal-footer pt-8">
            <div className="w-full flex justify-center">
              <CustomButtonBack onClick={() => handleCloseModal()} text={"Cancel"} />
              <CustomButtonNext onClick={handelonChange} text={"Yes"} />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ConnectedApplicationEdit;
