import { Button, Table, Tooltip, Switch, TableColumnsType } from "antd";
import React from "react";
const AccessManagerContainerTable = (props: any) => {
  const { accessManagerList, accessManagerCreate, getPermissionStyle, handleClickRestrictionName, onChange, handleDeleteRestrictionPopup, openCreateAccessManager } = props.props;
  const handleTypes = (e: string) => {
    if (e === "a") {
      return "IP Restriction";
    } else if (e === "b") {
      return "Device Restriction";
    } else if (e === "c") {
      return "Time Restriction";
    }
  };
  const columns: TableColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) => {
        return (
          <div role="none" onClick={() => handleClickRestrictionName(record)} className="cursor-pointer">
            <div className="accessMangerTable-bg">
              <div className="accessMangerTable-name">
                <h2>{record.name}</h2>
                <p>{record.description}</p>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type: any, record: any) => (
        <div className="accessMangerTable-bg">
          <i className="accessMangerTable-icon">
            <Tooltip title={handleTypes(record.classification)} placement="top">
              <img src={type} alt={type} />
            </Tooltip>
          </i>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: boolean, record: any) => (
        <div className="accessMangerTable-bg">
          <span className="accessMangerTable-switch">
            <Switch
              disabled={record?.classification === "b"}
              checked={status}
              onChange={(status) => {
                onChange(status, record);
              }}
            />
          </span>
        </div>
      ),
    },
    {
      title: "Assigned",
      dataIndex: "assigned",
      key: "assigned",
      render: (assigned: string) => (
        <div className="accessMangerTable-bg">
          <span className="accessMangerTable-assigned">{assigned !== "" ? <span className="active">{assigned}</span> : "-"}</span>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text: any, record: any) => (
        <Button disabled={record?.classification === "b"} className="border-0 btn-disabled">
          <span
            role="none"
            className="material-icons-outlined"
            onClick={() => {
              handleDeleteRestrictionPopup(record);
            }}
          >
            delete
          </span>
        </Button>
      ),
    },
  ];
  return (
    <div className="accessManger mt-3">
      <div className="accessManger-contents" data-testid="accessManager-table">
        {accessManagerList?.length > 0 ? (
          <Table  columns={columns} dataSource={accessManagerList} pagination={false} />
        ) : (
          <div className="header-content no_data">
            <div>
              <img src="/no_data.png" alt="nodata" />
            </div>
            <div>
              <p>No rules defined for IP Restriction, Time Restriction, and Device Restriction.</p>
            </div>

            <button type="button" className={`c-new bg-[#5441DA] ${getPermissionStyle(accessManagerCreate)}`} onClick={openCreateAccessManager}>
              Create New
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccessManagerContainerTable;
