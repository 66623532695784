import { Modal, Tabs, TabsProps } from "antd";
import React, { useEffect, useState } from "react";
import Profile from "./Profile";
import ResetProfilePassword from "./ResetProfilePassword";

export default function HeaderModal({ handleModalClose, open, setProfileUpdate, profileUpdate, setFullPageLoader, userHavePassword, getDetails }: any) {
  const itemsTabs: TabsProps["items"] = [
    {
      key: "1",
      label: "Profile Details",
      children: <Profile handleModalClose={handleModalClose} setProfileUpdate={setProfileUpdate} profileUpdate={profileUpdate} />,
    },
    {
      key: "2",
      label: `${!userHavePassword ? "Change Password" : "Set Password"}`,
      children: <ResetProfilePassword handleModalClose={handleModalClose} open={open} setFullPageLoader={setFullPageLoader} userHavePassword={userHavePassword} getDetails={getDetails} />,
    },
  ];
  const [key, setKey] = useState(1);
  useEffect(() => {
    setKey(key + 1);
  }, [open]);

  return (
    <Modal className="p-5 rounded-lg profileEdit-modal" maskClosable={false} centered open={open} footer={false} onCancel={handleModalClose}>
      <div className="modal-body "></div>
      <div className="profileEdit" key={key}>
        <Tabs defaultActiveKey="1" items={itemsTabs} />
      </div>
    </Modal>
  );
}
