import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, FieldArray } from "formik";
import { Input, Select } from "antd";
import { useSelector } from "react-redux";
const { TextArea } = Input;

const UserDeprovision = (props: any) => {
  const { values, setFieldValue } = props;
  const [activeKey, setActiveKey] = useState<string>("");
  const [authType, setAuthType] = useState<string>("");
  const connectorDetailsAuthType = useSelector((state: any) => state?.deProvisioning?.generalDetails?.authenticationType);
  const handleCollapseChange = (key: any) => {
    setActiveKey(key);
  };
  useEffect(() => {}, [values]);
  const handleOnChange = (setFieldValue: any, value: any, field: any) => {
    setAuthType(value);
    setFieldValue(field, value);
  };

  return (
    <FieldArray name="userDeProvision">
      {({ insert, remove, push }) => (
        <div>
          {values?.userDeProvision?.length !== 0 &&
            values?.userDeProvision?.map((samlConfig: any, index: any) => (
              <div key={samlConfig} className=" w-[80%] justify-between flex-wrap flex">
                <div className="mb-10 input-container w-[45%] ">
                  <p className="font-medium text-[#1D1D24] font-Inter flex text-[18px] items-center ">API Endpoint Url*</p>
                  <Field as={Input} type="text" className="h-[56px]  text-[18px] font-medium" name={`userDeProvision.${index}.apiEndpointURL`} />
                  <ErrorMessage name={`userDeProvision.${index}.apiEndpointURL`} component="div" className=" custom-error error-message " />
                </div>
                <div className="mb-10   relative w-[45%] input-container">
                  <p className="text-[#1D1D24] font-Inter text-[18px]  font-medium">Method Type*</p>

                  <Field
                    name={`userDeProvision.${index}.methodType`}
                    type="text"
                    disabled
                    as={Select}
                    className="h-[56px] loginThemeText font-medium text-[18px] w-[100%] "
                    value={values.userDeProvision[index].methodType || undefined}
                    filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={(value: string) => setFieldValue(`userDeProvision.${index}.methodType`, value)}
                    placeholder="Select"
                  >
                    <Select.Option value="Delete">Delete</Select.Option>
                  </Field>
                  <ErrorMessage name={`userDeProvision.${index}.methodType`} component="div" className="error-message" />
                </div>
                {/* {connectorDetailsAuthType !== "oidc" ? (
                  <div className="input-container mb-8  relative w-[45%]">
                    <p className="font-Inter text-[#1D1D24] text-[18px]  font-medium">Auth Type*</p>
                    <Field
                      type="text"
                      as={Select}
                      className="h-[56px] loginThemeText font-medium text-[18px] w-[100%] "
                      name={`userDeProvision.${index}.authType`}
                      value={values.userDeProvision[index].authType || undefined}
                      filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onChange={(value: string) => handleOnChange(setFieldValue, value, `userDeProvision.${index}.authType`)}
                      placeholder="Select"
                    >
                      <Select.Option value="bearerToken">Bearer Token</Select.Option>
                      <Select.Option value="basicAuth">Basic Auth</Select.Option>
                      <Select.Option value="JWTBearer">JWT Bearer</Select.Option>
                      <Select.Option value="apiKey">API Key</Select.Option>
                    </Field>
                    <ErrorMessage name={`userDeProvision.${index}.authType`} component="div" className="error-message" />
                  </div>
                ) : null} */}

                {authType === "bearerToken" && (
                  <div className="w-full mb-8">
                    <div className="mb-0 w-[45%]">
                      <p className="text-[#1D1D24] font-Inter text-[18px]  flex items-center font-medium">Token*</p>
                      <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`userDeProvision.${index}.token`} />
                      <ErrorMessage name={`userDeProvision.${index}.token`} component="div" className="error-message custom-error" />
                    </div>
                  </div>
                )}
                {authType === "basicAuth" && (
                  <div className="w-full mb-8 flex justify-between">
                    <div className="mb-0 w-[45%]">
                      <p className="text-[#1D1D24]  items-center font-Inter flex text-[18px]  font-medium">Username*</p>
                      <Field as={Input} type="text" className="h-[56px]  text-[18px] font-medium" name={`userDeProvision.${index}.userName`} />
                      <ErrorMessage name={`userDeProvision.${index}.userName`} component="div" className="custom-error error-message " />
                    </div>
                    <div className="mb-0 w-[45%]">
                      <p className="text-[#1D1D24] text-[18px]  flex items-center font-Inter font-medium">Password*</p>
                      <Field as={Input} type="password" className="h-[56px]  text-[18px] font-medium" name={`userDeProvision.${index}.password`} />
                      <ErrorMessage name={`userDeProvision.${index}.password`} component="div" className="custom-error error-message " />
                    </div>
                  </div>
                )}
                {authType === "JWTBearer" && (
                  <div className="w-full  flex mb-8 justify-between">
                    <div className="mb-0 w-[45%]">
                      <p className="text-[#1D1D24] text-[18px]  flex items-center font-Inter font-medium">Algorithm*</p>
                      <Field as={Input} type="text" className="h-[56px]  text-[18px] font-medium" name={`userDeProvision.${index}.algorithm`} />
                      <ErrorMessage name={`userDeProvision.${index}.algorithm`} component="div" className="custom-error error-message " />
                    </div>
                    <div className="mb-0 w-[45%]">
                      <p className="text-[#1D1D24]  font-Inter flex text-[18px] items-center font-medium">Secret*</p>
                      <Field as={Input} type="text" className="h-[56px]  text-[18px] font-medium" name={`userDeProvision.${index}.secret`} />
                      <ErrorMessage name={`userDeProvision.${index}.secret`} component="div" className="error-message custom-error" />
                    </div>
                  </div>
                )}
                {authType === "JWTBearer" && (
                  <div className="mb-8 w-[45%] input-container">
                    <p className="text-[#1D1D24] font-Inter flex text-[18px] items-center font-medium"> Payload*</p>
                    <Field as={TextArea} type="text" className="min-h-[200px] font-medium text-[18px]" name={`userDeProvision.${index}.payload`} />
                    <ErrorMessage name={`userDeProvision.${index}.payload`} component="div" className="error-message custom-error" />
                  </div>
                )}
                {authType === "apiKey" && (
                  <div className="w-full mb-8 flex flex-wrap justify-between">
                    <div className="mb-0 w-[45%]">
                      <p className="text-[#1D1D24] font-Inter flex text-[18px] items-center font-medium">Key*</p>
                      <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`userDeProvision.${index}.apiKey`} />
                      <ErrorMessage name={`userDeProvision.${index}.apiKey`} component="div" className="error-message custom-error" />
                    </div>
                    <div className="mb-4 w-[45%]">
                      <p className="text-[#1D1D24] font-Inter flex text-[18px] items-center font-medium">Value*</p>
                      <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`userDeProvision.${index}.apiValue`} />
                      <ErrorMessage name={`userDeProvision.${index}.apiValue`} component="div" className="error-message custom-error" />
                    </div>

                    <div className="mb-4 w-[45%]">
                      <p className="text-[#1D1D24] font-Inter flex text-[18px] items-center font-medium">Add to *</p>
                      <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`userDeProvision.${index}.apiAddTo`} />
                      <ErrorMessage name={`userDeProvision.${index}.apiAddTo`} component="div" className="error-message custom-error" />
                    </div>
                  </div>
                )}
                <div className="mb-4 w-[45%] input-container">
                  <p className="text-[#1D1D24] font-Inter flex text-[18px] items-center font-medium">Request Payloads</p>
                  <Field as={TextArea} type="text" className="min-h-[200px] font-medium text-[18px]" name={`userDeProvision.${index}.requestPayload`} />
                </div>
                <div className="mb-0 w-[45%] input-container">
                  <p className="text-[#1D1D24] font-Inter flex text-[18px] items-center font-medium">Response</p>
                  <Field type="text" className="min-h-[200px] font-medium text-[18px]" name={`userDeProvision.${index}.response`} as={TextArea} />
                  <ErrorMessage name={`userDeProvision.${index}.response`} className="error-message custom-error" component="div" />
                </div>
                <div className="mb-10   relative w-[100%] input-container">
                  <p className="text-[#1D1D24] font-Inter text-[18px] font-medium">Header Parameters type*</p>

                  <Field
                    className="h-[56px] font-medium text-[18px] w-[100%] loginThemeText"
                    as={Select}
                    value={values.userDeProvision[index].headerParametersType || ""}
                    onChange={(value: string) => setFieldValue(`userDeProvision.${index}.headerParametersType`, value)}
                    filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    type="text"
                    name={`userDeProvision.${index}.headerParametersType`}
                    placeholder="Select"
                  >
                    <Select.Option value="true">Path</Select.Option>
                    <Select.Option value="false">Query</Select.Option>
                  </Field>
                  <ErrorMessage name={`userDeProvision.${index}.headerParametersType`} className="error-message custom-error" component="div" />
                </div>
                <FieldArray name={`userDeProvision.${index}.headerParam`}>
                  {({ push, remove }) => (
                    <div className="w-full">
                      {samlConfig?.headerParam?.length > 0 &&
                        samlConfig.headerParam.map((param: any, paramIndex: any) => (
                          <div key={param} className="w-full flex justify-between relative">
                            <div className=" relative mt-4  w-[45%]">
                              <p className={`${paramIndex === 0 ? "text-[#1D1D24] flex text-[18px]  font-medium items-center font-Inter " : " opacity-0"}`}>Header Parameters *</p>
                              <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`userDeProvision.${index}.headerParam.${paramIndex}.key`} placeholder="Key" />
                              <ErrorMessage name={`userDeProvision.${index}.headerParam.${paramIndex}.key`} component="div" className="error-message custom-error" />
                            </div>
                            <div className="w-[45%] relative mt-4 ">
                              <p className={`${paramIndex === 0 ? "text-[#1D1D24] font-Inter flex text-[18px] items-center  font-medium" : " opacity-0"}`}>&nbsp;</p>
                              <Field placeholder="Value" as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`userDeProvision.${index}.headerParam.${paramIndex}.value`} />
                              <ErrorMessage name={`userDeProvision.${index}.headerParam.${paramIndex}.value`} component="div" className="error-message custom-error" />
                            </div>
                            <div className="absolute provision-action ">
                              {paramIndex !== samlConfig.headerParam.length - 1 && (
                                <button type="button" disabled={samlConfig.headerParam.length === 1} onClick={() => remove(paramIndex)}>
                                  <span className="material-symbols-outlined">remove</span>
                                </button>
                              )}
                              {paramIndex === samlConfig.headerParam.length - 1 && (
                                <button
                                  type="button"
                                  onClick={() => {
                                    push({ key: "", value: "" });
                                  }}
                                >
                                  <span className="material-symbols-outlined">add</span>
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </FieldArray>
              </div>
            ))}
        </div>
      )}
    </FieldArray>
  );
};

export default UserDeprovision;
