import { Input } from "antd";
import { ErrorMessage, Field } from "formik";
const { TextArea } = Input;

interface RenderAuthFieldsProps {
  [key: string]: any;
}
export const RenderAuthFields: React.FC<RenderAuthFieldsProps> = (errors, connectorDetailsAuthType) => {
  switch (connectorDetailsAuthType) {
    case "oidc":
      return (
        <div className="connection-authentication w-full flex justify-between self-start  flex-wrap">
          <div className="mb-12 w-[45%] input-container relative">
            <p>Token API EndPoint Url*</p>
            <Field as={Input} type="text" className="form-type-box" name="tokenApiEndpoint" data-testid="tokenApiUrl-input" />
            <ErrorMessage name="tokenApiEndpoint" component="div" className="error-message" />
          </div>
          <div className="mb-12 w-[45%] input-container relative">
            <p>Username*</p>
            <Field as={Input} type="text" className="form-type-box" name="userName" data-testid="userName-input" />
            <ErrorMessage name="userName" component="div" className="error-message" />
          </div>
          <div className="mb-12 w-[45%] input-container relative">
            <p>Password*</p>
            <Field as={Input} type="text" className="form-type-box" name="password" data-testid="password-input" />
            <ErrorMessage name="password" component="div" className="error-message" />
          </div>
          <div className="mb-12 w-[45%] input-container relative">
            <p>Grant_type *</p>
            <Field as={Input} type="text" className="form-type-box" name="grantType" data-testid="grantType-input" />
            <ErrorMessage name="grantType" component="div" className="error-message" />
          </div>
          <div className="mb-12 w-[45%] input-container relative">
            <p>Client_id*</p>
            <Field as={Input} type="text" className="form-type-box" name="clientId" data-testid="clientId-input" />
            <ErrorMessage name="clientId" component="div" className="error-message" />
          </div>
          <div className="mb-12 w-[45%] input-container relative">
            <p>Client_secret *</p>
            <Field as={Input} type="text" className="form-type-box" name="secret" data-testid="clientSecret-input" />
            <ErrorMessage name="secret" component="div" className="error-message" />
          </div>
        </div>
      );
    case "servicetoken":
      return (
        <div className="connection-authentication w-full flex justify-between self-start  flex-wrap">
        
        <div className="mb-12 w-[45%] input-container relative">
          <p>Service Token *</p>
          <Field as={TextArea} type="text" className="form-type-box !min-h-[175px]" name="tokenApiUrl" data-testid="tokenApiUrl-input" />
          {errors.tokenApiUrl && <div className="error-message">{errors.tokenApiUrl}</div>}
        </div>
          <div className=" w-[45%] input-container relative">
            <div className="mb-10 w-[100%] input-container relative authenticationEndpoint">
            <p>Authentication Endpoint</p>
            <Field as={Input} type="text" className="form-type-box" name="tokenApiEndpoint" data-testid="tokenApiUrl-input" />
            <ErrorMessage name="tokenApiEndpoint" component="div" className="error-message" />
          </div>
          <div className="mb-12 w-[100%] input-container relative">
            <p>Administrator Username / Email</p>
            <Field as={Input} type="text" className="form-type-box" name="userNameEmail" data-testid="userNameEmail-input" />
            <ErrorMessage name="userNameEmail" component="div" className="error-message" />
          </div>
          </div>

      </div>
      );
    default:
      return (
        <div className="connection-authentication w-full flex justify-between self-start  flex-wrap">
          <div className="mb-12 w-[45%] input-container relative">
            <p>API Token *</p>
            <Field as={TextArea} type="text" className="form-type-box" name="apiToken" data-testid="apiToken-input" />
            <ErrorMessage name="apiToken" component="div" className="error-message" />
          </div>
          <div className="mb-12 w-[45%] input-container relative">
            <p>Username / Email *</p>
            <Field as={Input} type="text" className="form-type-box" name="userNameEmail" data-testid="userNameEmail-input" />
            <ErrorMessage name="userNameEmail" component="div" className="error-message" />
          </div>
        </div>
      );
  }
};
