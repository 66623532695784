import React from "react";
import { useNavigate } from "react-router-dom";

export const DeviceManagementCSVUploadHeader = () => {
  const navigate = useNavigate();

  const getSampleDeiveCSVUrl: any = process.env.REACT_APP_SAMPLE_DEVICE_IMPORT;

  const handleDownload = () => {
    window.location.href = getSampleDeiveCSVUrl;
  };
  const redirectToUser = () => {
    navigate("/device-management");
  };
  return (
    <div className="flex justify-between items-center">
      <div className="csv-upload-header">
        <h2 className="flex items-center csv-upload-header-title">
          <button onClick={redirectToUser} className="material-icons-outlined  text-[#1C1B1F] cursor-pointer arrow_upward" data-testid="buttonId">
            arrow_upward
          </button>
          <span className="ml-3">CSV Upload</span>
        </h2>
        <p className="mt-2 pl-9">Effortlessly add multiple users via data list uploads</p>
      </div>
      <button className="flex items-center sample-download" onClick={handleDownload}>
        <span className="material-symbols-outlined">download</span>
        <p className="upload-label">Sample CSV File</p>
      </button>
    </div>
  );
};
