export const checkFilePathsAvailableOrNot = async (getFilepaths: any, setLoader: any, customerId: any) => {
  const queryParams: object = { realmName: customerId };
  setLoader(true);
  try {
    const response: any = await getFilepaths("", queryParams);
    const status = response.status;
    if (status === 200) return false;
  } catch (err: any) {
    if (err?.response?.status === 404) return true;
  }
};

export const plansDetails = [
  {
    planType: "Basic",
    include: "include",
    products: ["Cloud Directory", "SSO/IDP", "Adaptive MFA"],
  },
  {
    planType: "Professional",
    include: "include",
    products: ["Cloud Directory", "SSO/IDP", "Adaptive MFA", "Adaptive Access Manager"],
  },
  {
    planType: "Advanced",
    include: "include",
    products: ["Cloud Directory", "SSO/IDP", "Adaptive MFA", "Adaptive Access Manager", "Password Manager", "Device Manager"],
  },
];
