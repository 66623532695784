import React, { useEffect, useState } from "react";
import { images } from "../../../../../../../assets/images/ImagePaths";
import { useSelector } from "react-redux";
import { useAddUserContext } from "../../../add-user-context/AddUserContext";
import Loader from "../../../../../../../layouts/component/Loader";
import "./AddUserStepper.scss";
export default function AddUserStepper() {
  const stepper = useSelector((state: any) => state?.addUserSlice?.steeper);
  const { addUserType } = useAddUserContext();
  const [steppers, setStepper] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    if (addUserType === "singleUser") {
      setStepper(true);
    } else {
      setStepper(false);
    }
    setLoader(true);

    setTimeout(() => {
      setLoader(false);
    }, 300);
  }, [addUserType]);
  const getStepperStatus = (index: any) => {
    return index === 1 || index === 0 ? "hidden kerberos" : "";
  };

  return (
    <>
      {loader && <Loader />}
      <div className={steppers ? "hidden" : "addUser"}>
        <div className="userHeader flex justify-center flex-wrap">
          <img src={images.addUserIcons} alt="add user" />
          <h1 className="w-full text-center">Add Users</h1>
        </div>
        <ul className="addUser-stepper parent">
          {stepper.map((step: any, index: number) => (
            <li className={`parent-item ${step?.active ? "active" : ""} ${getStepperStatus(index)}`} key={step.title}>
              <header className="flex">{step.title} </header>
              <i className={`icon material-symbols-outlined ${step.status}`}>{step.icon} </i>
              {step.children && step.children.length > 0 && (
                <ul className="child">
                  {step.children.map((childStep: any, childIndex: number) => (
                    <li className={`child-item ${childStep?.active ? "active" : ""}`} key={childStep.title}>
                      <h6>{childStep.title}</h6>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
