import { Modal } from "antd";
import React from "react";
import ImageView from "../../../../../layouts/component/ImageView";
import unauthorized from "../../../../../assets/images/icons/unauthorized.svg";
import CustomButtonNext from "../../../../../layouts/component/CustomButton";

interface UnauthorizedModalProps {
  open: boolean;
  handleModalClose: () => void;
  message: string;
}

const ProvisionEditStatusModal: React.FC<UnauthorizedModalProps> = ({ open, handleModalClose, message }) => {

  return (
    <Modal className="p-10  provision-edit-access-modal rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleModalClose} closable={false}>
      <div className="modal-body  pt-4 ">
        <div className="flex w-full justify-center items-center flex-col">
          <div className="w-20 h-20">
            <ImageView alt="notificationDone" src={unauthorized} />
          </div>

          <p className="text-[24px] text-center font-Inter font-semibold text-[#101828]">{message}</p>
        </div>
      </div>

      <div className="modal-footer pt-8">
        <div className="w-full flex justify-center">
          <CustomButtonNext onClick={handleModalClose} text={"Ok"} />
        </div>
      </div>
    </Modal>
  );
};

export default ProvisionEditStatusModal;
