import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, FieldArray } from "formik";
import { Input, Select } from "antd";
import { useSelector } from "react-redux";
const { TextArea } = Input;

const UserProvision = (props: any) => {
  const { values, setFieldValue } = props;
  const [activeKey, setActiveKey] = useState<string>("");
  const [authType, setAuthType] = useState<string>("");
  const provisioning = useSelector((state: any) => state?.provisioning);
  const provisionDetails = useSelector((state: any) => state?.provisioning?.provisioning);
  const connectorDetailsAuthType = useSelector((state: any) => state?.provisioning?.generalDetails?.authenticationType);
  const handleCollapseChange = (key: any) => {
    setActiveKey(key);
  };
  useEffect(() => {}, [values]);
  const handleOnChange = (setFieldValue: any, value: any, field: any) => {
    setAuthType(value);
    setFieldValue(field, value);
  };

  const isKeyValueEmpty = (provisionDetails?.userProvision[0]?.headerParam as any[]).some((data: any) => data?.key === "");

  return (
    <FieldArray name="userProvision">
      {({ insert, remove, push }) => (
        <div>
          {values?.userProvision?.length !== 0 &&
            values?.userProvision?.map((samlConfig: any, index: any) => (
              <div key={samlConfig} className=" w-[80%] justify-between flex-wrap flex">
                <div className="mb-10 input-container w-[45%] ">
                  <p className="font-medium text-[#1D1D24] font-Inter flex text-[18px] items-center ">API Endpoint Url*</p>
                  <Field as={Input} type="text" className="h-[56px]  text-[18px] font-medium" name={`userProvision.${index}.apiEndpointURL`} />
                  <ErrorMessage name={`userProvision.${index}.apiEndpointURL`} component="div" className=" custom-error error-message " />
                </div>
                <div className="mb-10   relative w-[45%] input-container">
                  <p className="text-[#1D1D24] font-Inter text-[18px]  font-medium">Method Type*</p>

                  <Field
                    name={`userProvision.${index}.methodType`}
                    type="text"
                    as={Select}
                    className="h-[56px] loginThemeText font-medium text-[18px] w-[100%] "
                    value={values.userProvision[index].methodType || undefined}
                    filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={(value: string) => setFieldValue(`userProvision.${index}.methodType`, value)}
                    placeholder="Select"
                  >
                    <Select.Option value="Get">Get</Select.Option>
                    <Select.Option value="Post">Post</Select.Option>
                  </Field>
                  <ErrorMessage name={`userProvision.${index}.methodType`} component="div" className="error-message" />
                </div>
                {/* {connectorDetailsAuthType !== "oidc" ? (
                  <div className="input-container mb-8  relative w-[45%]">
                    <p className="font-Inter text-[#1D1D24] text-[18px]  font-medium">Auth Type*</p>
                    <Field
                      type="text"
                      as={Select}
                      className="h-[56px] loginThemeText font-medium text-[18px] w-[100%] "
                      name={`userProvision.${index}.authType`}
                      value={values.userProvision[index].authType || undefined}
                      filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onChange={(value: string) => handleOnChange(setFieldValue, value, `userProvision.${index}.authType`)}
                      placeholder="Select"
                    >
                      <Select.Option value="bearerToken">Bearer Token</Select.Option>
                      <Select.Option value="basicAuth">Basic Auth</Select.Option>
                      <Select.Option value="JWTBearer">JWT Bearer</Select.Option>
                      <Select.Option value="apiKey">API Key</Select.Option>
                    </Field>
                    <ErrorMessage name={`userProvision.${index}.authType`} component="div" className="error-message" />
                  </div>
                ) : null} */}

                {/* <div className="mb-10   relative w-[45%] input-container">
                  <p className="text-[#1D1D24] font-Inter text-[18px] font-medium">Content-Type*</p>

                  <Field
                    className="h-[56px] font-medium text-[18px] w-[100%] loginThemeText"
                    as={Select}
                    value={values.userProvision[index].contentType || undefined}
                    onChange={(value: string) => setFieldValue(`userProvision.${index}.contentType`, value)}
                    filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    type="text"
                    name={`userProvision.${index}.contentType`}
                    placeholder="Select"
                  >
                    <Select.Option value="exactIp">Exact IP</Select.Option>
                    <Select.Option value="range16">Range 16</Select.Option>
                    <Select.Option value="range24">Range 24</Select.Option>
                  </Field>
                  <ErrorMessage name={`userProvision.${index}.contentType`} component="div" className="error-message" />
                </div> */}
                {authType === "bearerToken" && (
                  <div className="w-full mb-8">
                    <div className="mb-0 w-[45%]">
                      <p className="text-[#1D1D24] font-Inter text-[18px]  flex items-center font-medium">Token*</p>
                      <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`userProvision.${index}.token`} />
                      <ErrorMessage name={`userProvision.${index}.token`} component="div" className="error-message custom-error" />
                    </div>
                  </div>
                )}
                {authType === "basicAuth" && (
                  <div className="w-full mb-8 flex justify-between">
                    <div className="mb-0 w-[45%]">
                      <p className="text-[#1D1D24]  items-center font-Inter flex text-[18px]  font-medium">Username*</p>
                      <Field as={Input} type="text" className="h-[56px]  text-[18px] font-medium" name={`userProvision.${index}.userName`} />
                      <ErrorMessage name={`userProvision.${index}.userName`} component="div" className="custom-error error-message " />
                    </div>
                    <div className="mb-0 w-[45%]">
                      <p className="text-[#1D1D24] text-[18px]  flex items-center font-Inter font-medium">Password*</p>
                      <Field as={Input} type="password" className="h-[56px]  text-[18px] font-medium" name={`userProvision.${index}.password`} />
                      <ErrorMessage name={`userProvision.${index}.password`} component="div" className="custom-error error-message " />
                    </div>
                  </div>
                )}
                {authType === "JWTBearer" && (
                  <div className="w-full  flex mb-8 justify-between">
                    <div className="mb-0 w-[45%]">
                      <p className="text-[#1D1D24] text-[18px]  flex items-center font-Inter font-medium">Algorithm*</p>
                      <Field as={Input} type="text" className="h-[56px]  text-[18px] font-medium" name={`userProvision.${index}.algorithm`} />
                      <ErrorMessage name={`userProvision.${index}.algorithm`} component="div" className="custom-error error-message " />
                    </div>
                    <div className="mb-0 w-[45%]">
                      <p className="text-[#1D1D24]  font-Inter flex text-[18px] items-center font-medium">Secret*</p>
                      <Field as={Input} type="text" className="h-[56px]  text-[18px] font-medium" name={`userProvision.${index}.secret`} />
                      <ErrorMessage name={`userProvision.${index}.secret`} component="div" className="error-message custom-error" />
                    </div>
                  </div>
                )}
                {authType === "JWTBearer" && (
                  <div className="mb-8 w-[45%] input-container">
                    <p className="text-[#1D1D24] font-Inter flex text-[18px] items-center font-medium"> Payload*</p>
                    <Field as={TextArea} type="text" className="min-h-[200px] font-medium text-[18px]" name={`userProvision.${index}.payload`} />
                    <ErrorMessage name={`userProvision.${index}.payload`} component="div" className="error-message custom-error" />
                  </div>
                )}
                {authType === "apiKey" && (
                  <div className="w-full mb-8 flex flex-wrap justify-between">
                    <div className="mb-0 w-[45%]">
                      <p className="text-[#1D1D24] font-Inter flex text-[18px] items-center font-medium">Key*</p>
                      <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`userProvision.${index}.apiKey`} />
                      <ErrorMessage name={`userProvision.${index}.apiKey`} component="div" className="error-message custom-error" />
                    </div>
                    <div className="mb-4 w-[45%]">
                      <p className="text-[#1D1D24] font-Inter flex text-[18px] items-center font-medium">Value*</p>
                      <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`userProvision.${index}.apiValue`} />
                      <ErrorMessage name={`userProvision.${index}.apiValue`} component="div" className="error-message custom-error" />
                    </div>

                    <div className="mb-4 w-[45%]">
                      <p className="text-[#1D1D24] font-Inter flex text-[18px] items-center font-medium">Add to *</p>
                      <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`userProvision.${index}.apiAddTo`} />
                      <ErrorMessage name={`userProvision.${index}.apiAddTo`} component="div" className="error-message custom-error" />
                    </div>
                  </div>
                )}
                <div className="mb-4 w-[45%] input-container">
                  <p className="text-[#1D1D24] font-Inter flex text-[18px] items-center font-medium">Request Payload*</p>
                  <Field as={TextArea} type="text" className="min-h-[200px] font-medium text-[18px]" name={`userProvision.${index}.requestPayload`} />
                  <ErrorMessage name={`userProvision.${index}.requestPayload`} component="div" className="error-message custom-error" />
                </div>
                <div className="mb-0 w-[45%] input-container">
                  <p className="text-[#1D1D24] font-Inter flex text-[18px] items-center font-medium">Response*</p>
                  <Field type="text" className="min-h-[200px] font-medium text-[18px]" name={`userProvision.${index}.response`} as={TextArea} />
                  <ErrorMessage name={`userProvision.${index}.response`} className="error-message custom-error" component="div" />
                </div>
                {(provisioning.isActiveEdit && provisioning.endPointResponseDtosLen !== 0 ? provisionDetails?.userProvision[0]?.headerParam && !isKeyValueEmpty : true) && (
                  <>
                    <div className="mb-10   relative w-[100%] input-container">
                      <p className="text-[#1D1D24] font-Inter text-[18px] font-medium">Header Parameters type</p>

                      <Field
                        className="h-[56px] font-medium text-[18px] w-[100%] loginThemeText"
                        as={Select}
                        value={values.userProvision[index].headerParametersType || ""}
                        onChange={(value: string) => setFieldValue(`userProvision.${index}.headerParametersType`, value)}
                        filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        type="text"
                        name={`userProvision.${index}.headerParametersType`}
                        placeholder="Select"
                      >
                        <Select.Option value="true">Path</Select.Option>
                        <Select.Option value="false">Query</Select.Option>
                      </Field>
                      <ErrorMessage name={`userProvision.${index}.headerParametersType`} className="error-message custom-error" component="div" />
                    </div>
                    <FieldArray name={`userProvision.${index}.headerParam`}>
                      {({ push, remove }) => (
                        <div className="w-full">
                          {samlConfig?.headerParam?.map((param: any, paramIndex: any) => (
                            <div key={param} className="w-full flex justify-between relative">
                              <div className=" relative mt-4  w-[45%]">
                                <p className={`${paramIndex === 0 ? "text-[#1D1D24] flex text-[18px]  font-medium items-center font-Inter " : " opacity-0"}`}>Header Parameters</p>
                                <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`userProvision.${index}.headerParam.${paramIndex}.key`} placeholder="Key" />
                                <ErrorMessage name={`userProvision.${index}.headerParam.${paramIndex}.key`} component="div" className="error-message custom-error" />
                              </div>
                              <div className="w-[45%] relative mt-4 ">
                                <p className={`${paramIndex === 0 ? "text-[#1D1D24] font-Inter flex text-[18px] items-center  font-medium" : " opacity-0"}`}>&nbsp;</p>
                                <Field placeholder="Value" as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`userProvision.${index}.headerParam.${paramIndex}.value`} />
                                <ErrorMessage name={`userProvision.${index}.headerParam.${paramIndex}.value`} component="div" className="error-message custom-error" />
                              </div>
                              <div className="absolute provision-action ">
                                {paramIndex !== samlConfig.headerParam.length - 1 && (
                                  <button type="button" disabled={samlConfig.headerParam.length === 1} onClick={() => remove(paramIndex)}>
                                    <span className="material-symbols-outlined">remove</span>
                                  </button>
                                )}
                                {paramIndex === samlConfig.headerParam.length - 1 && (
                                  <button
                                    type="button"
                                    onClick={() => {
                                      push({ key: "", value: "" });
                                    }}
                                  >
                                    <span className="material-symbols-outlined">add</span>
                                  </button>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </FieldArray>
                  </>
                )}
              </div>
            ))}
        </div>
      )}
    </FieldArray>
  );
};

export default UserProvision;
