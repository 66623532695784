import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import CardTitle from "../../component/CardTitle";
import batch from "../../../assets/images/icons/recover.png";
import { DatePicker, Select } from "antd";
import AttemptsIndividualScore from "./AttemptsIndividualScore";
import TotalAttemptScore from "./TotalAttemptScore";
import { useSelector } from "react-redux";
import { chartOptions } from "./chartOptions";
import { DropDownOption, RecoveryAttemptsRepresentation } from "./attemptsInterface";
import ImageView from "../../component/ImageView";
import { images } from "../../../assets/images/ImagePaths";
import "./Attempts.scss";
const RecoveryAttempts = () => {
  const [recoveryAttempts, setRecoveryAttempts] = useState<any>(null);
  const [series, setSeries] = useState<number[]>([0, 0]);

  const userDetails = useSelector((state: any) => state.dashboardDetails?.recoveryAttempts);

  useEffect(() => {
    const seriesSuccess = calculateSuccessRatePercentage(userDetails);
    const seriesFail = calculateFailureRatePercentage(userDetails);
    const storeSeriesValue = [seriesFail, seriesSuccess];
    setSeries(storeSeriesValue);
    setRecoveryAttempts(userDetails);
    setDropDownList([]);
  }, [userDetails]);

  const [dropDownList, setDropDownList] = useState<DropDownOption[]>([{ label: "Password", value: "password" }]);

  const calculateSuccessRatePercentage = (el: RecoveryAttemptsRepresentation) => {
    const successCount = el?.totalSuccessRecoveryAttemptsCount;
    const totalCount = el?.totalRecoveryAttemptsCount;
    const rate = Math.round((successCount / totalCount) * 100);
    return rate;
  };

  const calculateFailureRatePercentage = (el: RecoveryAttemptsRepresentation) => {
    const failureCount = el?.totalFailureRecoveryAttemptsCount;
    const totalCount = el?.totalRecoveryAttemptsCount;
    const rate = Math.round((failureCount / totalCount) * 100);
    return rate;
  };

  const onChange = (date: any, dateString: any) => {
    console.log(date, dateString);
  };
  return (
    <div className="recover-attempts">
      <div className="_card _card-modify rounded-lg w-full h-full min-h-[350px] max-h-[350px]">
        <div className="_card-header --no-border ">
          <div className="title">
            <CardTitle title="Recovery Attempts " src={batch} />
          </div>
          <div className=" hidden">
            <Select
              suffixIcon={<ImageView className="w-6 custom-chevron" src={images.chevronDown} alt="chevronDown" />}
              className="w-[150px] h-[48px] mr-3"
              onChange={(value) => {}}
              showSearch
              value={"Password"}
            >
              {dropDownList?.map((option: any) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}{" "}
                </Select.Option>
              ))}
            </Select>
            {/* <Button className="w-[56px] h-[48px] __common btn flex justify-center items-center mr-3" icon={ <span className="material-symbols-outlined text-[#667085]">download</span>} size={"large"}/> */}
            <div className="_custum-date-picker relative w-[200px] ">
              <DatePicker
                className="__common h-[48px] pl-10 __placeholder"
                placeholder="Today"
                onChange={(date, dateString) => {
                  onChange(date, dateString);
                }}
                suffixIcon={null}
              />
              <span className="material-symbols-outlined text-[#667085] absolute left-3 top-3 pr-3">calendar_today</span>
            </div>
          </div>
        </div>
        <div className="flex w-full pt-4">
          <div className="w-[50%] flex justify-center items-center">
            <div className="w-full flex items-center justify-center">
              <TotalAttemptScore attempt={recoveryAttempts?.totalRecoveryAttemptsCount} />
              <div className="w-1/2">
                <Chart options={chartOptions.options} series={series} type="pie" width="225" />
              </div>
            </div>
          </div>
          <AttemptsIndividualScore failureScore={recoveryAttempts?.totalFailureRecoveryAttemptsCount} successScore={recoveryAttempts?.totalSuccessRecoveryAttemptsCount} />
        </div>
      </div>
    </div>
  );
};

export default RecoveryAttempts;
