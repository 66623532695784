import { ConfigProvider, Layout } from "antd";
import { useEffect, useState } from "react";
import SideBar from "./layouts/siderbar/SideBar";
import Header from "./layouts/header/Header";
import AppRouter from "./routes/public/AppRouter";
import { themeConfig } from "./User/theme";
import Theme from "./theme/Theme";
import { useSelector } from "react-redux";
import dashboardRestriction from "./assets/images/background/dashboardRestriction.png";
import clientDashboardRestriction from "./assets/images/background/clientDashboardRestriction.png";
import { retrieveData } from "./services/storage/Storage";
import "./App.scss";

function App() {
  const role = retrieveData("role", false);
  const currentRole = retrieveData("currentRole", true);
  const restriction = useSelector((state: any) => state?.restriction?.restrictionDetails);

  const token = retrieveData("authToken", true);
  const [collapsed, setCollapse] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const isDarkMode: boolean = false;

  useEffect(() => {
    handleResize();
    if (currentRole === "user" && window.location.pathname !== "/dashboard" && token) {
      window.location.href = "/dashboard";
      setIsClient(false);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    const deviceWidth = window.innerWidth <= 1380;
    const isSidebar = document.getElementsByTagName("aside");
    setCollapse(deviceWidth);
    if (isSidebar.length !== 0) {
      setIsClient(true);
    }
  };

  return (
    <>
      {!token && <Theme />}
      <ConfigProvider theme={themeConfig}>
        <div className={`app-container ${isDarkMode ? "dark" : "light"}`}>
          {restriction.isActive && (
            <div className="">
              <img src={role === "admin" ? dashboardRestriction : clientDashboardRestriction} alt="dashboard Restriction" />
            </div>
          )}
          <Layout style={{ minHeight: "100vh" }}>
            {!restriction.isActive && <SideBar setCollapse={setCollapse} />}

            <div className={`${collapsed ? "expanded-width" : "collapsed-width"} ${isClient && currentRole !== "user" ? "" : "client-dashboard"} relative`}>
              <Header />
              <AppRouter />
            </div>
          </Layout>
        </div>
      </ConfigProvider>
    </>
  );
}

export default App;
