import React, { useEffect, useState } from "react";
import CustomButtonBack from "../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../layouts/component/CustomButton";
import { Modal } from "antd";
import ImageView from "../../../layouts/component/ImageView";
import { images } from "../../../assets/images/ImagePaths";
import Loader from "../../../layouts/component/Loader";
import { retrieveData } from "../../../services/storage/Storage";
import { useDispatch, useSelector } from "react-redux";
import { triggerNotification } from "../../../layouts/toast/ToastBar";
import { apiResponseMessage } from "../../../constant/apiResponseMessage";
import { setCredentialUpdate } from "../../../redux/slice/credential-management/credentialsSlice";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
import "./ConnectionModal.scss";
const DeleteConnection = (props: any) => {
  const { handleClose, isDelete, selectedRow, setSelectedRow, setSelectedRowKeys } = props;

  const [open, isOpen] = useState<boolean>(false);
  const baseURL = process.env.REACT_APP_BLOCKCHAIN_BASEURL;
  const token: string = retrieveData("authToken", true);
  const connectionState = useSelector((state: any) => state?.credentialsSlice);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteConnection = async () => {
    setLoader(true);
    const baseUrl = `${baseURL}v1/user/credential/revoke`;
    const query = selectedRow.map((id: any) => `userId=${encodeURIComponent(id)}`).join("&");
    const url = `${baseUrl}?${query}`;
    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        triggerNotification("error", "", apiResponseMessage?.tryAging, "topRight");
      }

      const data = await response.json();
      setSelectedRow([]);
      setSelectedRowKeys([]);
      triggerNotification("success", "", data?.message, "topRight");
      dispatch(setCredentialUpdate(!connectionState?.isUpdate));
      setLoader(false);
      handleClose();
    } catch (error) {
      setLoader(false);
      handleRequestError(error);
    }
  };
  useEffect(() => {
    isOpen(isDelete);
  }, [isDelete]);

  return (
    <Modal className="rounded-lg delete-credential" maskClosable={false} centered open={open} footer={false} onCancel={handleClose}>
      {loader && <Loader />}

      <div className="modal-body  justify-center  pt-4 px-12 ">
        <div className="w-full justify-center flex">
          <div className="w-20">
            <ImageView src={images.notificationDelete} />
          </div>
        </div>
        <div className="w-full ">
          <p className="text-center delete">Delete Connection</p>
          <p className="text-center confirm">Are you sure want to delete?</p>
        </div>
      </div>
      <div className="modal-footer pt-10">
        <div className="w-full flex justify-center">
          <CustomButtonBack onClick={handleClose} text={"No"} />
          <CustomButtonNext onClick={handleDeleteConnection} text={" Yes "} />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConnection;
