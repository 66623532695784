import React from "react";
import { images } from "../../../../../assets/images/ImagePaths";
import ImageView from "../../../../../layouts/component/ImageView";
import { Modal } from "antd";
import CustomButtonNext from "../../../../../layouts/component/CustomButton";
import "./TestingModal.scss";
import { useDispatch } from "react-redux";
import { setAuthenticationNextButtonClicked } from "../../../../../redux/slice/provisioning/ProvisioningSlice";

const TestingModal = (props: any) => {
  const { testModal, setTestModal, rotate } = props;
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(setAuthenticationNextButtonClicked(true));
    setTestModal(false);
  };
  return (
    <Modal className="p-16 show-notification  _suspended rounded-lg" closable={false} maskClosable={false} centered open={testModal} onCancel={handleCloseModal} footer={false}>
      <div className="modal-body   min-h-[150px]  items-center pt-4 flex">
        <div className=" flex-col flex w-full justify-center items-center ">
          <div className={`w-12 ${rotate ? "animate-rotation" : ""}`}>
            <ImageView src={images.sync} />
          </div>
          <p className="font-Inter   font-medium text-xl text-[#000]  pt-5">Testing In Progress...</p>
        </div>
      </div>
      {!rotate && (
        <div>
          <p className="text-center text-[#19A227]  text-xl font-medium pb-5 font-Inter ">Success</p>
          <div className="modal-footer">
            <div className=" flex w-full justify-center">
              <CustomButtonNext onClick={handleCloseModal} text={"Done"} />
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default TestingModal;
