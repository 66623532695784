import React, { useEffect, useState } from "react";
import ImageView from "../../../../../layouts/component/ImageView";
import { images } from "../../../../../assets/images/ImagePaths";
import SelectConnector from "../modal/SelectConnector";
import "./ManageConnection.scss";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedSourceConnector } from "../../../../../redux/slice/provisioning/ProvisioningSlice";
const ConnectionSourceAndTarget = (props: any) => {
  const { triggerError } = props;
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const generalDetails = useSelector((state: any) => state?.provisioning?.generalDetails);
  const provisioning = useSelector((state: any) => state?.provisioning);
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleSelectConnector = () => {
    setOpenModal(true);
  };
  useEffect(() => {
    if (provisioning?.isActiveEdit) {
      dispatch(setSelectedSourceConnector("Akku_Connector"));
    }
  }, [provisioning?.isActiveEdit]);
  return (
    <>
      <div className="flex  px-[30px] manage-connector  items-center">
        <div className="lg:w-[40%] w-[60%]">
          <p className="source">Select Source</p>
          <div className="browse-card">
            <div
              role="none"
              className="browse  h-full flex flex-col"
              onClick={() => {
                handleSelectConnector();
              }}
            >
              <div className="w-11">
                <ImageView src={images.link} />
              </div>

              <p className="text-center">{provisioning?.selectedSourceConnector !== "" ? provisioning?.selectedSourceConnector : "Browse from connectors"}</p>
              {provisioning?.selectedSourceConnector !== "" ? (
                <p className="query">The Akku connector provisions users from Akku to target apps, managing accounts, permissions, and data for seamless, secure access.</p>
              ) : null}
              {/* <p className="text-center">Browse from connectors</p> */}
            </div>

            <div className="selected-source hidden ">
              <p className="flex items-center  name">
                <div className="w-11 mr-3">
                  <ImageView src={images.link} />
                </div>
                Akku_Connector
              </p>
              <p className="query">query DetectEnginePerformanceProblem</p>
            </div>
          </div>
          {triggerError && <div className="error-message">Please select source connector</div>}
        </div>
        <div className="lg:w-[20%]  flex justify-center items-center  mt-11 lg:mt-0">
          <div className="w-20 self-center img-arrow">
            <ImageView src={images.rightArrow} />
          </div>
        </div>
        <div className="lg:w-[40%] w-[60%] mt-7 lg:mt-0">
          <p className="source">Select Target</p>
          <div className="browse-card">
            <div className="selected-source flex flex-col justify-center items-center">
              <p className="flex items-center  name capitalize">
                <div className="w-11 mr-3">
                  <ImageView src={images.link} />
                </div>
                {generalDetails?.name} Connector
              </p>
              <p className="query">{provisioning?.generalDetails?.description || "query DetectEngineProblems"}</p>
            </div>
          </div>
        </div>
      </div>

      {openModal && <SelectConnector openModal={openModal} handleClose={handleClose} />}
    </>
  );
};

export default ConnectionSourceAndTarget;
