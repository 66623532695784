import React, { useState } from "react";
import { Button, Input, Select } from "antd";
import { ErrorMessage, Field, useFormikContext } from "formik";
import { staticMessage } from "../../../../../../../../constant/StaticContent";

const SearchAndUpdateForm = (props: any) => {
  const { setFieldValue, setIsSubmitted, values, className, handleRedirectBack, isSubmitted, handleRedirectMapper } = props;
  const editModeList = [
    { label: "Read only", value: "READ_ONLY" },
    { label: "Writeable", value: "WRITABLE" },
    { label: "Unsynced", value: "UNSYNCED" },
  ];
  const [isTouched, setIsTouched] = useState(false);

  const { handleChange } = useFormikContext();

  const handleRedirectNext = () => {
    handleRedirectMapper();
  };

  const handleFieldChange = (e: any, field: any) => {
    setIsTouched(true);
    setIsSubmitted(false);
    handleChange(e);
  };

  const handleSelectChange = (field: string, value: any, setFieldValue: any) => {
    setFieldValue(field, value);
    setIsSubmitted(false);
  };
  return (
    <div className="w-full overflow-y-auto   search-update">
      <div className="mb-10 w-[92%] mx-auto pb-10">
        <div className="w-[90%] flex pt-[40px] flex-col edit-basic">
          <div className="w-full gap-5 flex mx-auto justify-between flex-wrap _edit ">
            <div className="mb-8 w-[45%] input-container relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{staticMessage?.ConfigureSearchUpdate?.editMode}</p>
              <Field
                as={Select}
                suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                className=" w-[100%] h-[56px]"
                name="editMode"
                value={values?.editMode}
                filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(value: any) => handleSelectChange("editMode", value, setFieldValue)}
              >
                {editModeList?.map((option: any) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Field>
              <ErrorMessage name="editMode" component="div" className="error-message" />
            </div>
            <div className="mb-12 w-[45%] input-container relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{staticMessage?.ConfigureSearchUpdate?.userDn}</p>
              <Field onChange={handleFieldChange} as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="usersDn" data-testid="usersDn-input" />
              <ErrorMessage name="usersDn" component="div" className="error-message" />
            </div>
            <div className="mb-12 w-[45%] input-container relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{staticMessage?.ConfigureSearchUpdate?.userLdap}</p>
              <Field onChange={handleFieldChange} as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="usernameLDAPAttribute" data-testid="usernameLDAPAttribute-input" />
              <ErrorMessage name="usernameLDAPAttribute" component="div" className="error-message" />
            </div>
            <div className="mb-12 w-[45%] input-container relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{staticMessage?.ConfigureSearchUpdate?.rdnLdap}</p>
              <Field onChange={handleFieldChange} as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="rdnLdapAttribute" data-testid="rdnLdapAttribute-input" />
              <ErrorMessage name="rdnLdapAttribute" component="div" className="error-message" />
            </div>
            <div className="mb-12 w-[45%] input-container relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{staticMessage?.ConfigureSearchUpdate?.uuidLdap}</p>
              <Field onChange={handleFieldChange} as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="uuidLdapAttribute" data-testid="uuidLdapAttribute-input" />
              <ErrorMessage name="uuidLdapAttribute" component="div" className="error-message" />
            </div>
            <div className="mb-12 w-[45%] input-container relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{staticMessage?.ConfigureSearchUpdate?.userObject}</p>
              <Field onChange={handleFieldChange} as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="userObjectClasses" data-testid="userObjectClasses-input" />
              <ErrorMessage name="userObjectClasses" component="div" className="error-message" />
            </div>
            <div className="mb-12 w-[45%] input-container relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Sync Update Interval(seconds)*</p>
              <Field onChange={handleFieldChange} as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="syncUpdateInterval" data-testid="syncUpdateInterval-input" />
              <ErrorMessage name="syncUpdateInterval" component="div" className="error-message" />
            </div>

            <div className="mb-12 w-[45%] input-container relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Certificate*</p>
              <Field onChange={handleFieldChange} as="textarea" type="text" className="h-[56px] w-full border resize-none outline-none rounded-md font-medium text-[18px]" name="certificate" />
              <ErrorMessage name="certificate" component="div" className="error-message" />
            </div>
          </div>
        </div>
      </div>
      <div className={`${className ? "edit-ldap-footer" : "w-full"} footer flex items-center absolute bottom-0 right-0  bg-[#fff] h-[95px]`}>
        <div className="modal-footer w-[95%] mx-auto ">
          <div className="w-full flex justify-end">
            {className ? (
              <>
                <Button onClick={handleRedirectBack} className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] mr-4 text-[#5441DA] ">
                  Back
                </Button>
                {(isSubmitted && isTouched) || (!isSubmitted && !isTouched) ? (
                  <Button type="primary" onClick={handleRedirectNext} className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white">
                    Next
                  </Button>
                ) : (
                  <Button type="primary" htmlType="submit" className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white">
                    Update
                  </Button>
                )}
              </>
            ) : (
              <Button type="primary" htmlType="submit" className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white">
                Next
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchAndUpdateForm;
