import React, { useEffect, useState } from "react";
import { Button, Input } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import useGetApiRequests from "../../services/axios/useApiRequests";
import { retrieveData } from "../../services/storage/Storage";
import { useSelector } from "react-redux";
import { handleRequestError } from "../toast/ErrorNotificationMessage";
import { createValidationSchema } from "../../Utils/DynamicSchemaForPassword";
import PasswordStrengthIndicator from "../../Utils/PasswordStrengthIndicator";
import Loader from "../component/Loader";

import { ErrorMessages } from "../../const/Messages";
import SetPasswordSuccessModal from "../../User/UserDashboard/password-modal/SetPasswordSuccessModal";

const oldPasswordSchema = Yup.object({
  oldPassword: Yup.string().required("Old Password is required"),
});

const ResetProfilePassword = (props: any) => {
  const { userHavePassword, handleModalClose, getDetails } = props;
  const isActiveClientDashboard = window.location.pathname === "/dashboard";
  const updatePasswordApiUrl = isActiveClientDashboard ? "updateClientPassword" : "setPassword";

  const keycloak = useSelector((state: any) => state?.keycloak);
  const userId: string = keycloak?.KeycloakInfo?.subject || "";

  const realmName = retrieveData("realmName", true);

  const setPasswordApi = useGetApiRequests(updatePasswordApiUrl, "PUT");
  const updatePassword = useGetApiRequests("updatePassword", "PUT");
  const getPasswordList = useGetApiRequests("getPasswordPolicy", "GET");
  const [validationSchema, setValidationSchema] = useState<any>();
  const [loader, setLoader] = useState(false);
  const [oldPassword, setOldPassword] = useState<boolean>(true);
  const [visiblePassword, setVisiblePassword] = useState<boolean>(true);
  const [responseErrorMessage, setResponseErrorMessage] = useState<any>(null);
  const [visibleConfirmPassword, setConfirmVisiblePassword] = useState<boolean>(true);
  const [isFormDirty, setFormDirty] = useState<boolean>(false);
  const [passwordPolicy, setPasswordPolicy] = useState<any>([]);

  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [responseSuccessMessage, setResponseSuccessMessage] = useState<string>("");
  const initialValues = {
    oldPassword: "",
    password: "",
    confirmPassword: "",
  };
  useEffect(() => {
    getPasswordPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPasswordPolicy = async () => {
    setLoader(true);
    const pathParams = {
      id: realmName,
    };
    try {
      const response: any = await getPasswordList("", {}, pathParams);
      const status = response?.status;
      if (status === 200) {
        setLoader(false);
        const data = response?.data?.data;
        let validationSchemaObject = createValidationSchema(data);
        setPasswordPolicy(data);
        if (!userHavePassword) {
          validationSchemaObject = validationSchemaObject.shape({
            oldPassword: oldPasswordSchema.fields.oldPassword,
          });
        }

        setValidationSchema(validationSchemaObject);
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const onSubmit = async (values: any, { resetForm }: any) => {
    if (userHavePassword) {
      handleSetPassword(values, resetForm);
    } else {
      handleChangePassword(values, resetForm);
    }
  };
  const handleChangePassword = async (values: any, resetForm: any) => {
    setLoader(true);
    const payload = {
      userEmailId: keycloak?.KeycloakUserDetails?.preferred_username,
      password: values.password,
      realmId: retrieveData("realmId", true),
      oldPassword: values.oldPassword,
    };

    try {
      const response: any = await setPasswordApi(payload, {}, {}, { "User-Id": userId });
      const status = response.status;
      if (status === 200) {
        setResponseSuccessMessage(response?.data?.message);
        resetForm();
        setLoader(true);
        setOpenSuccessModal(true);
      }
    } catch (err: any) {
      setLoader(false);
      if (err?.response?.data?.message === ErrorMessages?.inCorrectCurrentPassword) {
        const message = err?.response?.data?.message ? err?.response?.data?.message : err?.message;
        setResponseErrorMessage(`${message} (Three incorrect attempts will lock the account)`);
      } else {
        handleRequestError(err);
      }
    }
  };
  const handleSetPassword = async (values: any, resetForm: any) => {
    setLoader(true);
    const payload = {
      userEmailId: keycloak?.KeycloakUserDetails?.preferred_username,
      password: values.password,
      isRecoveryAttempt: false,
      recoveryAttempt: false,
      realmName: realmName,
    };
    try {
      const response: any = await updatePassword(payload,{},{},{ "User-Id": userId });
      const status = response?.status;
      if (status === 200) {
        setResponseSuccessMessage(response?.data?.message);
        resetForm();
        setLoader(true);
        setOpenSuccessModal(true);
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const toggleVisibilityPassword = () => {
    setVisiblePassword(!visiblePassword);
  };
  const toggleRestPassword = () => {
    setOldPassword(!oldPassword);
  };
  const toggleVisibilityConfirmPassword = () => {
    setConfirmVisiblePassword(!visibleConfirmPassword);
  };
  const handleCopyPaste = (event: any) => {
    event.preventDefault();
    return false;
  };
  const handleOnChange = () => {
    setResponseErrorMessage(null);
  };
  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };
  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize={true} isInitialValid={isFormDirty}>
        {({ handleSubmit, values, errors }) => {
          return (
            <Form
              onSubmit={(e) => {
                setFormDirty(true);
                handleSubmit(e);
              }}
            >
              <div className="w-full flex pt-0">
                <div className=" w-[100%] flex mx-auto justify-between flex-wrap ">
                  <div className="w-full  ">
                    {!userHavePassword && (
                      <div className="mb-8 w-full relative  ">
                        <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Old Password*</p>
                        <Field
                          as={Input}
                          type={oldPassword ? "Password" : "text"}
                          className="h-[48px] font-medium text-[18px]"
                          name="oldPassword"
                          maxLength={30}
                          onCopy={handleCopyPaste}
                          onPaste={handleCopyPaste}
                          onClick={handleOnChange}
                        />
                        {responseErrorMessage && <div className="error-message">{responseErrorMessage}</div>}
                        <ErrorMessage name="oldPassword" component="div" className="error-message" />
                        <span className="material-symbols-outlined absolute top-[42px] right-3 text-[#667085] cursor-pointer" onClick={toggleRestPassword}>
                          {oldPassword ? " visibility_off" : " visibility"}
                        </span>
                      </div>
                    )}
                    <div className="mb-8 w-full relative  ">
                      <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">New Password*</p>
                      <Field as={Input} type={visiblePassword ? "Password" : "text"} className="h-[48px] font-medium text-[18px]" name="password" onCopy={handleCopyPaste} onPaste={handleCopyPaste} />
                      <span className="material-symbols-outlined absolute top-[42px] right-3 text-[#667085] cursor-pointer" onClick={toggleVisibilityPassword}>
                        {visiblePassword ? " visibility_off" : " visibility"}
                      </span>
                    </div>
                    <div className="mb-8 w-full relative">
                      <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Confirm Password*</p>
                      <Field
                        as={Input}
                        type={visibleConfirmPassword ? "Password" : "text"}
                        className="h-[48px] font-medium text-[18px]"
                        name="confirmPassword"
                        onCopy={handleCopyPaste}
                        onPaste={handleCopyPaste}
                      />
                      <ErrorMessage name="confirmPassword" component="div" className="error-message" />
                      <span className="material-symbols-outlined absolute top-[42px] right-3 text-[#667085] cursor-pointer" onClick={toggleVisibilityConfirmPassword}>
                        {visibleConfirmPassword ? " visibility_off" : " visibility"}
                      </span>
                    </div>
                    <div className="mb-6 errorValidator-container ">
                      <PasswordStrengthIndicator password={values?.password} passwordPolicy={passwordPolicy} />
                    </div>
                    {passwordPolicy?.notRecentlyUsed && (
                      <div data-testid="resetProfilePassword" className="italic mb-2 text-[16px] font-medium font-Inter text-[#5441da]">
                        Note: <span className="text-[#3a3a3a]">Your password cannot be same as the last three passwords</span>
                      </div>
                    )}
                  </div>
                  <div className="flex justify-end w-full mt-4">
                    <Button htmlType="submit" loading={loader} className="btn bg-[#5441DA] w-[153px] h-[55px] font-Inter text-[20px]" type="primary" disabled={Object.keys(errors).length !== 0}>
                      Update
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      {openSuccessModal && (
        <SetPasswordSuccessModal
          finalAPiCall={getDetails}
          open={openSuccessModal}
          handleModalClose={handleCloseSuccessModal}
          handleParentModalClose={handleModalClose}
          responseMessage={responseSuccessMessage}
        />
      )}
      {loader && <Loader />}
    </>
  );
};

export default ResetProfilePassword;
