import React, { useEffect, useState } from "react";
import { Button, Input, Modal, Select } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import CustomButtonBack from "../../../layouts/component/CustomButtonBack";
import ImageView from "../../../layouts/component/ImageView";
import { images } from "../../../assets/images/ImagePaths";
import UsersSearchDropdown from "../layout/UsersSearchDropDown";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { deviceManagementValidationSchema, getUsersListForDeviceManagement } from "./UserListModalHelper";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../services/storage/Storage";
import SuccessMessageModal from "../../access-manager/create/IpTimeSuccessModal";
import Loader from "../../../layouts/component/Loader";
import { uniq } from "lodash";
import { useDispatch } from "react-redux";
import { setReloadDeviceManagement } from "../../../redux/slice/device-management/deviceManagementSlice";
const { Option } = Select;
export default function AddDeviceRestrictionModal(props: any) {
  const { handleCloseModal, modalOpen, isEdit, editRecord } = props;
  const dispatch = useDispatch();
  const realmId = retrieveData("realmId", true);

  const getUserList = useGetApiRequests("userManagement", "POST");
  const createDeviceManagerApi = useGetApiRequests("createDeviceManager", "POST");
  const getParticularDeviceDetailsApi = useGetApiRequests("getParticularDeviceTypes", "GET");
  const updateDeviceManagerApi = useGetApiRequests("updateDeviceManagement", "PUT");
  const getDeviceType = useGetApiRequests("getDeviceTypes", "GET");

  const [initialValues, setInitialValues] = useState({
    serialNumber: "",
    deviceUUId: "",
    deviceType: "",
  });

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [usersList, setUsersList] = useState<any>([]);
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [loader, setLoader] = useState(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>("");
  const [totalActiveUsersList, setTotalActiveUsersList] = useState<any>([]);
  const [userWithDevices, setUserWithDevices] = useState<any>([]);
  const [removedUsersFromDevices, setRemovedUsersFromDevices] = useState<any>([]);
  const [addedUserForDevice, setAddedUserForDevices] = useState<any>([]);
  const [deviceTypeList, setDeviceTypeList] = useState<any>([]);
  const [tempSelectedValues, setTempSelectedValues] = useState<any>([]);
  useEffect(
    () => {
      getServiceProviders();
      if (isEdit) {
        getParticularDeviceDetails();
      } else {
        getUsersListForDeviceManagement(setLoader, getUserList, false, setUsersList, [], setTotalActiveUsersList);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.isEdit],
  );

  useEffect(() => {
    if (!dropdownVisible) {
      setTempSelectedValues([]);
    } else {
      setTempSelectedValues(selectedValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownVisible]);
  const getServiceProviders = async () => {
    try {
      const response: any = await getDeviceType();
      const status = response.status;
      if (status === 200) {
        const list = response?.data?.data;
        const deviceTypes = Object.entries(list)?.map(([value, label]) => ({
          value,
          label,
        }));
        setDeviceTypeList(deviceTypes);
      }
    } catch (err) {
      handleRequestError(err);
      setLoader(false);
    }
  };
  const getParticularDeviceDetails = async () => {
    setLoader(true);
    const pathParams: object = {
      id: `${realmId}/serial-number/${editRecord?.key}`,
    };
    try {
      const response: any = await getParticularDeviceDetailsApi("", {}, pathParams);
      const status = response.status;
      if (status === 200) {
        const responseData = response?.data?.data;
        setInitialValues({
          serialNumber: responseData?.[0]?.serialNumber,
          deviceUUId: responseData?.[0]?.deviceUuid,
          deviceType: responseData?.[0]?.deviceType,
        });
        const userIds = uniq(responseData?.map((item: any) => item?.userEntityId)?.filter((id: string) => id));
        setSelectedValues(userIds);
        setTempSelectedValues(userIds);
        setUserWithDevices(userIds);

        getUsersListForDeviceManagement(setLoader, getUserList, true, setUsersList, userIds, setTotalActiveUsersList);
      }

      setLoader(false);
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const handleCloseSuccessModal = () => {
    setSuccessModal(false);
  };
  const onSubmit = async (values: any, { resetForm }: any) => {
    setLoader(true);

    try {
      const payload: any = {
        serialNumber: values?.serialNumber,
        deviceUuid: values?.deviceUUId,
        deviceType: values?.deviceType,
        realmId,
      };
      if (isEdit) {
        await updateDeviceManager(payload, resetForm);
      } else {
        await createDeviceManager(payload, resetForm);
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    } finally {
      setLoader(false);
    }
  };
  const capitalizeFirstLetter = (string: any) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  const afterApiSuccessCallback = (response: any, resetForm: any) => {
    setLoader(false);
    setSuccessModal(true);
    setResponseMessage(response?.data?.message);
    setTimeout(() => {
      dispatch(setReloadDeviceManagement(true));
      handleCloseModal();
      resetForm();
      handleCloseSuccessModal();
    }, 2000);
  };
  const createDeviceManager = async (payload: any, resetForm: any) => {
    setLoader(true);
    try {
      if (selectedValues && selectedValues.length > 0) {
        const selectedEmails = totalActiveUsersList.filter((user: any) => selectedValues?.includes(user?.key))?.map((user: any) => user?.email);
        payload.userEmailIdList = selectedEmails;
      }
      const response: any = await createDeviceManagerApi(payload);
      const status = response.status;
      if (status === 200) {
        afterApiSuccessCallback(response, resetForm);
      }
    } catch (err) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  const updateDeviceManager = async (payload: any, resetForm: any) => {
    setLoader(true);
    try {
      const pathParams = {
        id: `${editRecord?.key}`,
      };
      if (addedUserForDevice.length > 0) {
        payload.addUserIdList = addedUserForDevice;
      }
      if (removedUsersFromDevices.length > 0) {
        payload.removedUserIdList = removedUsersFromDevices;
      }
      const response: any = await updateDeviceManagerApi(payload, "", pathParams);
      const status = response?.status;
      if (status === 200) {
        afterApiSuccessCallback(response, resetForm);
      }
    } catch (err) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  const filterOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleLabelClick = (event: any, value: any) => {
    event.stopPropagation();
    const newValue: any = [...tempSelectedValues];
    if (newValue.includes(value)) {
      newValue.splice(newValue.indexOf(value), 1);
    } else {
      newValue.push(value);
    }
    setTempSelectedValues(newValue);
  };

  const handleAddApps = () => {
    setSelectedValues(tempSelectedValues);
    setDropdownVisible(false);
    const previousValue = [...userWithDevices];
    const missingIds = previousValue.filter((item) => !tempSelectedValues.includes(item)).map((item) => item);
    setRemovedUsersFromDevices(missingIds);
    const addedIds = tempSelectedValues.filter((item: any) => !previousValue.includes(item)).map((item: any) => item);
    setAddedUserForDevices(addedIds);
  };

  const dropdownRender = (menu: any) => {
    return (
      <div>
        <div>{menu}</div>
        <UsersSearchDropdown handleAddApps={handleAddApps} setDropdownVisible={setDropdownVisible} />
      </div>
    );
  };
  const handleCloseDeviceForm = (resetForm: any) => {
    handleCloseModal();
    resetForm();
  };

  return (
    <div className="add-device-restriction-modal">
      <Modal
        className=" rounded-lg  _suspended show-notification add-device-restriction-modal"
        closable={true}
        centered
        open={modalOpen}
        footer={false}
        onCancel={handleCloseModal}
        maskClosable={false}
      >
        <div className=" rounded-lg rounded-b-none border-b-2 bg-[#F0F0FA] py-5 pl-16 flex items-center">
          <div className="pl-2">
            <span className="text-[#171717] font-bold text-[28px] font-Inter">{isEdit ? "Edit Details" : "Add Device"}</span>
            <p className="text-[14px]  text-[#444444] font-Inter font-medium">This information is necessary for the allocation and administration of the user.</p>
          </div>
        </div>
        <div className="pt-4 modal-body pl-20 mt-8">
          <Formik initialValues={initialValues} values={initialValues} validationSchema={deviceManagementValidationSchema} onSubmit={onSubmit} enableReinitialize={true}>
            {({ setFieldValue, resetForm, values }) => {
              return (
                <Form>
                  <div className="w-[90%] flex flex-wrap justify-between gap-5 border-b">
                    <div className="mb-6 relative w-[100%] xl:w-[45%]">
                      <p className="text-[#171717] font-medium text-[18px] font-Inter">Serial Number *</p>
                      <Field
                        as={Input}
                        type={"text"}
                        className="h-[48px] font-medium text-[18px] font-Inter input-field"
                        name="serialNumber"
                        data-testid="password"
                        placeholder="Enter Serial Number"
                      />
                      <ErrorMessage name="serialNumber" component="div" className="error-message custom-err" />
                    </div>
                    <div className="mb-6 relative w-[100%] xl:w-[45%]">
                      <p className="text-[#171717] font-medium text-[18px] font-Inter">Device UUID *</p>
                      <Field as={Input} type={"text"} className="h-[48px] font-medium text-[18px] font-Inter input-field" name="deviceUUId" data-testid="password" placeholder="Enter deviceUUID" />
                      <ErrorMessage name="deviceUUId" component="div" className="error-message" />
                    </div>
                    <div className="mb-6 relative w-[100%] xl:w-[45%]">
                      <p className="text-[#171717] font-medium text-[18px] font-Inter">Device Type *</p>
                      <Select
                        suffixIcon={
                          <div className="w-6">
                            <ImageView src={images.chevronDown} alt="chevronDown" />
                          </div>
                        }
                        className="w-full h-[48px] mr-8 text-[20px] input-field"
                        onChange={(value) => setFieldValue("deviceType", value)}
                        placeholder="Select Type"
                        value={values?.deviceType || undefined}
                      >
                        {deviceTypeList?.map((option: any) => (
                          <Select.Option key={option?.value} value={option?.value} className="capitalize">
                            {capitalizeFirstLetter(option?.label)}
                          </Select.Option>
                        ))}
                      </Select>
                      <ErrorMessage name="deviceType" component="div" className="error-message" />
                    </div>
                    <div className="mb-6 relative w-[100%] xl:w-[45%]">
                      <p className="text-[#171717] font-medium text-[18px] font-Inter">Assigned Users</p>
                      <div className="w-[100%]">
                        <Select
                          className="h-14 w-full mb-5 custom-dropdown"
                          mode="tags"
                          optionLabelProp="label"
                          filterOption={filterOption}
                          tokenSeparators={[","]}
                          placeholder={"Search and Select"}
                          dropdownRender={dropdownRender}
                          onDropdownVisibleChange={(visible) => setDropdownVisible(visible)}
                          open={dropdownVisible}
                          value={selectedValues}
                        >
                          {usersList?.map((option: any) => (
                            <Option key={option?.value} value={option?.value} label={option?.name}>
                              <input
                                name={option?.name}
                                id={option?.value}
                                checked={tempSelectedValues.includes(option?.value)}
                                onChange={(e) => handleLabelClick(e, option?.value)}
                                type="checkbox"
                                className="dropdown-list w-5 device-management-user-list-checkbox"
                              />
                              <label htmlFor={option.name} className="flex flex-col mb-3 h-14 pointer-events-none justify-center">
                                {option.name} <span className="absolute top-[32px] text-[#747577]">{option?.email}</span>
                              </label>
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="p-8 modal-footer mt-7">
                    <div className="flex justify-end w-full">
                      <CustomButtonBack
                        text={"Cancel"}
                        onClick={() => {
                          handleCloseDeviceForm(resetForm);
                        }}
                      />
                      <Button type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn">
                        {!isEdit ? "Save" : "Update"}
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        {loader && <Loader />}
      </Modal>
      {successModal && <SuccessMessageModal open={successModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
    </div>
  );
}
