import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
import { keycloakLogout } from "../../../redux/slice/Users/KeyCloakSlice";
import { setClearPermission } from "../../../redux/slice/permissions/permissions";
import { clearThemeConfigure } from "../../../redux/slice/theme/themeSlice";
import { retrieveData } from "../../../services/storage/Storage";

export const clearStore = (dispatch: any) => {
  localStorage.clear();
  sessionStorage.clear();
  dispatch(clearThemeConfigure());
  dispatch(keycloakLogout());
  dispatch(setClearPermission());
};
export const handleLogout = async (dispatch: any, gettingLogoutUrls: any, userDetails: any, userSessionKilling?: any, setLoader?: any, deviceUrl?: any, setLogoutLoader?: any) => {
  const realmId = retrieveData("realmId", true);
  const userName = retrieveData("userName", true);
  const openedTabs: any = [];

  let emailId = "";
  if (userDetails?.username) {
    emailId = userDetails.username;
  } else {
    emailId = userName;
  }
  
  const queryParams: object = {
    realmId: realmId,
    userEmailId: emailId,
  };
  const payload: object = {
    emailId: emailId,
    realmId: realmId,
  };

  setLoader(true);
  setLogoutLoader(true);
  try {
    const response: any = await gettingLogoutUrls("", queryParams);
    const status = response.status;
    if (status === 200) {
      const apiUrls = response?.data?.data;

      if (apiUrls.length > 0 && !apiUrls[0]?.logoutUrl?.includes("logouturl")) {
        const openAndCloseTabs = async () => {
          for (let i = 0; i < apiUrls?.length; i++) {
            const { logoutUrl } = apiUrls[i];
            if (logoutUrl) {
              await new Promise((resolve) => setTimeout(resolve, i * 700));
              const newTab = window.open(logoutUrl, "_blank");
              if (newTab) {
                openedTabs.push(newTab);
              }
            }
          }

          await new Promise((resolve) => setTimeout(resolve, apiUrls.length * 700));
          openedTabs.forEach((tab: any) => tab.close());
          userSessionKilling(payload)
            .then((res: any) => {
              if (res.status === 200) {
                clearStore(dispatch);
                setLogoutLoader(false);

                if (!deviceUrl) {
                  window.location.href = "/";
                } else {
                  window.location.href = "/device-logout";
                }
              }
            })
            .catch((err: any) => {
              setLoader(false);
              setLogoutLoader(false);
              handleRequestError(err);
            });
        };

        await openAndCloseTabs();
      } else {
        userSessionKilling(payload)
          .then((res: any) => {
            if (res.status === 200) {
              clearStore(dispatch);
              if (!deviceUrl) {
                window.location.href = "/";
              } else {
                window.location.href = "/device-logout";
              }
            }
          })
          .catch((err: any) => {
            setLoader(false);
            setLogoutLoader(false);
            handleRequestError(err);
          });
      }
    }
  } catch (error: any) {
    setLoader(false);
    setLogoutLoader(false);
    handleRequestError(error);
  }
};
