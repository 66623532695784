import * as Yup from "yup";

export const connectorType = [
  { label: "REST API", value: "RESTAPI" },
  { label: "Database", value: "Database" },
];

export const defaultConnector = [
  { text: "Yes", value: "yes" },
  { text: "No", value: "No" },
];
export const authenticationType = [
  { label: "Service Token", value: "servicetoken" },
  { label: "API Token", value: "apitoken" },
  { label: "OIDC", value: "oidc" },
];

export const connectorDetailsInitialValue = {
  name: "",
  description: "",
  type: "",
  isDefaultSourceConnector: "",
  authenticationType: "",
};

export const connectorAuthenticationReduxDetails = (data: any) => {
  let storeTokenApiUrl = "";

  if (data.authenticationType === "servicetoken") {
    storeTokenApiUrl = data.tokenApiEndpoint;
  } else if (data.authenticationType === "apitoken") {
    storeTokenApiUrl = data?.tokenApiEndpoint && JSON.stringify(JSON.parse(data?.tokenApiEndpoint));
  }

  const authenticationDetails = {
    apiToken: data?.authToken && JSON.parse(data?.authToken)?.token,
    tokenApiUrl: data?.authToken && JSON.stringify(JSON.parse(data?.authToken)),
    userNameEmail: data?.username,
    password: data?.password,
    clientId: data?.clientId,
    secret: data?.secret,
    grantType: data?.type,
    tokenApiEndpoint: storeTokenApiUrl,
    userName: data?.username,
  };
  return authenticationDetails;
};

export const manageConnectionSchema = Yup.object().shape({
  manageConnectorName: Yup.string().required("Connector name is required"),
  manageDescription: Yup.string().required("Description is required"),
});

export const initAppConfiguration = {
  connectorName: "",
  description: "",
  connectorType: "RESTAPI",
  connectorURL: "",
  userName: "",
  password: "",
  provisionURL: "",
  deprovisionURL: "",
  authenticationType: "",
};

export const initAppConnection = {
  manageDescription: "",
  manageConnectorName: "",
};

export const createAuthSchema = Yup.object().shape({
  tokenApiUrl: Yup.string().required("Token API EndPoint Url is required"),
  userName: Yup.string().required("User name is required"),
  password: Yup.string().required("Password is required"),
  grantType: Yup.string().required("Grant_type  is required"),
  clientId: Yup.string().required("Client_id is required"),
  clientSecret: Yup.string().required("Client_secret is required"),
  authToken: Yup.string().required("Authentication Token is required"),
  apiToken: Yup.string().required("API Token is required"),
  userNameEmail: Yup.string().required("Username/Email is required"),
});

export const initialAuthValues = {
  tokenApiUrl: "",
  userName: "",
  password: "",
  grantType: "",
  clientId: "",
  clientSecret: "",
  authToken: "",
};

// export const connectorProvisionSchema = Yup.object().shape({
//   userProvision: Yup.array().of(
//     Yup.object().shape({
//       apiEndpointURL: Yup.string().required("API Endpoint URL is required"),
//       methodType: Yup.string().required("Method Type is required"),
//       authType: Yup.string().required("Authentication Type is required"),
//       contentType: Yup.string().required("Content Type is required"),

//       token: Yup.string().when("authType", {
//         is: "bearerToken",
//         then: (schema) => schema.required("Token is required"),
//       }),

//       userName: Yup.string().when("authType", {
//         is: "basicAuth",
//         then: (schema) => schema.required("User name is required"),
//       }),

//       password: Yup.string().when("authType", {
//         is: "basicAuth",
//         then: (schema) => schema.required("Password is required"),
//       }),

//       algorithm: Yup.string().when("authType", {
//         is: "JWTBearer",
//         then: (schema) => schema.required("Algorithm is required"),
//       }),

//       secret: Yup.string().when("authType", {
//         is: "JWTBearer",
//         then: (schema) => schema.required("Secret is required"),
//       }),

//       apiKey: Yup.string().when("authType", {
//         is: "apiKey",
//         then: (schema) => schema.required("Key is required"),
//       }),

//       apiValue: Yup.string().when("authType", {
//         is: "apiKey",
//         then: (schema) => schema.required("Value is required"),
//       }),

//       apiAddTo: Yup.string().when("authType", {
//         is: "apiKey",
//         then: (schema) => schema.required("Add To is required"),
//       }),
//       requestPayload: Yup.string().required("Request Payload is required"),
//       response: Yup.string().required("Response is required"),
//     }),
//   ),
//   roleProvision: Yup.array().of(
//     Yup.object().shape({
//       apiEndpointURL: Yup.string().required("API Endpoint URL is required"),
//       methodType: Yup.string().required("Method Type is required"),
//       authType: Yup.string().required("Authentication Type is required"),
//       contentType: Yup.string().required("Content Type is required"),

//       // Conditional validations based on authType
//       token: Yup.string().when("authType", {
//         is: "bearerToken",
//         then: (schema) => schema.required("Token is required"),
//       }),

//       userName: Yup.string().when("authType", {
//         is: "basicAuth",
//         then: (schema) => schema.required("User name is required"),
//       }),

//       password: Yup.string().when("authType", {
//         is: "basicAuth",
//         then: (schema) => schema.required("Password is required"),
//       }),

//       algorithm: Yup.string().when("authType", {
//         is: "JWTBearer",
//         then: (schema) => schema.required("Algorithm is required"),
//       }),

//       secret: Yup.string().when("authType", {
//         is: "JWTBearer",
//         then: (schema) => schema.required("Secret is required"),
//       }),

//       apiKey: Yup.string().when("authType", {
//         is: "apiKey",
//         then: (schema) => schema.required("Key is required"),
//       }),

//       apiValue: Yup.string().when("authType", {
//         is: "apiKey",
//         then: (schema) => schema.required("Value is required"),
//       }),

//       apiAddTo: Yup.string().when("authType", {
//         is: "apiKey",
//         then: (schema) => schema.required("Add To is required"),
//       }),
//       requestPayload: Yup.string().required("Request Payload is required"),
//       response: Yup.string().required("Response is required"),
//     }),
//   ),
//   groupProvision: Yup.array().of(
//     Yup.object().shape({
//       apiEndpointURL: Yup.string().required("API Endpoint URL is required"),
//       methodType: Yup.string().required("Method Type is required"),
//       authType: Yup.string().required("Authentication Type is required"),
//       contentType: Yup.string().required("Content Type is required"),

//       // Conditional validations based on authType
//       token: Yup.string().when("authType", {
//         is: "bearerToken",
//         then: (schema) => schema.required("Token is required"),
//       }),

//       userName: Yup.string().when("authType", {
//         is: "basicAuth",
//         then: (schema) => schema.required("User name is required"),
//       }),

//       password: Yup.string().when("authType", {
//         is: "basicAuth",
//         then: (schema) => schema.required("Password is required"),
//       }),

//       algorithm: Yup.string().when("authType", {
//         is: "JWTBearer",
//         then: (schema) => schema.required("Algorithm is required"),
//       }),

//       secret: Yup.string().when("authType", {
//         is: "JWTBearer",
//         then: (schema) => schema.required("Secret is required"),
//       }),

//       apiKey: Yup.string().when("authType", {
//         is: "apiKey",
//         then: (schema) => schema.required("Key is required"),
//       }),

//       apiValue: Yup.string().when("authType", {
//         is: "apiKey",
//         then: (schema) => schema.required("Value is required"),
//       }),

//       apiAddTo: Yup.string().when("authType", {
//         is: "apiKey",
//         then: (schema) => schema.required("Add To is required"),
//       }),
//       requestPayload: Yup.string().required("Request Payload is required"),
//       response: Yup.string().required("Response is required"),
//     }),
//   ),
// });
const tokenApiUrlStringSchema = (fieldName: string) =>
  Yup.string()
    .required(`${fieldName} is required`)
    .test("is-plain-string", "The input must be a string, not JSON or other formats", (value) => {
      if (!value) return false;
      try {
        JSON.parse(value);
        return false;
      } catch (error) {
        return true;
      }
    });

const tokenApiUrlJsonSchema = (fieldName: string) =>
  Yup.string()
    .required(`${fieldName} is required`)
    .test("is-json", "The input must be a valid JSON string", (value) => {
      if (!value) return false;
      try {
        JSON.parse(value);
        return true;
      } catch (error) {
        return false;
      }
    });

const headerParamSchema = Yup.array()
  .of(
    Yup.object().shape({
      key: Yup.string().required("Key is required"),
      // value: Yup.string().required("Value is required"),
    }),
  )
  .required("Header Param is required");

export const connectorProvisionSchema = Yup.object().shape({
  userProvision: Yup.array().of(
    Yup.object().shape({
      apiEndpointURL: Yup.string().required("API Endpoint URL is required").url("Please enter a valid API Endpoint URL"),
      methodType: Yup.string().required("Method Type is required"),
      requestPayload: tokenApiUrlJsonSchema("Request Payload"),
      response: tokenApiUrlJsonSchema("Response"),
      // headerParametersType: Yup.string().required("Header Parameter Type is required"),
      // headerParam: headerParamSchema
    }),
  ),
});

export const connectorDeProvisionSchema = Yup.object().shape({
  userDeProvision: Yup.array().of(
    Yup.object().shape({
      apiEndpointURL: Yup.string().required("API Endpoint URL is required"),
      methodType: Yup.string().required("Method Type is required"),
      // headerParametersType: Yup.string().required("Header Parameter Type is required"),
      headerParam: headerParamSchema,
    }),
  ),
});

export const connectionAuthSchema = (authType: string) => {
  switch (authType) {
    case "oidc":
      return Yup.object().shape({
        tokenApiEndpoint: tokenApiUrlStringSchema("Token API EndPoint Url"),
        userName: Yup.string().required("Username is required"),
        password: Yup.string().required("Password is required"),
        grantType: Yup.string().required("Grant type is required"),
        clientId: Yup.string().required("Client ID is required"),
        secret: Yup.string().required("Client Secret is required"),
      });
    case "servicetoken":
      return Yup.object().shape({
        tokenApiUrl: tokenApiUrlJsonSchema("Service Token"),
      });
    default:
      return Yup.object().shape({
        apiToken: tokenApiUrlStringSchema("API Token"),
        userNameEmail: Yup.string().required("Username / Email is required"),
      });
  }
};
interface GeneralDetails {
  name: string;
  description: string;
  type: string;
  isDefaultSourceConnector: string;
  authenticationType: string;
  [key: string]: any;
}

interface AppDetails {
  logoUrl: string;
  [key: string]: any;
}

interface AuthPayload {
  tokenApiEndpoint?: string;
  username?: string;
  password?: string;
  clientId?: string;
  secret?: string;
  authToken?: string;
  [key: string]: any;
}

export const connectionAuthPayload = (values: any, generalDetails: GeneralDetails, realmId: string, appDetails: AppDetails, connectorDetailsAuthType: string): { [key: string]: any } => {
  const isDefaultSourceConnector = generalDetails.isDefaultSourceConnector === "yes";

  const basePayload = {
    name: generalDetails.name,
    description: generalDetails.description,
    type: generalDetails.type,
    isDefaultSourceConnector,
    realmId,
    authenticationType: generalDetails.authenticationType,
    logoUrl: appDetails.logoUrl,
  };

  const authPayload: AuthPayload = {};

  if (connectorDetailsAuthType === "oidc") {
    authPayload.tokenApiEndpoint = `{"token": "${values.tokenApiEndpoint}"}`;
    authPayload.username = values.userName;
    authPayload.password = values.password;
    authPayload.clientId = values.clientId;
    authPayload.secret = values.secret;
  } else if (connectorDetailsAuthType === "apitoken") {
    authPayload.authToken = `{"token": "${values.apiToken}"}`;
    authPayload.username = values.userNameEmail;
  } else {
    authPayload.authToken = values.tokenApiUrl;
    authPayload.tokenApiEndpoint = values.tokenApiEndpoint ? values.tokenApiEndpoint : "";
    authPayload.username = values.userNameEmail ? values.userNameEmail : "";
  }
  return { ...basePayload, ...authPayload };
};

export const endPointDescriptionPayload = (type: string) => {
  switch (type) {
    case "Post":
      return "create_user";
    case "Delete":
      return "delete_user";
    case "Get":
      return "get_user";
    default:
      return "create_user";
  }
};

interface HeaderParameter {
  key: string;
  value: string;
}

export function transformHeaderParameters(headerParameters?: HeaderParameter[]): Record<string, string> {
  if (!Array.isArray(headerParameters)) {
    return {}; // Return an empty object if headerParameters is not an array or undefined
  }

  return headerParameters.reduce(
    (acc, item) => {
      if (!item.key) {
        return {}; // Return an empty object if any key is empty
      }

      acc[item.key] = item.value;
      return acc;
    },
    {} as Record<string, string>,
  );
}

export const headerParamNonMandatory = (el: number) => {
  return el === 1 ? true : false;
};
export const pathVariableNonMandatory = (el: number) => {
  return el === 2 ? true : false;
};