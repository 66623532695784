import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, Field, FieldArray } from "formik";
import { Input, Tooltip } from "antd";

const CustomFieldBasic = (props: any) => {
  const { values, setFieldValue, userDetails, setUserDetails, errors } = props;

  const [editableFields, setEditableFields] = useState<any>({});
  const inputRefs = useRef<any>([]);

  useEffect(() => {
    Object.keys(editableFields).forEach((index: any) => {
      if (editableFields[index] && inputRefs.current[index]) {
        inputRefs.current[index]?.focus();
      }
    });
  }, [editableFields]);

  const handleEditClick = (index: any) => {
    setEditableFields((prev: any) => ({ ...prev, [index]: !prev[index] }));
  };
  const handleReset = (index: any) => {
    setFieldValue(`customField[${index}].name`, "Untitled");
    setEditableFields((prev: any) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleRemoveList = (index: any, remove: any, val: any) => {
    setUserDetails((prevState: any) => {
      const existingAttributeIndex = prevState.userDetailDto?.attributes?.findIndex((attribute: any) => attribute.name === val.name);

      let newAttributes;
      if (existingAttributeIndex !== -1) {
        newAttributes = userDetails.userDetailDto?.attributes?.filter((attribute: any) => attribute.name !== val.name);
      } else {
        newAttributes = [...(prevState?.userDetailDto?.attributes || []), val];
      }
      return {
        ...prevState,
        userDetailDto: {
          ...prevState.userDetailDto,
          attributes: newAttributes,
        },
      };
    });
    remove(index);
  };
  return (
    <div className="w-full">
      <FieldArray name="customField">
        {({ push, remove }) => {
          return (
            <div className="w-full">
              <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
                {values?.customField?.map((field: any, index: any) => (
                  <div className="w-full" key={field?.id}>
                    <div className="flex mb-8 w-full relative">
                      <div className="w-full ">
                        <div>
                          <Tooltip
                            className="error-tooltip"
                            placement="topLeft"
                            title={errors.customField?.[index]?.name && <ErrorMessage name={`customField[${index}].name`} component="span" className="text-[#cf3b30]" />}
                          >
                            <div className="flex items-center relative h-4 mb-2">
                              <Field name={`customField[${index}].name`}>
                                {({ field }: any) => (
                                  <Input
                                    {...field}
                                    type="text"
                                    disabled={!editableFields[index]}
                                    maxLength={15}
                                    ref={(el) => (inputRefs.current[index] = el)}
                                    className={`${!editableFields[index] ? "no-edit" : "allow-edit"} text-[#1D1D24] font-medium text-[18px] edit-field ${errors.customField?.[index]?.name ? "error-field" : ""}`}
                                  />
                                )}
                              </Field>
                              <div className="actions absolute  left-[250px]">
                                <span className="material-symbols-outlined text-[18px] ml-3 cursor-pointer" onClick={() => handleEditClick(index)} role="none">
                                  {editableFields[index] ? "done" : "edit"}
                                </span>
                                <span role="none" className="material-symbols-outlined text-[18px] ml-3 cursor-pointer" onClick={() => handleReset(index)}>
                                  {editableFields[index] ? "close" : ""}
                                </span>
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                        <div className="flex relative">
                          <Field as={Input} type="text" className="h-[56px]  font-medium text-[18px]" name={`customField[${index}].value`} />
                          <ErrorMessage name={`customField[${index}].value`} component="div" className="error-message bottom-[-28px]" />
                          <div className="flex justify-center items-center">
                            {index !== 0 && (
                              <span role="none" className="material-symbols-outlined add-custom remove cursor-pointer" onClick={() => handleRemoveList(index, remove, values.customField[index])}>
                                remove
                              </span>
                            )}
                            {index === values.customField.length - 1 && (
                              <span
                                className={`material-symbols-outlined cursor-pointer add-custom `}
                                onClick={() => push({ value: "", name: index === 0 ? "Untitled1" : `Untitled${index + 1}`, custom: true, id: index })}
                                role="none"
                              >
                                add
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        }}
      </FieldArray>
    </div>
  );
};

export default CustomFieldBasic;
