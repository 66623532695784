import * as Yup from "yup";

export const createAppSchema = Yup.object().shape({
  name: Yup.string().required("Connector name is required"),
  description: Yup.string().required("Description is required"),
  type: Yup.string().required("Connector type is required"),
  authenticationType: Yup.string().when("type", {
    is: (value: string | undefined) => value === "RESTAPI",
    then: (schema) => schema.required("Authentication Type is required"),
    otherwise: (schema) => schema,
  }),
  // connectorURL: Yup.string().required("Connector URL is required").url("Invalid URL"),
  // userName: Yup.string().required("User name is required"),
  // password: Yup.string().required("Password is required"),
  // provisionURL: Yup.string().required("Provisioning URL is required").url("Invalid URL"),
  // deprovisionURL: Yup.string().required("Deprovisioning URL is required").url("Invalid URL"),
});
