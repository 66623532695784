import { useEffect, useState } from "react";
import { useAccessManager } from "../context/AccessManagerContext";
import CreateAccessMangerModal from "../create/CreateAccessMangerModal";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { retrieveData, storeData } from "../../../services/storage/Storage";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
import { useDispatch, useSelector } from "react-redux";
import { setInformationFromIpGet, setIpEditRecord, setParticularIpInfo } from "../../../redux/slice/IPSlice";
import { useNavigate } from "react-router-dom";
import { getPermissionStyle, hasPermission } from "../../../redux/slice/permissions/permissionUtils";
import { modulePermissions } from "../../../const/RolePermissions";
import DeviceRestrictionWarningModal from "../../dashboard/user/user-dashboard/adduser/device-authorization/DeviceRestrictionWarningModal";
import AccessManagerContainerTable from "./AccessManagerContainerTable";
import AccessManagerContainerHeader from "./AccessManagerContainerHeader";
import { accessManagerListFormation, getIpDetailsConvertedData, handleNotification, toggleIPPayloadFormation, toggleTimePayloadFormation } from "./AccessManagerHelperFile";
import { getUserGroupCount, getUsersCountForGroups } from "./AccessManagerGetGroupCount";
import PermissionsModal from "../../../layouts/permissionsModal/PermissionsModal";
import DeleteRestrictionModal from "../create/Model/DeleteRestrictionModal";
import { ErrorMessages } from "../../../const/Messages";
import Loader from "../../../layouts/component/Loader";

export default function AccessManagerContainer() {
  const { openCreateAccessManager, warningModal, closeWarningModal } = useAccessManager();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [accessManagerList, setAccessManagerList] = useState<any>([]);
  const [permissionModal, setPermissionModal] = useState(false);
  const [editRecords, setEditRecords] = useState<any>({});
  const [deleteRestrictionModalOpen, setDeleteRestrictionModalOpen] = useState(false);
  const [toReload, setToReload] = useState(false);

  const getAccessManager = useGetApiRequests("getAccessManager", "GET");
  const getIPRestrictionDetails = useGetApiRequests("getParticularIpRestriction", "POST");
  const getUserListCount = useGetApiRequests("userManagement", "POST");
  const getGroupsListCount = useGetApiRequests("getGroupsList", "GET");
  const updateIpRestriction = useGetApiRequests("updateIpRestriction", "PUT");
  const updateTimeRestrictionApi = useGetApiRequests("updateTimeBasedRestriction", "PUT");
  const getParticularTimeRestriction = useGetApiRequests("getAccessManagerDetails", "GET");

  const realmId = retrieveData("realmId", true);
  const accessManagerPermissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const accessManagerCreate = accessManagerPermissions["Access Manager"]?.create;
  const accessManagerEdit = accessManagerPermissions["Access Manager"]?.update;
  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const hasDeletePermission = permissions && hasPermission(permissions, modulePermissions.accessManger, "delete");

  const onChange = async (checked: boolean, record: any) => {
    if (accessManagerEdit) {
      //IP restriction
      if (record?.classification === "a") {
        const ipRestrictionPayload = toggleIPPayloadFormation(record, checked);
        try {
          const response: any = await updateIpRestriction(ipRestrictionPayload);
          const status = response.status;
          if (status === 200) {
            setLoader(false);
            handleNotification("IP", checked);
            getAccessManagerList();
          }
        } catch (err: any) {
          setLoader(false);
          handleRequestError(err);
        }
      } else if (record?.classification === "c") {
        //Time Restriction
        getTimeDetails(record, true, checked);
      }
    } else {
      setPermissionModal(true);
    }
  };

  const getAccessManagerList = async () => {
    const pathParams: any = {
      realmId: realmId,
    };
    setLoader(true);
    try {
      const response: any = await getAccessManager("", {}, pathParams);
      const status = response.status;
      if (status === 200) {
        const mappingData = response?.data?.data;
        getUsersCountForGroups(getUserListCount);
        getUserGroupCount(getGroupsListCount);
        const reformedArray = accessManagerListFormation(mappingData);
        setAccessManagerList(reformedArray);
      }
      setTimeout(() => {
        setLoader(false);
      }, 3000);
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };
  useEffect(() => {
    getAccessManagerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toReload]);
  const handleDeleteRestrictionPopup = (record: any) => {
    if (hasDeletePermission) {
      setDeleteRestrictionModalOpen(true);
      setEditRecords(record);
    } else {
      setPermissionModal(true);
    }
  };
  const handleClickRestrictionName = (record: any) => {
    if (accessManagerEdit) {
      if (record?.classification === "a") {
        getIpDetails(record);
      } else if (record?.classification === "c") {
        getTimeDetails(record, false, false);
      } else if (record?.classification === "b") {
        storeData("key", "5", false);
        navigate("/access-manager/device-based-restriction");
      }
    } else {
      setPermissionModal(true);
    }
  };
  const getIpDetails = async (record: any) => {
    dispatch(setInformationFromIpGet(record));
    const payload: any = {
      realmId: realmId,
      name: record?.name,
    };
    try {
      const response: any = await getIPRestrictionDetails(payload);

      const status = response.status;
      dispatch(setParticularIpInfo(response?.data?.data));
      if (status === 200) {
        const data = response?.data?.data;
        const convertData = await getIpDetailsConvertedData(data);
        if (convertData?.assignTo === "groups") {
          const ipGroupIds: any = response?.data?.data?.userGroupRestrictions
            ?.filter(({ keycloakGroupId }: any) => keycloakGroupId !== null && keycloakGroupId !== undefined)
            .map(({ keycloakGroupId }: any) => keycloakGroupId);
          convertData.groupIds = ipGroupIds;
          dispatch(setIpEditRecord(convertData));
          navigate("/access-manager/select-ip?isEdit=true&type=groups");
        } else if (convertData?.assignTo === "users") {
          const ipUserIds: any = response?.data?.data?.userGroupRestrictions
            .filter(({ userEntityId }: any) => userEntityId !== null && userEntityId !== undefined)
            .map(({ userEntityId }: any) => userEntityId);
          convertData.userId = ipUserIds;
          dispatch(setIpEditRecord(convertData));
          navigate("/access-manager/select-ip?isEdit=true&type=users");
        } else if (convertData?.assignTo === "organization") {
          convertData.akkuUserIpBasedAuthorizationId = response?.data?.data?.userGroupRestrictions?.akkuUserIpBasedAuthorizationId;
          dispatch(setIpEditRecord(convertData));
          navigate("/access-manager/select-ip?isEdit=true&type=organization");
        }
      }
      setLoader(false);
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };
  const getTimeDetails = async (record: any, isNavigate: any, isActive: any) => {
    let navigationType: string = "";
    const queryParams: any = {
      akkuUserTimeBasedAuthorizationId: record?.akkuUserTimeBasedAuthorizationId,
    };
    try {
      const response: any = await getParticularTimeRestriction("", queryParams);
      if (response.status === 200) {
        const timeBasedRestrictionApiDetails = response?.data?.data?.timeBasedAuthorization;
        dispatch(setIpEditRecord(timeBasedRestrictionApiDetails));
        const assignedToLower = record?.assigned?.toLowerCase();
        storeData("timeRecords", true);
        if (!isNavigate) {
          if (assignedToLower?.includes("user")) {
            navigationType = "users";
          } else if (assignedToLower?.includes("group")) {
            navigationType = "groups";
          } else if (assignedToLower?.includes("all")) {
            navigationType = "organization";
          }
          navigate(`/access-manager/time-parameters?isEdit=true&type=${navigationType}`);
        } else {
          disableTimeParameter(response?.data, record, isActive);
        }
      }
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  const disableTimeParameter = async (response: any, record: any, isActive: any) => {
    const payload = toggleTimePayloadFormation(response, record, isActive);
    const pathParams: any = {
      id: record?.akkuUserTimeBasedAuthorizationId,
    };
    try {
      const response: any = await updateTimeRestrictionApi(payload, {}, pathParams);
      const status = response.status;
      if (status === 200) {
        handleNotification("Time", isActive);
        setLoader(false);
        getAccessManagerList();
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const closePermissionModal = () => {
    setPermissionModal(false);
  };
  return (
    <div className="akku-container">
      <div className="main-container">
        <div className="dashboard-container h-full">
          <AccessManagerContainerHeader accessManagerList={accessManagerList} />
          <AccessManagerContainerTable
            props={{
              accessManagerList: accessManagerList,
              accessManagerCreate: accessManagerCreate,
              getPermissionStyle: getPermissionStyle,
              handleClickRestrictionName: handleClickRestrictionName,
              onChange: onChange,
              handleDeleteRestrictionPopup: handleDeleteRestrictionPopup,
              openCreateAccessManager: openCreateAccessManager,
            }}
          />
        </div>
      </div>
      <CreateAccessMangerModal />
      {loader && <Loader />}
      {permissionModal && <PermissionsModal open={permissionModal} close={closePermissionModal} />}
      {deleteRestrictionModalOpen && (
        <DeleteRestrictionModal
          editRecord={editRecords}
          deleteRestrictionModalOpen={deleteRestrictionModalOpen}
          setDeleteRestrictionModalOpen={setDeleteRestrictionModalOpen}
          setToReload={setToReload}
          toReload={toReload}
          setLoader={setLoader}
        />
      )}
      {warningModal && <DeviceRestrictionWarningModal modalOpen={warningModal} setModalOpen={closeWarningModal} contentMessage={ErrorMessages?.deviceRestrictionWarning} />}
    </div>
  );
}
