import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import GeneralDetailsForm from "../../../layouts/dashboard-module/customer-table/modal/forms/GeneralDetailsForm";
import { ValidationSchema } from "../../../layouts/dashboard-module/customer-table/modal/schema/ValidationScheme";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
import { useSelector } from "react-redux";
import EditEmailConfiguration from "./EditEmailConfiguration";
import EditSSOSettings from "./EditSSOSettings";
import ProductsAndPlans from "../../../layouts/dashboard-module/customer-table/modal/forms/products/ProductsAndPlans";
import Loader from "../../../layouts/component/Loader";
import { useNavigate } from "react-router-dom";
import "./EditTenentDetails.scss";
const EditTenentDetails = () => {
  const [initialValues, setInitialValues] = useState<any>({
    customerId: "",
    displayName: "",
    customerEmailId: "",
    noOfLicense: null,
    phone: "",
    loginTheme: "",
  });
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const getCustomerDetailsApi = useGetApiRequests("createCustomer", "GET");
  const handleNavigate = () => {
    navigate("/dashboard");
  };
  const handleSubmit = async (values: any) => {
    console.log(values);
  };
  const [enableTest, setEnableTest] = useState<boolean>(true);
  const [currentTab, setCurrentTab] = useState<number>(1);
  const availableButton = false;
  const isEdit: boolean = true;
  const [enableTestButton, setEnableTestButton] = useState(isEdit);
  const [getCustomerDetails, setGetCustomerDetails] = useState<any>({
    generalDetails: {},
    emailDetails: {},
    ssoDetails: {},
    plansDetails: {},
  });
  const [generalDataChanges, setGeneralChanges] = useState({});
  const [emailDataChanges, setEmailDataChanges] = useState({});
  const [ssoDataChanges, setSsoDataChanges] = useState({});
  const customerGeneralData = useSelector((state: any) => state?.CustomerSlice?.getCustomerDetails?.generalDetails);
  const currentUserData = useSelector((state: any) => state?.ProfileDetailsSlice?.profileDetails);

  useEffect(() => {
    getDetails();
    console.log(currentUserData);
  }, []);
  const getDetails = async () => {
    setLoader(true);
    const pathParams: any = {
      name: currentUserData?.customerName,
    };
    try {
      const response: any = await getCustomerDetailsApi("", {}, pathParams);
      const status = response.status;
      const responseData = response?.data?.data;
      if (status === 200) {
        setLoader(false);
        setGetCustomerDetails({
          generalDetails: {
            customerId: responseData.customerGeneralDetailsDto?.customerId,
            displayName: responseData.customerGeneralDetailsDto?.displayName,
            customerEmailId: responseData.customerGeneralDetailsDto?.primaryEmailId,
            noOfLicense: responseData.customerGeneralDetailsDto?.noOfLicense,
            phone: responseData.customerGeneralDetailsDto?.primaryMobileNumber?.replace(/\D/g, ""),
            loginTheme: responseData.customerGeneralDetailsDto?.loginTheme,
          },
          emailDetails: {
            senderEmail: responseData.emailConfigurationDto?.fromEmailAddress,
            displaySenderEmail: responseData.emailConfigurationDto?.fromDisplayName,
            replyEmail: responseData.emailConfigurationDto?.replyToEmailAddress,
            displayReplyEmail: responseData.emailConfigurationDto?.replyToDisplayName,
          },
          ssoDetails: {
            ssoSessionIdleTimeout: responseData.sessionDto?.ssoSessionIdleTimeout / 60,
            ssoSessionMaxLifespan: responseData.sessionDto?.ssoSessionMaxLifespan / 60,
            ssoSessionIdleTimeoutRememberMe: responseData.sessionDto?.ssoSessionIdleTimeoutRememberMe / 60,
            ssoSessionMaxLifespanRememberMe: responseData.sessionDto?.ssoSessionMaxLifespanRememberMe / 60,
            loginTimeout: responseData.sessionDto?.loginTimeout / 60,
            loginActionTimeout: responseData.sessionDto?.loginActionTimeout / 60,
          },
          plansDetails: responseData.customerPlanPayloadDto,
        });
        setEmailDataChanges({
          senderEmail: responseData.emailConfigurationDto?.fromEmailAddress,
          displaySenderEmail: responseData.emailConfigurationDto?.fromDisplayName,
          replyEmail: responseData.emailConfigurationDto?.replyToEmailAddress,
          displayReplyEmail: responseData.emailConfigurationDto?.replyToDisplayName,
        });
        setSsoDataChanges({
          ssoSessionIdleTimeout: responseData.sessionDto?.ssoSessionIdleTimeout / 60,
          ssoSessionMaxLifespan: responseData.sessionDto?.ssoSessionMaxLifespan / 60,
          ssoSessionIdleTimeoutRememberMe: responseData.sessionDto?.ssoSessionIdleTimeoutRememberMe / 60,
          ssoSessionMaxLifespanRememberMe: responseData.sessionDto?.ssoSessionMaxLifespanRememberMe / 60,
          loginTimeout: responseData.sessionDto?.loginTimeout / 60,
          loginActionTimeout: responseData.sessionDto?.loginActionTimeout / 60,
        });
        setInitialValues({
          customerId: responseData.customerGeneralDetailsDto?.customerId,
          displayName: responseData.customerGeneralDetailsDto?.displayName,
          customerEmailId: responseData.customerGeneralDetailsDto?.primaryEmailId,
          noOfLicense: responseData.customerGeneralDetailsDto?.noOfLicense,
          phone: responseData.customerGeneralDetailsDto?.primaryMobileNumber?.replace(/\D/g, ""),
          loginTheme: responseData.customerGeneralDetailsDto?.loginTheme,
        });
        if (Object.keys(customerGeneralData).length === 0) {
          setGeneralChanges({
            customerId: responseData.customerGeneralDetailsDto?.customerId,
            displayName: responseData.customerGeneralDetailsDto?.displayName,
            customerEmailId: responseData.customerGeneralDetailsDto?.primaryEmailId,
            noOfLicense: responseData.customerGeneralDetailsDto?.noOfLicense,
            phone: responseData.customerGeneralDetailsDto?.primaryMobileNumber?.replace(/\D/g, ""),
            loginTheme: responseData.customerGeneralDetailsDto?.loginTheme,
          });
        }
      }
    } catch (err: any) {
      setLoader(false);

      handleRequestError(err);
    }
  };
  return (
    <div className="akku-container">
      {loader && <Loader />}
      <div className="main-container">
        <div className="dashboard-container  h-full rounded overflow-y-scroll edit-tenant">
          <ul className="flex breadcrumb">
            <li className="font-Inter pr-1 cursor-pointer" onClick={handleNavigate}>
              Smart Analytics /
            </li>
            <li className="active font-Inter"> Edit Details</li>
          </ul>
          <div className="w-full">
            <p className="title py-4">General Details</p>
            <Formik initialValues={initialValues} validationSchema={ValidationSchema} onSubmit={handleSubmit} enableReinitialize={true}>
              {({ values, setFieldValue, errors, setFieldTouched }) => {
                return (
                  <Form>
                    <div className="w-full">
                      <GeneralDetailsForm
                        setFieldTouched={setFieldTouched}
                        handleCloseModal={null}
                        isEdit={isEdit}
                        setFieldValue={setFieldValue}
                        enableTest={enableTest}
                        values={values}
                        setCurrentTab={setCurrentTab}
                        setEnableTest={setEnableTest}
                        availableButton={availableButton}
                        enableTestButton={enableTestButton}
                        setEnableTestButton={setEnableTestButton}
                        getCustomerDetails={getCustomerDetails}
                        setGetCustomerDetails={setGetCustomerDetails}
                        errors={errors}
                        active
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div className="w-full pt-10">
            <EditEmailConfiguration emailDataChanges={emailDataChanges} />
            <div className="w-full ">
              <EditSSOSettings generalDataChanges={generalDataChanges} ssoDataChanges={ssoDataChanges} />
            </div>
            <div className="w-full products-edit">
              <ProductsAndPlans active currentTab={currentTab} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTenentDetails;
