import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import MappingConnectorAttributes from "./MappingConnectorAttributes";
import { useDispatch, useSelector } from "react-redux";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import Loader from "../../../../../layouts/component/Loader";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../../../services/storage/Storage";
import "./ProvisionMapping.scss";
import { setActiveStep, setEndPointResponseDtos, setMappingAttributes, setMappingAttributesPayloadCreation } from "../../../../../redux/slice/provisioning/ProvisioningSlice";
import SuccessMessageModal from "../../../../access-manager/create/IpTimeSuccessModal";
import { useNavigate } from "react-router-dom";
const ProvisionMappingAttributes = () => {
  const formKey = 1;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const provisioning = useSelector((state: any) => state?.provisioning);
  const mappingAttributes = useSelector((state: any) => state?.provisioning?.mappingAttributes);
  const provisioningConnectorDetails = useGetApiRequests("provisioningConnectorDetails", "GET");
  const provisionSourceAttributes = useGetApiRequests("provisionSourceAttributes", "GET");
  const realmId = retrieveData("realmId", true);
  const [loader, setLoader] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(mappingAttributes);
  const [target, setTarget] = useState([]);
  const [source, setSource] = useState([]);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [apiHitCount, setApiHitCount] = useState<number>(0);
  const [noOfApiCalls, setNoOfApiCalls] = useState(); // do not set initial value as 0
  const [responseMessage, setResponseMessage] = useState("");
  const transformList: any = [];
  const validationSchema = Yup.object({
    forms: Yup.array().of(
      Yup.object({
        source: Yup.string().required("Please select the required value"),
        target: Yup.string().required("Please select the required value"),
      }),
    ),
  });

  const handleSubmit = async (values: any) => {
    const transformPayload = createPayloads(provisioning?.endPointResponseDtos, values?.forms);
    setNoOfApiCalls(transformPayload?.length);
    dispatch(setMappingAttributes(values));
    dispatch(setMappingAttributesPayloadCreation(transformPayload));
    dispatch(setActiveStep(8));
  };

  useEffect(() => {
    getProvisioningConnectorDetails();
    getProvisionSourceAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (apiHitCount === noOfApiCalls) {
      setOpenSuccessModal(true);
      setTimeout(() => {
        handleCloseSuccessModal();
        navigate("/app-store");
      }, 3000);
    }
  }, [apiHitCount]);

  const createPayloads = (provisioning: any, results: any) => {
    // Create a map for quick lookup of targets to sources
    try {
      const targetToSourceMap = results?.reduce((map: any, result: any) => {
        map[result.target] = result.source;
        return map;
      }, {});

      // Generate payloads based on the provisioning data
      return provisioning?.reduce((payloads: any, endpoint: any) => {
        // Combine attributeMappings and headerParameters into one object
        const combinedMappings = {
          ...endpoint.attributeMappings,
          ...endpoint.headerParameters,
        };

        // Create the attributesMap
        const attributesMap = Object.entries(combinedMappings).reduce((map: any, [target, value]) => {
          if (targetToSourceMap.hasOwnProperty(target)) {
            map[target] = targetToSourceMap[target];
          }
          return map;
        }, {});

        // If attributesMap is not empty, create a payload
        if (Object.keys(attributesMap).length > 0) {
          payloads.push({
            akkuProvisioningConnectorEndpointConfigId: endpoint.akkuProvisioningConnectorEndpointConfigId,
            attributesMap: attributesMap,
          });
        }

        return payloads;
      }, []);
    } catch (err) {
      console.log(err);
    }
  };

  const getProvisioningConnectorDetails = async () => {
    setLoader(true);
    const params = {
      akkuProvisioningConnectorId: provisioning?.akkuProvisioningConnectorId,
    };

    try {
      const response: any = await provisioningConnectorDetails("", "", params);
      const status = response?.status;
      if (status === 200) {
        const data = response?.data?.data;
        const attributeMappingKeys = extractKeys(data);
        const transformedData = processEndpointData(data);
        const mappingAttributesData = mapResponseToAttributes(data?.endPointResponseDtos);
        setFormInitialValues(mappingAttributesData);
        dispatch(setMappingAttributes(mappingAttributesData));
        dispatch(setEndPointResponseDtos(transformedData));
        setTarget(attributeMappingKeys);
        setLoader(false);
      }
      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const getProvisionSourceAttributes = async () => {
    setLoader(true);
    let params = {
      realmId: realmId,
    };
    try {
      const response: any = await provisionSourceAttributes("", params);
      const status = response?.status;
      if (status === 200) {
        const data = response?.data?.data;
        setLoader(false);
        setSource(data?.sourceAttributes);
      }
      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  // const extractAttributeMappingKeys = (endpoints: any) => endpoints.flatMap((endpoint: any) => Object.keys(endpoint.attributeMappings).filter((key) => key !== "products"));
  const mapResponseToAttributes = (response: any) => {
    const result: any = [];

    response?.forEach((config: any) => {
      // Process attributeMappings
      Object.entries(config?.attributeMappings || {})?.forEach(([target, source]: any) => {
        if (source?.trim()) {
          result?.push({
            source: source?.trim(),
            target,
            userType: "",
          });
        }
      });

      // Process headerParameters
      Object.entries(config?.headerParameters || {})?.forEach(([target, source]: any) => {
        if (source?.trim()) {
          result?.push({
            source: source?.trim(),
            target,
            userType: "",
          });
        }
      });
    });
    // Ensure at least one form is returned with default structure if the result is empty
    if (result?.length === 0) {
      result.push({ source: "", target: "", userType: "", id: "" });
    }

    return { forms: result };
  };
  const processEndpointData = (data: any) => {
    return (data?.endPointResponseDtos || [])?.map((endpoint: any) => ({
      akkuProvisioningConnectorEndpointConfigId: endpoint?.akkuProvisioningConnectorEndpointConfigId || "",
      endpointDescription: endpoint?.endpointDescription || "",
      attributeMappings: endpoint?.attributeMappings || {},
      headerParameters: endpoint?.headerParameters || {},
    }));
  };

  const extractKeys = (data: any) => {
    const result: any = [];

    data.endPointResponseDtos.forEach((endpoint: any) => {
      // Extract keys from attributeMappings, excluding 'products' if name is jira
      const attributeKeys = Object.keys(endpoint.attributeMappings || {}).filter((key) => !(data.name.toLowerCase() === "jira" && key === "products"));

      // Extract keys from headerParameters
      const headerKeys = Object.keys(endpoint.headerParameters || {});

      result.push(...attributeKeys, ...headerKeys);
    });

    return result;
  };

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };
  return (
    <>
      <div className="w-full px-10 pb-5 mapping-attributes">
        <p className="app-header pt-8">Mapping - Attributes {provisioning?.isDuplicate ? "(Copy)" : null}</p>
      </div>
      <div className="w-full mapping-attributes">
        <Formik initialValues={formInitialValues} formKey={formKey} onSubmit={handleSubmit} validationSchema={validationSchema} enableReinitialize>
          {({ values, setFieldValue }) => (
            <Form>
              <MappingConnectorAttributes
                values={values}
                setFieldValue={setFieldValue}
                transformList={transformList}
                setFormInitial={setFormInitialValues}
                target={target}
                source={source}
                handleSubmit={handleSubmit}
              />
            </Form>
          )}
        </Formik>
      </div>
      {loader && <Loader />}
      {openSuccessModal && <SuccessMessageModal open={openSuccessModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
    </>
  );
};

export default ProvisionMappingAttributes;
