import React from "react";
import { Select, Table } from "antd";
const { Option } = Select;

const SelectIPAssignTo = (props: any) => {
  const {
    type,
    setDropdownVisible,
    dropdownVisible,
    handleChangeGroups,
    filterOption,
    dropdownRender,
    userSearchGroupData,
    selectedValues,
    handleUserLabelClick,
    usersSearchList,
    groupData,
    listOfUsers,
    selectedRowKeys,
    rowSelection,
    groupColumnsIp,
    unassignUser,
    columnsIp,
    handleChangeUsers,
  } = props.props;
  return (
    <div className="assignedTable-container">
      <h3>Assigned to </h3>
      <div className="table-search">
        <div className="w-[35%]">
          {type === "groups" ? (
            <Select
              className="h-14 w-full mb-5 custom-dropdown"
              mode="tags"
              placeholder={"Search"}
              onDropdownVisibleChange={(visible) => setDropdownVisible(visible)}
              open={dropdownVisible}
              tokenSeparators={[","]}
              optionLabelProp="label"
              onChange={handleChangeGroups}
              filterOption={filterOption}
              dropdownRender={dropdownRender}
              data-testid="select-userInput"
            >
              {userSearchGroupData?.map((option: any) => (
                <Option key={option?.value} value={option?.value} label={option?.name}>
                  <input
                    type="checkbox"
                    className="dropdown-list w-5"
                    name={option?.name}
                    id={option?.value}
                    checked={selectedValues.includes(option?.value)}
                    onChange={(e) => handleUserLabelClick(e, option?.value)}
                  />
                  <label htmlFor={option?.value} className="flex flex-col h-14 pointer-events-none justify-center">
                    {option?.name} <span className="absolute top-[32px] text-[#747577]">{option?.description}</span>
                  </label>
                </Option>
              ))}
            </Select>
          ) : (
            <Select
              className="h-14 w-full mb-5 custom-dropdown"
              mode="tags"
              placeholder={"Search"}
              onDropdownVisibleChange={(visible) => setDropdownVisible(visible)}
              open={dropdownVisible}
              tokenSeparators={[","]}
              optionLabelProp="label"
              onChange={handleChangeUsers}
              filterOption={filterOption}
              dropdownRender={dropdownRender}
            >
              {usersSearchList?.map((option: any) => (
                <Option key={option?.value} value={option?.value} label={option?.name}>
                  <input
                    type="checkbox"
                    className="dropdown-list w-5"
                    name={option?.name}
                    id={option?.value}
                    checked={selectedValues.includes(option?.value)}
                    onChange={(e) => handleUserLabelClick(e, option?.value)}
                  />
                  <label htmlFor={option?.name} className="flex flex-col h-14 pointer-events-none justify-center">
                    {option?.name} <span className="absolute top-[32px] text-[#747577]">{option?.email}</span>
                  </label>
                </Option>
              ))}
            </Select>
          )}
        </div>
      </div>
      {type !== "organization" && (
        <div className="assignedTable">
          <div className="flex device-restriction">
            <p className="assignedTable-totalGroup">{`Total No. of ${type} ${type === "groups" ? groupData?.length : listOfUsers?.length} `}</p>
            {selectedRowKeys && selectedRowKeys.length > 0 && (
              <p role="none" className={`ml-[55px] users-clear `} onClick={unassignUser}>
                {type === "groups" ? "Unassign Group(s)" : "Unassign user(s)"}
              </p>
            )}
          </div>
          <Table rowSelection={rowSelection} columns={type === "groups" ? groupColumnsIp : columnsIp} dataSource={type === "groups" ? groupData : listOfUsers} pagination={false} />
        </div>
      )}
    </div>
  );
};

export default SelectIPAssignTo;
