import React, { useEffect, useState } from "react";
import { Select, Table } from "antd";
import { prerequisitesTable } from "../../../../../constant/prerequisites/prerequisites";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../../../layouts/component/CustomButton";
import SendAuthorizeMailModal from "./modal/SendAuthorizeMailModal";
import DeviceRestrictionTableHeader from "./DeviceRestrictionTableHeader";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import { useNavigate } from "react-router-dom";
import { deviceRestrictionColumnsUpdate } from "./DeviceRestrictionTableColumns";
import AccessManagerBreadcrumbs from "./BreadCrumbsAccessManager";
import { retrieveData } from "../../../../../services/storage/Storage";
import UsersSearchDropdown from "../groups/UserSearchModal";
import ReturnToHome from "../../../../access-manager/create/ReturnToHome";
import "./DeviceBasedRestriction.scss";
const UpdateDeviceBasedRestriction = () => {
  const deviceBasedForSingleUser = useGetApiRequests("deviceBasedRestrictionForSingleUser", "POST");
  const getDeviceRestrictionList = useGetApiRequests("getAccessManagerDetails", "GET");
  const getUserListForDevice = useGetApiRequests("userManagement", "POST");
  const deviceBasedForMultipleUser = useGetApiRequests("deviceBasedMultipleUser", "POST");
  const [userListDeviceRestriction, setUserListDeviceRestriction] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRowDevice, setSelectedRowDevice] = useState<any>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState<any>([]);
  const [searchUserList, setSearchUserList] = useState<any>([]);
  const [selectedObjectList, setSelectedObjectList] = useState<any>([]);
  const [usersAddedForDevice, setUsersAddedForDevice] = useState<any>([]);
  const [unChangeAbleUserList, setUnChangeAbleUserList] = useState<any>([]);
  const [updatedUserList, setUpdatedUserList] = useState<any>([]);
  const [defaultSize, setDefaultSize] = useState<any>();
  const { Option } = Select;

  const navigate = useNavigate();
  const realmId = retrieveData("realmId", true);

  useEffect(() => {
    getDeviceRestrictionUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDeviceRestrictionUsersList = async () => {
    setLoader(true);
    const queryParams = {
      realmId: realmId,
    };
    try {
      const response: any = await getDeviceRestrictionList("", queryParams);
      if (response.status === 200) {
        const mappedUerList = response?.data?.data?.accessManagerDeviceRestrictionKvDto?.userDtoList;
        const reformedUserList = mappedUerList?.map((item: any) => ({
          key: item?.id,
          status: item?.userDeviceInfoStatus,
        }));

        const unMappedUerList = response?.data?.data?.accessManagerDeviceRestrictionKvDto?.unMappedUserDtoList;
        const newUnMappedList = unMappedUerList?.map((item: any) => ({
          key: item?.id,
          name: item?.username,
          email: item?.email,
          id: item?.id,
        }));

        const payload = {
          realmId: realmId,
          sorting: "name_asc",
          statusFilter: "active,inactive",
          searchFilter: "",
          startValue: 0,
          limitValue: retrieveData("totalUserRecords", true),
        };
        try {
          const response: any = await getUserListForDevice(payload);
          const status = response.status;

          if (status === 200) {
            const responseData = response?.data;
            const userListFromApi = responseData?.data?.map((item: any) => ({
              name: item?.userData?.name,
              id: item?.userData?.userId,
              email: item?.userData?.email,
              key: item?.userData?.userId,
            }));
            const statusLookup = reformedUserList?.reduce((acc: any, item: any) => {
              acc[item.key] = item.status;
              return acc;
            }, {});
            const getStatusText = (key: string, statusLookup: Record<string, string>): string => {
              if (statusLookup[key] === "agentsent") {
                return "agent Sent";
              } else {
                return statusLookup[key];
              }
            };
            const reformedListKeys = reformedUserList?.map((item: any) => item.key);
            const finalUserList = userListFromApi
              .filter((item: any) => reformedListKeys.includes(item.key))
              .map((item: any) => ({
                ...item,
                status: getStatusText(item.key, statusLookup),
              }));
            if (finalUserList?.length === 0) {
              navigate("/access-manager");
            }
            setUserListDeviceRestriction(finalUserList);
            const defaultPageSize = finalUserList?.length <= 5 ? 5 : 10;
            setDefaultSize(defaultPageSize);
            setUnChangeAbleUserList(finalUserList);

            const userListOfKeys = userListFromApi?.map((item: any) => item.key);
            const searchBarList = newUnMappedList?.filter((item: any) => userListOfKeys?.includes(item.key));
            setSearchUserList(searchBarList);
            setLoader(false);
          }
        } catch (err: any) {
          setLoader(false);
          handleRequestError(err);
        }
      }
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };
  const handleCloseModal = () => {
    setSelectedRowKeys([]);
    setSelectedRowDevice([]);
    setOpenModal(false);
    handleCancel();
  };

  const onRowSelect = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRowDevice(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onRowSelect,
  };

  const handleAuthorizeApi = () => {
    if (usersAddedForDevice.length === 1) {
      setLoader(true);
      const payload: object = {
        userId: usersAddedForDevice?.[0],
      };
      deviceBasedForSingleUser(payload)
        .then((response: any) => {
          setLoader(false);
          setOpenModal(true);
        })
        .catch((err: any) => {
          setLoader(false);
          setOpenModal(false);
          handleRequestError(err);
        });
    } else if (usersAddedForDevice.length > 1) {
      setLoader(true);
      const commaSeparatedString: string = usersAddedForDevice?.join(",");
      const payload: object = {
        userId: commaSeparatedString,
      };
      deviceBasedForMultipleUser(payload)
        .then((response: any) => {
          setLoader(false);
          setOpenModal(true);
        })
        .catch((err: any) => {
          setLoader(false);
          setOpenModal(false);
          handleRequestError(err);
        });
    }
  };
  const handleCancel = () => {
    navigate("/access-manager");
  };
  const handleAddRemoveUsers = () => {
    if (selectedRecords.length > 0) {
      const selectedObjects = searchUserList.filter((item: any) => selectedRecords.includes(item.key));
      setUsersAddedForDevice(selectedRecords);
      setSelectedObjectList(selectedObjects);
      const mergedArray = [...updatedUserList, ...selectedObjects];
      const uniqueMergedArray = Array.from(new Set(mergedArray.map((obj) => obj.key))).map((value) => {
        return mergedArray.find((obj) => obj.key === value);
      });
      setUserListDeviceRestriction(uniqueMergedArray);
      const pageSize = uniqueMergedArray?.length <= 5 ? 5 : 10;
      setDefaultSize(pageSize);
      setDropdownOpen(false);
    } else {
      setUsersAddedForDevice(selectedRecords);
      setDropdownOpen(false);
      setUserListDeviceRestriction(unChangeAbleUserList);
      const pageSize = unChangeAbleUserList?.length <= 5 ? 5 : 10;
      setDefaultSize(pageSize);
    }
  };
  const dropdownSearchRender = (list: any) => {
    return (
      <div>
        <div>{list}</div>
        <UsersSearchDropdown handleAddApps={handleAddRemoveUsers} setDropdownVisible={setDropdownOpen} />
      </div>
    );
  };
  const userFilterOptions = (input: string, option?: { label: string; value: string }) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleUserLabelClick = (event: any, value: any) => {
    event.stopPropagation();
    const newValue: any = [...selectedRecords];
    if (newValue.includes(value)) {
      newValue.splice(newValue.indexOf(value), 1);
    } else {
      newValue.push(value);
    }
    setSelectedRecords(newValue);
  };
  const handleChangeUsers = (value: string) => {
    setSelectedRecords(value);
    const previousValue = [...selectedObjectList];
    const missingIds = previousValue?.filter((item) => !value.includes(item.key)).map((item) => item.key);
    const listOfUsersDetails = [...userListDeviceRestriction];
    const needToDelete = listOfUsersDetails.filter((item) => !missingIds.includes(item.key));
    setUpdatedUserList(needToDelete);
  };
  return (
    <div className="akku-container">
      <div className="main-container" data-testid="containerId">
        <div className="rounded device-restriction dashboard-container device-restriction-table update h-full">
          <AccessManagerBreadcrumbs isEdit={true} />
          <div className={`mb-2 pt-1 "selectIpEdit-title`}>
            <ReturnToHome title={"Edit - Device Restriction"} />
          </div>
          <div className="pt-3 w-[35%]">
            <Select
              className="h-14 w-full mb-5 custom-dropdown"
              mode="tags"
              placeholder={"Search"}
              onDropdownVisibleChange={(visible) => setDropdownOpen(visible)}
              open={dropdownOpen}
              tokenSeparators={[","]}
              optionLabelProp="label"
              onChange={handleChangeUsers}
              filterOption={userFilterOptions}
              dropdownRender={dropdownSearchRender}
            >
              {searchUserList?.map((option: any) => (
                <Option key={option?.id} value={option?.id} label={option?.name}>
                  <input
                    type="checkbox"
                    className="w-5 dropdown-list"
                    name={option?.name}
                    id={option?.id}
                    checked={selectedRecords.includes(option?.id)}
                    onChange={(e) => handleUserLabelClick(e, option?.id)}
                  />
                  <label htmlFor={option?.name} className="pointer-events-none justify-center flex flex-col h-14">
                    {option?.name} <span className="top-[32px] text-[#747577] absolute">{option?.email}</span>
                  </label>
                </Option>
              ))}
            </Select>
          </div>
          <div className=" w-full">
            <div className="w-full">
              <DeviceRestrictionTableHeader
                fromAccessManager={true}
                userList={userListDeviceRestriction}
                selectedRows={selectedRowDevice}
                setSelectedRow={setSelectedRowDevice}
                setSelectedRowKeys={setSelectedRowKeys}
                getDeviceRestrictionUsersList={getDeviceRestrictionUsersList}
                updateDeviceBasedRestriction={true}
              />
            </div>
            <Table
              pagination={{
                showSizeChanger: true,
                pageSizeOptions: prerequisitesTable.pageSizeOptions,
                pageSize: defaultSize,
                onShowSizeChange: (current, size) => setDefaultSize(size),
              }}
              rowSelection={rowSelection}
              columns={deviceRestrictionColumnsUpdate}
              dataSource={userListDeviceRestriction}
              loading={loader}
            />
            <div className="pt-10 w-full flex justify-end">
              <CustomButtonBack text={"Cancel"} onClick={handleCancel} />
              <CustomButtonNext text={"Authorize"} onClick={handleAuthorizeApi} active={usersAddedForDevice.length === 0} />
            </div>
          </div>
        </div>
      </div>
      {openModal && <SendAuthorizeMailModal openModal={openModal} handleCloseModal={handleCloseModal} />}
    </div>
  );
};

export default UpdateDeviceBasedRestriction;
