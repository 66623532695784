import React, { useState } from "react";
import SyncButton from "../../../../../../layouts/component/SyncButton";
import AssignNewUsers from "../device-authorization/AssignNewUsers";
import { useSelector } from "react-redux";
import { triggerNotification } from "../../../../../../layouts/toast/ToastBar";
import { ErrorMessages } from "../../../../../../const/Messages";
import { useNavigate } from "react-router-dom";
import viewImage from "../../../../../../assets/images/icons/groupView.svg";
import ImageView from "../../../../../../layouts/component/ImageView";
import PermissionsModal from "../../../../../../layouts/permissionsModal/PermissionsModal";
import PolicyAndDeviceLevel from "../device-authorization/PolicyLevelModal";

const UserTableHeader = (props: any) => {
  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const userList: any = useSelector((state: any) => state?.DeviceRestrictionSlice?.deviceRestrictionList);
  const hasUpdatePermission = permissions?.["Access Manager"]?.create;

  const navigate = useNavigate();

  const { selectedRowLength, totalRecords, clearSelection, handleSync, loadings } = props;
  const [deviceAuth, setDeviceAuth] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [permissionsModal, setPermissionsModal] = useState<boolean>(false);

  const handleOpenHelpModal = () => {
    setPermissionsModal(true);
  };
  const handleCloseHelpModal = () => {
    setPermissionsModal(false);
  };
  const handleAuthorization = () => {
    if (hasUpdatePermission) {
      if (userList.length > 0) {
        setDeviceAuth(true);
      } else {
        triggerNotification("warning", "", ErrorMessages?.deviceRestriction, "topRight");
      }
    } else {
      handleOpenHelpModal();
    }
  };

  const handelClose = () => {
    setDeviceAuth(false);
    clearSelection();
  };
  const handelCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div className={`${selectedRowLength === 0 ? "w-1/2" : "w-[20%]"} md:w-[100%]`}>
      {selectedRowLength === 0 ? (
        <div className="flex">
          <p className="total-user w-[50%] lg:w-[20%]">
            Total User Count : <span> {totalRecords}</span>
          </p>
          <p
            role="none"
            className="total-count w-[50%] lg:w-[20%] xl:pl-5 !pl-4"
            onClick={() => {
              navigate("/user/groups");
            }}
          >
            <span className="cursor-pointer text-[14px]"> Change to group view</span>
            <div className="inline relative">
              <ImageView src={viewImage} alt={"group_view"} className="pl-2 cursor-pointer" />
            </div>
          </p>
        </div>
      ) : (
        <div className="w-full flex ">
          <div className=" flex w-full justify-between">
            <div className="w-full flex justify-between">
              <p className="total-user">{selectedRowLength === 1 ? `${selectedRowLength} user selected` : `${selectedRowLength} users selected`}</p>
              <p role="none" className="text-[14px] font-Inter text-[#5441DA] ml-3 cursor-pointer users-clear" onClick={clearSelection}>
                Clear selection
              </p>
            </div>
            <div className="w-[70%]  justify-end hidden">
              <div className="flex items-center justify-start ml-10 px-5  border-r-2">
                <p className="user-sync flex">
                  Device Authorization
                  <span>{<SyncButton disabled={hasUpdatePermission} icon="description" loadings={null} onClick={() => handleAuthorization()} title=" Device Authorization" />}</span>
                </p>
              </div>
              {/* <div className="flex items-center px-5 border-r-2 ">
                <p className="user-sync flex">
                  Assign to group
                  <span>{<SyncButton disabled={hasUpdatePermission} icon="group" loadings={null} onClick={() => handleAssignGroup()} title="Sync" />}</span>
                </p>
              </div> */}
              <div className="flex items-center px-5 border-r-2  ">
                <p className="user-sync flex">
                  Sync all
                  <span>
                    {<SyncButton disabled={hasUpdatePermission} icon="sync" loadings={loadings} onClick={hasUpdatePermission ? () => handleSync() : () => setPermissionsModal(true)} title="Sync" />}
                  </span>
                </p>
              </div>
            </div>
          </div>
          {permissionsModal && <PermissionsModal open={permissionsModal} close={handleCloseHelpModal} />}
          {deviceAuth && <PolicyAndDeviceLevel deviceAuth={deviceAuth} handelClose={handelClose} />}
          {openModal && <AssignNewUsers openModal={openModal} handelCloseModal={handelCloseModal} />}
        </div>
      )}
    </div>
  );
};

export default UserTableHeader;
