import React from "react";
import "./DirectoryBreadcrumb.scss";
const DirectoryBreadcrumb = (props: any) => {
  const { onClick } = props;
  return (
    <ul className="flex ldab-breadcrumb">
      <li role="none" onClick={onClick} className="cursor-pointer">
        Connected Directory <span className="pr-1">/</span>
      </li>
      <li className="active"> Management Directory</li>
    </ul>
  );
};

export default DirectoryBreadcrumb;
