import React, { useEffect, useState } from "react";
import AppConfigureCard from "../../card/AppConfigureCard";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import RadioGroup from "./RadioGroup";
import GeneralSettingForm from "../GeneralSettingForm";
import CapabilityConfigurationOpenId from "./CapabilityConfigurationOpenId";
import OpenIdLogInSetting from "./OpenIdLogInSetting";
import CapabilityConfigurationForm from "../CapabilityConfigurationForm";
import { openIdSchema, samlSchema } from "./schema/createappSchema"; // Adjust the import path as necessary
import { authenticate, openIdInitialValue, samlInitialValue, appCommonDetails, initialApiData, authenticateApiData } from "../helper/createAppHelper";
import { retrieveData } from "../../../../../services/storage/Storage";
import { triggerNotification } from "../../../../../layouts/toast/ToastBar";
import { Formik, Form } from "formik";
import { RadioChangeEvent } from "antd";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../../layouts/component/Loader";
import AdvanceSetting from "../advance-setting/AdvanceSetting";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import SubmitButton from "../../../../../layouts/component/SubmitButton";
import { useSelector } from "react-redux";
import { ErrorMessages } from "../../../../../const/Messages";
import "./CreateAppForm.scss";
const CreateAppForm = () => {
  const createAppConfiguration = useGetApiRequests("createAppConfiguration", "POST");
  const editConfiguration = useGetApiRequests("editConfiguration", "PUT");
  const editAppConfiguration = useGetApiRequests("editAppConfiguration", "GET");
  const [value, setValue] = useState("saml");
  const [validationSchema, setValidationSchema] = useState(samlSchema);
  const [formKey, setFormKey] = useState(0);
  const [authentication, setAuthenticate] = useState(authenticate);
  const [imageData, setImageData] = useState<any>(new FormData());
  const [appCredential, setAppCredential] = useState<any>();
  const [masterClientId, setAppClientId] = useState<any>();
  const [loader, setLoader] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<any>();
  const [isClientAuthentication, setClientAuthentication] = useState<boolean>(false);
  const [commonDetails, setCommonDetails] = useState<any>(appCommonDetails);
  const [initialValues, setInitialValues] = useState<any>(samlInitialValue);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const navigate = useNavigate();
  const configureApp = retrieveData("configureApp", true);
  const akkuCustometId = retrieveData("akkuCustometId", true);
  const appDetails = useSelector((state: any) => state?.AppDetailsSlice?.appDetails);
  const [description, setDescription] = useState("");
  const [appName, setAppName] = useState("");
  const [logoUrlFromAPi, setLogoUrlFromAPi] = useState("");
  useEffect(() => {
    setIsEdit(configureApp);
    if (configureApp === "configure") {
      setAppClientId(appDetails?.akkuMasterClientId);
      setCommonDetails((prevDetails: any) => ({
        ...prevDetails,
        description: appDetails.description,
        name: appDetails.name,
        delegatedUser: appDetails.delegatedUser,
      }));
    } else if (configureApp === "edit") {
      getEditAppDetails();
    }
  }, []);
  const getEditAppDetails = () => {
    setLoader(true);
    const akkuClientId = appDetails?.akkuClientId;
    setAppClientId(appDetails?.akkuMasterClient?.akkuMasterClientId);
    editAppConfiguration("", "", [akkuClientId])
      .then((response: any) => {
        const responseData = response?.data?.data;
        setResponseData(responseData.id);
        setLogoUrlFromAPi(responseData?.logoUrl);
        setAppCredential(responseData?.credsJson);
        setLoader(false);
        setValue(responseData.protocol === "openid-connect" ? "openid-connect" : "saml");
        const isProtocolOpenId = responseData.protocol === "openid-connect";
        const protocolKey = isProtocolOpenId ? "openId" : "saml";
        const initialData = initialApiData(responseData);
        const openIdInitialValue = { [protocolKey]: [initialData] };
        const authenticate: any = isProtocolOpenId ? authenticateApiData(responseData) : {};
        const commonDetails = {
          description: responseData.description ?? "",
          name: responseData.name ?? "",
          protocol: responseData.protocol ?? "",
        };
        setInitialValues(openIdInitialValue);
        if (isProtocolOpenId) {
          setAuthenticate(authenticate);
        }
        setCommonDetails(commonDetails);
        setFormKey((prevKey) => prevKey + 1);
      })
      .catch((err) => {
        handleRequestError(err);
        setLoader(false);
      });
  };

  const onChange = (e: RadioChangeEvent) => {
    const newProtocol = e.target.value;
    setValue(newProtocol);
    setCommonDetails((prevDetails: any) => ({
      ...prevDetails,
      protocol: newProtocol,
    }));
    setInitialValues(newProtocol === "openid-connect" ? openIdInitialValue : samlInitialValue);
    setValidationSchema(newProtocol === "openid-connect" ? openIdSchema : samlSchema);
    setFormKey((prevKey) => prevKey + 1);
  };
  // handel submit form
  const handleSubmit = async (values: any) => {
    if (!appName || appName === "" || !description || description === "") {
      triggerNotification("error", "", ErrorMessages?.appNameEmpty, "topRight");
    } else if (appName && appName.length > 30) {
      triggerNotification("error", "", ErrorMessages?.appNameTooLong, "topRight");
    } else {
      setLoader(true);
      let params = { akkuMasterClientId: masterClientId, customerId: akkuCustometId, logo: imageData, logoName: imageData.name };
      let payload: any =
        value === "saml"
          ? values.saml[0]
          : {
              ...values.openId[0],
              ...authentication,
              clientAuthentication: isClientAuthentication,
            };
      let payloadData = { ...payload, ...commonDetails };
      const headers = { "Content-Type": "multipart/form-data" };
      // Determine the function to call based on `configureApp`
      const apiCall = configureApp === "configure" ? createAppConfiguration : editConfiguration;
      if (configureApp === "edit") {
        payloadData.id = responseData;
        payloadData.name = appName;
        payloadData.description = description;
      }
      const akkuClientRequestResponseDtoStr = JSON.stringify(payloadData);
      const requestDto = {
        akkuClientRequestResponseDtoStr: akkuClientRequestResponseDtoStr,
      };
      try {
        const response: any = await apiCall({ ...params, ...requestDto }, {}, {}, headers);
        if (response.status === 200) {
          triggerNotification("success", "", response?.data?.message, "topRight");
          navigate("/app-store");
        }
      } catch (err: any) {
        handleRequestError(err);
        setLoader(false);
      }
    }
  };
  const handleError = () => {
    setIsError(true);
  };
  return (
    <>
      {loader && <Loader />}
      <div>
        {/* <div className="hidden">
          {" "} */}
        <Formik
          key={formKey}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            await new Promise((r) => setTimeout(r, 500));
            handleSubmit(values);
          }}
        >
          {({ values, errors, dirty, isValid }) => (
            <>
              {!isValid ? handleError() : undefined}

              <Form>
                <div className="flex w-full flex-col app-create">
                  <div className="w-full flex flex-wrap">
                    <div className="lg:w-[100%] xl:w-[40%] md:w-[100%] pt-8">
                      <AppConfigureCard
                        value={value}
                        responseData={responseData}
                        setInitialValues={setInitialValues}
                        setAppName={setAppName}
                        appName={appName}
                        setDescription={setDescription}
                        description={description}
                        setImageDataFinal={setImageData}
                        logoUrlFromAPi={logoUrlFromAPi}
                      />
                    </div>
                    <div className="lg:w-[100%] xl:w-[60%] md:w-[100%] pl-5 pb-10 xl:pt-0 pt-10">
                      <RadioGroup value={value} onChange={onChange} />
                      <div className="flex w-full flex-col">
                        <GeneralSettingForm isError={isError} values={values} setCommonDetails={setCommonDetails} value={value} setImageData={setImageData} appCredential={appCredential} />
                      </div>
                      {value === "saml" ? (
                        <>
                          <CapabilityConfigurationForm isError={isError} values={values} imageData={imageData} /> <AdvanceSetting values={values} isError={isError} />
                        </>
                      ) : (
                        <>
                          <CapabilityConfigurationOpenId isError={isError} authentication={authentication} setAuthenticate={setAuthenticate} setClientAuthentication={setClientAuthentication} />
                          <OpenIdLogInSetting isError={isError} values={values} initialValues={initialValues} setInitialValues={setInitialValues} />{" "}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex justify-end w-full relative bottom-[0rem]  py-6  app-configure-footer">
                  <CustomButtonBack onClick={() => navigate("/app-store")} text={"Cancel"} /> <SubmitButton text={isEdit ? "Save" : "Update"} disable={loader} />
                </div>
              </Form>
            </>
          )}
        </Formik>
        {/* </div> */}
        {/* <AdminCredentials /> */}
      </div>
    </>
  );
};
export default CreateAppForm;
