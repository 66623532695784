import { useSelector } from "react-redux";

const FinalReviewUserDataAccordionAttributes = () => {
  const mappingAttributes = useSelector((state: any) => state?.provisioning.mappingAttributes);
  const provisioning = useSelector((state: any) => state?.provisioning);

  return (
    <div className="flex w-full mx-auto flex-wrap review px-5 ">
      <div className="w-full flex pt-8 pb-4 justify-evenly gap-x-10">
        <div className="w-[30%] flex text-[20px] ">
          <p className="content-header">SOURCE - Akku</p>
        </div>
        <div className="w-[40%]">
          <p className="content-header capitalize">TARGET-{provisioning?.generalDetails?.name}</p>
        </div>
      </div>
      <div className="w-full modal-list ">
        {mappingAttributes?.forms?.length > 0 &&
          mappingAttributes?.forms.map((elm: any) => (
            <div className="w-full flex border-b-2 2xl:py-3  py-2 justify-evenly gap-x-10  text-[#1D1D24]" key={elm.source}>
              <div className="w-[30%]  ">
                <p className="content-description text-[20px] ">{elm.source}</p>
              </div>
              <div className="w-[40%]">
                <p className="content-description text-[20px] ">{elm.target}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default FinalReviewUserDataAccordionAttributes;
