import React from "react";
import { Button, Input, Modal, Select, Tooltip } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ReactComponent as GreenCheckIcon } from "./../../../../../assets/images/icons/greencheck.svg";
import trashcan from "./../../../../../assets/images/icons/trashcan.svg";
import * as Yup from "yup";
import { validationErrorMessages } from "../../../../../constant/validationMessage";
import Loader from "../../../../../layouts/component/Loader";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import PhoneInput from "react-phone-input-2";
import GroupAppList from "./GroupAppList";
import ConnectedApplicationEdit from "../../user-dashboard/groups/layout/ConnectedApplicationEdit";
import { images } from "../../../../../assets/images/ImagePaths";
import ImageView from "../../../../../layouts/component/ImageView";
import CustomButtonNext from "../../../../../layouts/component/CustomButton";
import UserCreationNotificationModal from "../../user-dashboard/add-user/layouts/single-upload/directory/UserCreationNotificationModal";
import { ErrorMessages } from "../../../../../const/Messages";
import CustomFieldBasic from "./CustomFieldBasic";
import CustomAttributesField from "./CustomAttributesField";
import { useSelector } from "react-redux";
const UserManagementForm = (props: any) => {
  const customerDetailsDefaultPassword = useSelector((state: any) => state?.userBasicDetails?.customerDetailsDefaultPassword);
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(validationErrorMessages.firstName.required)
      .matches(/^[a-zA-Z\s]*$/, validationErrorMessages.firstName.letterAndSpace)
      .min(4, validationErrorMessages.firstName.minLength)
      .max(16, validationErrorMessages.firstName.maxLength),
    lastName: Yup.string()
      .required(validationErrorMessages.lastName.required)
      .matches(/^[a-zA-Z\s]*$/, validationErrorMessages.lastName.letterAndSpace)
      .min(1, validationErrorMessages.lastName.minLength)
      .max(16, validationErrorMessages.lastName.maxLength),
    phone: customerDetailsDefaultPassword
      ? Yup.string().min(8, "Mobile Number must be at least 8 characters").max(13, "Mobile Number cannot exceed 13 characters")
      : Yup.string().required("Mobile Number is required").min(8, "Mobile Number must be at least 8 characters").max(13, "Mobile Number cannot exceed 13 characters"),
    personalEmail: customerDetailsDefaultPassword ? Yup.string().email("Invalid email") : Yup.string().email("Invalid email").required("Email is required"),
    workEmail: Yup.string().email("Invalid email").required("Email is required"),
    customField: Yup.array().of(
      Yup.object().shape({
        value: Yup.string()
          .test("is-not-first", "Please fill this field", function (value, context: any) {
            const index = context?.options?.index;
            if (index === 0) return true;
            return value !== undefined && value !== "";
          })
          .min(3, "Please enter at least 3 characters"),
        name: Yup.string()
          .required("This field is required")
          .min(3, "Please enter at least 3 characters")
          .test("unique-name", "Field already exists", function (value: any) {
            const { path, options }: any = this;
            const fields: any = options.context.customField;
            const currentFieldIndex = parseInt(path.match(/\d+/)[0]);
            const isDuplicate = fields?.filter((field: any, index: any) => index !== currentFieldIndex).some((field: any) => field.name === value);

            return !isDuplicate;
          }),
      }),
    ),
    customField2: Yup.array().of(
      Yup.object().shape({
        value: Yup.string()
          .test("is-not-first", "Please fill this field", function (value, context: any) {
            const index = context?.options?.index;
            if (index === 0) return true;
            return value !== undefined && value !== "";
          })
          .min(3, "Please enter at least 3 characters"),
        name: Yup.string()
          .required("This field is required")
          .min(3, "Please enter at least 3 characters")
          .test("unique-name", "Field already exists", function (value: any) {
            const { path, options }: any = this;
            const fields: any = options.context.customField2;
            const currentFieldIndex = parseInt(path.match(/customField2\[(\d+)\]\.name/)[1]);
            const isDuplicate = fields?.filter((field: any, index: any) => index !== currentFieldIndex).some((field: any) => field.name === value);

            return !isDuplicate;
          }),
      }),
    ),
  });

  const {
    initialValues,
    onSubmit,
    isFormDirty,
    setFormDirty,
    disableFields,
    isEdit,
    roleSelected,
    onChange,
    userTypeList,
    selectedRowObject,
    deleteRestriction,
    handleOnChangePhone,
    userDetails,
    setListOfApps,
    listOfApps,
    individualApps,
    tempListApps,
    setCheckedState,
    checkedState,
    isActiveDeviceRestriction,
    handleToBack,
    loader,
    handleModalClose,
    confirmDeleteRestriction,
    modalOpen,
    setModalOpen,
    restrictionList,
    setUserDetails,
    deleteModal,
  } = props.props;

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} values={initialValues} onSubmit={onSubmit} enableReinitialize={true} isInitialValid={isFormDirty}>
      {({ handleSubmit, errors, setFieldValue, dirty, values }) => (
        <Form
          onSubmit={(e) => {
            setFormDirty(true);
            handleSubmit(e);
          }}
        >
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 mb-6">
            <div className="mb-2 relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">First Name*</p>
              <Field as={Input} disabled={disableFields} type="text" className="h-[56px] font-medium text-[18px]" name="firstName" maxLength={16} data-testid="fnameId" />
              <ErrorMessage name="firstName" component="div" className="error-message" />
            </div>
            <div className="mb-2 relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Last Name*</p>
              <Field as={Input} disabled={disableFields} type="text" className="h-[56px] font-medium text-[18px]" name="lastName" maxLength={16} data-testid="lnameId" />
              <ErrorMessage name="lastName" component="div" className="error-message" />
            </div>
            <div className="mb-2 relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Work Email ID *</p>
              <Field as={Input} disabled={disableFields} type="email" readOnly={isEdit || ""} className={`${isEdit ? "cursor-not-allowed" : ""} h-[56px] font-medium text-[18px]`} name="workEmail" />
              <ErrorMessage name="workEmail" component="div" className="error-message" />
            </div>
            <div className="mb-2">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium" data-testid="UTypeId">
                User Type*
              </p>
              <Field
                disabled={disableFields}
                as={Select}
                suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                className=" w-[100%] h-[56px]"
                name="userType"
                value={roleSelected}
                onChange={(value: string) => onChange(value, setFieldValue)}
                filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {userTypeList?.map((option: any) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Field>
              <ErrorMessage name="userType" component="div" className="error-message" />
            </div>
            {selectedRowObject?.restrictions === "active" && (
              <div className="mb-2">
                <p className="text-[#171717] text-[24px] font-inter font-semibold">Restrictions</p>
                <div className=" w-[40%] -form flex items-center justify-between mt-2">
                  <Input disabled={true} value={"Device Restriction"} type="text" className="h-[46px] flex items-center font-medium text-[18px]" prefix={<GreenCheckIcon />} />
                  <Tooltip title={"Remove Restriction"}>
                    <span>
                      <img role="none" src={trashcan} alt="delete" className="cursor-pointer h-[33px]" onClick={deleteRestriction} />
                    </span>
                  </Tooltip>
                </div>
              </div>
            )}
            <div className="mb-2 relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Personal Email ID {customerDetailsDefaultPassword ? null : "*"}</p>
              <Input
                type="email"
                className="h-[56px] font-medium"
                name="personalEmail"
                onChange={(e) => setFieldValue("personalEmail", e.target.value)}
                value={values.personalEmail}
                data-testid="emailId"
                disabled={disableFields}
              />
              <ErrorMessage name="personalEmail" component="div" className="error-message" />
            </div>
            <div className="mb-2 relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium" data-testid="phoneId">
                Mobile Number {customerDetailsDefaultPassword ? null : "*"}
              </p>
              <PhoneInput disabled={disableFields} inputClass="ant-input h-[56px] w-[100%]" country={"us"} value={values.phone} onChange={(value: any) => handleOnChangePhone(value, setFieldValue)} />
              <ErrorMessage name="phone" component="div" className="error-message" />
            </div>
          </div>

          <div className="mt-8">
            <CustomAttributesField errors={errors} values={values} setFieldValue={setFieldValue} userDetails={userDetails} setUserDetails={setUserDetails} hasValues={true} />
          </div>
          <div className="custom-field w-full mx-auto mb-10">
            <div className="flex w-full justify-between items-center ">
              <div className="text w-[75%] mt-5 ">
                <p className="text-[20px] font-semibold font-Inter">Please enter the following details or add custom field</p>
              </div>
            </div>
          </div>

          <CustomFieldBasic errors={errors} values={values} setFieldValue={setFieldValue} userDetails={userDetails} setUserDetails={setUserDetails} />

          <div className="mt-6">
            <ConnectedApplicationEdit
              setListOfApps={setListOfApps}
              listOfApps={listOfApps}
              name="Individual apps"
              styleName="individual-apps"
              individualApps={individualApps}
              tempListApps={tempListApps}
            />
          </div>
          <div>
            {userDetails?.akkuClientGroupMapDto?.userClientGroupDtoListStringMap && (
              <>
                <h1 className="editUser-title mb-6 mt-6">Groups connected apps</h1>
                <GroupAppList data={userDetails?.akkuClientGroupMapDto?.userClientGroupDtoListStringMap} setCheckedState={setCheckedState} checkedState={checkedState} />
              </>
            )}
          </div>
          {/* <UserRestriction restrictionList={restrictionList} /> */}

          {isActiveDeviceRestriction && (
            <div>
              <p className="text-[#171717] text-[24px] font-inter font-semibold">Restrictions</p>
              <div className="w-[100%] -form flex flex-wrap items-center justify-between mt-2">
                {restrictionList?.map((item: any) => (
                  <div key={item?.akkuUserDeviceInfoId} className=" w-[50%] -form flex items-center justify-between mt-2">
                    <div className="flex items-center justify-between">
                      <Input value={item?.serialNumber} type="text" className="h-[46px] w-[450px] flex items-center font-medium text-[18px]" prefix={<GreenCheckIcon />} />
                      <Tooltip title={"Remove Restriction"}>
                        <span>
                          <img role="none" src={trashcan} alt="delete" className="cursor-pointer h-[33px]" onClick={() => deleteRestriction(item)} />
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className={` ${isEdit ? "pb-4 relative bottom-0 left-0 mt-0 " : "pb-0 "} modal-footer mt-[5rem] p-8 pr-[30px] w-[100%] mx-auto`}>
            <div className="w-full flex justify-end">
              <CustomButtonBack onClick={handleToBack} text={"Cancel"} />
              <Button
                data-testid="submit-button"
                type="primary"
                htmlType="submit"
                className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white"
              >
                Update
              </Button>
            </div>
          </div>
          {loader && <Loader />}
          <Modal className="p-10  show-notification  _suspended rounded-lg" maskClosable={false} centered open={deleteModal} footer={false} onCancel={handleModalClose}>
            <div className="modal-body pt-4 ">
              <div className="flex w-full justify-center items-center flex-col">
                <ImageView src={images.notificationSuspend} alt="notificationDone" className="w-20 h-20" />
                <h5 className="text-[24px] font-Inter pt-3 font-semibold">{""}</h5>
                <p className="text-[20px] pt-4 text-center font-Inter font-medium text-[#475467]">Are you sure want to delete the restrictions ?</p>
              </div>
            </div>
            <div className="modal-footer pt-8">
              <div className="w-full flex justify-center">
                <CustomButtonBack text={"No"} onClick={handleModalClose} />
                <CustomButtonNext text={"Yes"} onClick={confirmDeleteRestriction} />
              </div>
            </div>
          </Modal>
          {modalOpen && <UserCreationNotificationModal type={"userBasicDetails"} modalOpen={modalOpen} setModalOpen={setModalOpen} responseMessage={ErrorMessages?.confirmationMessage} />}
        </Form>
      )}
    </Formik>
  );
};

export default UserManagementForm;
