import React, { useState, useEffect } from "react";
import useGetApiRequests from "../../services/axios/useApiRequests";
import { retrieveData, retrieveDeviceData } from "../../services/storage/Storage";
import AppList from "./apps/AppList";
import AppsHeader from "./apps/AppsHeader";
import UserHeader from "./user-header/UserHeader";
import Loader from "../../layouts/component/Loader";
import { useDispatch, useSelector } from "react-redux";
import { handleLogout } from "./user-header/LogoutHelperFile";
import checkWhitelistIP, { timeBasedRestriction } from "./checkWhitelistIP";
import UnauthorizedModal from "../../layouts/modal/unauthorized/UnauthorizedModal";
import { setRestrictionConfigure } from "../../redux/slice/restriction/RestrictionSlice";
import ClientDashboardNoDataFound from "./ClientDashboardNoDataFound";
import "./clientDashboard.scss";
interface App {
  app: string;
  description: string;
  link: string;
  image: string;
}

export default function ClientDashboard() {
  const dispatch = useDispatch();
  const deviceUrl = retrieveDeviceData("device");
  const config = retrieveData("kcConfig", false);
  const clientAppDetails = useGetApiRequests("clientAppDetails", "GET");
  const realmId = retrieveData("realmId", true);

  const whiteLabelListUrl: any = process.env.REACT_APP_WHITE_LABEL_BASEURL;
  const timeBasedUrl: any = process.env.REACT_APP_TIME_BASED_BASEURL;

  const realmName = retrieveData("realmName", true);
  const userId: string = useSelector((state: any) => state?.keycloak?.KeycloakInfo?.subject);
  const [appList, setAppList] = useState<App[]>([]);
  const [filterAppList, setFilterAppList] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [isActiveDesign, setIsActiveDesign] = useState<boolean>(false);
  const [isActiveTimeBasedAccess, setIsActiveTimeBasedAccess] = useState<boolean>(false);
  const [logoutLoader, setLogoutLoader] = useState<boolean>(false);
  const gettingLogoutUrls = useGetApiRequests("getLogoutUrls", "GET");
  const userSessionKilling = useGetApiRequests("logoutSessionKill", "POST");
  const userName = retrieveData("userName", true);

  useEffect(() => {
    // if (!deviceManagerEnabled) {
    //   if (config?.isIpRestrictionEnabled) {
    //     handleCheckWhitelistIP();
    //   }
    // }
    // if (!config?.isTimeBasedRestrictionEnabled && !config?.isIpRestrictionEnabled && !config?.isDeviceManagerEnabled) {
    //   getClientAppDetails();
    // }

    getClientAppDetails();
  }, []);

  const refreshTimeData = () => {
    handleTimeBasedRestriction();
  };

  const refreshIPData = () => {
    handleCheckWhitelistIP(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleTimeBasedRestriction = async () => {
    const isTimeAllowed = await timeBasedRestriction(realmName, userId, timeBasedUrl, setLoader);
    setIsActiveTimeBasedAccess(isTimeAllowed);
    setIsActiveDesign(isTimeAllowed);
    dispatch(
      setRestrictionConfigure({
        isActive: isTimeAllowed,
        isActiveTime: true,
        restrictionType: "timeRestriction",
      }),
    );

    const intervalId = setInterval(refreshTimeData, 180000); // 3 minutes
    return () => clearInterval(intervalId);
  };

  /**
   * Handler function to check if an IP is whitelisted.
   * This function calls the checkWhitelistIP function with necessary parameters.
   */
  const handleCheckWhitelistIP = async (interValCall = false) => {
    const isIpAllowed = await checkWhitelistIP(realmName, userId, whiteLabelListUrl, setLoader);
    setOpen(isIpAllowed);
    setIsActiveDesign(isIpAllowed);
    if (!isIpAllowed) {
      if (!interValCall) {
        getClientAppDetails();
      }

      if (config?.isTimeRestricitonEnabled && config?.isIpRestrictionEnabled) {
        handleTimeBasedRestriction();
      }

      const intervalId = setInterval(refreshIPData, 180000); // 3 minutes // 180000
      return () => clearInterval(intervalId);
    }
    setLoader(false);
  };

    const sortClientAppsList = (items: any) => {
    const sortedArrays = items?.sort((a: any, b: any) => {
      const firstElements = a?.app?.toUpperCase();
      const secondElements = b?.app?.toUpperCase();
      if (firstElements < secondElements) {
        return -1;
      }
      if (firstElements > secondElements) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    return sortedArrays;
  };
  const getClientAppDetails = async () => {
    setLoader(true);
    let payload = {
      realmId: realmId,
      userId: userId,
    };
    try {
      const response: any = await clientAppDetails("", payload);
      const status = response.status;

      if (status === 200) {
        const data = response.data.data;
        setAppList(data);
        const clientAppList = sortClientAppsList (data);
        setFilterAppList(clientAppList);
        setLoader(false);
      }
    } catch (err: any) {
      setLoader(false);
    }
  };
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);

    const filtered = appList.filter((item) => item.app.toLowerCase().includes(value.toLowerCase()));
    setFilterAppList(filtered);
  };

  const logout = () => {
    handleLogout(dispatch, gettingLogoutUrls, userName, userSessionKilling, setLoader, deviceUrl, setLogoutLoader);
  };

  return (
    <>
      {loader && <Loader />}
      {!isActiveDesign && (
        <div className="home-page">
          <div className="akkuHeader">
            <UserHeader />
          </div>
          <AppsHeader handleSearch={handleSearch} searchTerm={searchTerm} />
          <div className="app">
            <div className="app-items w-[80%] mx-auto">
              {filterAppList?.length !== 0 ? (
                <>
                  {/* <p className="text-[28px] font-Inter font-semibold pl-5">Frequently Used Apps</p> */}
                  <AppList filterAppList={filterAppList} />
                </>
              ) : (
                <ClientDashboardNoDataFound />
              )}
            </div>
          </div>
        </div>
      )}

      {open && <UnauthorizedModal loader={logoutLoader} modalType="ip" open={open} handleModalClose={handleModalClose} logout={logout} isActiveBackButton={true} />}
      {isActiveTimeBasedAccess && (
        <UnauthorizedModal modalType="time" loader={logoutLoader} open={isActiveTimeBasedAccess} handleModalClose={handleModalClose} logout={logout} isActiveBackButton={true} />
      )}
    </>
  );
}
