import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { retrieveData } from "../../../../services/storage/Storage";
import { useDispatch, useSelector } from "react-redux";
import { setIpPayload, setPayloadForTimeAndIp } from "../../../../redux/slice/IPSlice";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { getUsersList } from "../../../dashboard/user/user-dashboard/groups/modal/ListOfUserHelperFile";
import { getUserGroup } from "../select-ip/IpRestrictionUtils";
import { getZones } from "./TimeRestrictionTableData";
import SuccessMessageModal from "../IpTimeSuccessModal";
import CloseConfirmationModal from "../Model/CloseConfirmationModal";
import GroupAndUserNecessaryModal from "../Model/GroupAndUserNecessaryModal";
import TimeParametersRestrictionEdit from "./TimeParametersRestrictionEdit";
import TimeParameterAddForm from "./TimeParameterAddForm";
import { setListOfRestrictionModal } from "../../../../redux/slice/Users/UserDashboardUpdateSlice";
import AccessManagerBackNavigationArrow from "../AccessManagerBackNavigationArrow";
import dayjs from "dayjs";
import "./timeParameters.scss";

const TimeParameters = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const isEditFromUrl = params.get("isEdit");
  const type = params.get("type");
  const navigatedFrom = params.get("from");

  const realmId = retrieveData("realmId", true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const createTimeRestrictionApi = useGetApiRequests("createTimeBasedRestriction", "POST");
  const getUserListApi = useGetApiRequests("userManagement", "POST");
  const getGroupsListApi = useGetApiRequests("getGroupsList", "GET");
  const updateTimeRestrictionApi = useGetApiRequests("updateTimeBasedRestriction", "PUT");
  const [initialValues, setInitialValues] = useState<any>({
    name: "",
    description: "",
    assignTo: navigatedFrom ? "users" : "",
    timeParameter: [{ timeTo: "", timeFrom: "", timezone: "", id: "" }],
  });
  const [isActiveEdit, setIsActiveEdit] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [zonesList, setZonesList] = useState<any>([]);
  const [loader, setLoader] = useState(false);
  const [usersSearchListForTime, setUsersSearchListForTime] = useState<any>([]);
  const [selectedObjects, setSelectedObjects] = useState<any>([]);
  const [listOfUsersForTime, setListOfUsersForTime] = useState<any>([]);
  const [dropdownVisibleForTime, setDropdownVisibleForTime] = useState(false);
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [updatedUserList, setUpdatedUserList] = useState<any>([]);
  const [addedUsersForTime, setAddedUsersForTime] = useState<any>([]);
  const [groupDataForTime, setGroupDataForTime] = useState<any>([]);
  const [deletedUsersForTime, setDeletedUsersForTime] = useState<any>([]);
  const [userSearchGroupDataForTime, setUserSearchGroupDataForTime] = useState<any>([]);
  const [editRecordData, setEditRecordData] = useState<any>([]);
  const [timerSuccessModal, setTimerSuccessModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState<any>();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [atLeastOneModalForTime, setAtLeastOneModalForTime] = useState(false);
  const [deleteIds, setDeleteIds] = useState<any>([]);
  const [newRowAdded, setIsNewRowAdded] = useState(false);
  const [key, setKey] = useState<number>(1);
  const [newForm, setNewForm] = useState<any>([{ accessTo: "", accessFrom: "", timezone: "", userTimeBasedAuthorizationId: "" }]);
  const editIpDetailsForTime = useSelector((state: any) => state?.IpRestrictionSlice?.editRecordDetails);
  const savedPayloadForTimeParam = useSelector((state: any) => state?.IpRestrictionSlice?.storePayload);
  const deviceRestrictionList: any = useSelector((state: any) => state?.DeviceRestrictionSlice?.deviceRestrictionList);

  const returnToTheAccessManager = () => {
    navigate("/access-manager");
  };
  const returnToTheUserManagement = () => {
    dispatch(setListOfRestrictionModal(true));
    navigate("/user");
  };
  const returnToBack = () => {
    navigate(-1);
  };
  const handleOpenConfirmationModal = () => {
    setOpenConfirmationModal(true);
  };
  const closeConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  useEffect(() => {
    getZones(setLoader, setZonesList);

    if (isEditFromUrl === "true") {
      setIsActiveEdit(true);
      const timeParamDetailFromApi = editIpDetailsForTime?.userTimeBasedAuthorizationRestrictionKvDtoList?.map((item: any) => ({
        timezone: item?.timezone,
        accessFrom: item?.accessFrom,
        accessTo: item?.accessTo,
        userTimeBasedAuthorizationId: item?.userTimeBasedAuthorizationId,
      }));
      setNewForm(timeParamDetailFromApi);
      const timeParamDetailInTimeFormat = editIpDetailsForTime?.userTimeBasedAuthorizationRestrictionKvDtoList?.map((item: any) => ({
        timezone: item?.timezone,
        timeFrom: dayjs(item?.accessFrom, "hh:mm A"),
        timeTo: dayjs(item?.accessTo, "hh:mm A"),
        id: item?.userTimeBasedAuthorizationId,
      }));

      setInitialValues({
        name: editIpDetailsForTime?.userTimeBasedAuthorizationDto?.name,
        description: editIpDetailsForTime?.userTimeBasedAuthorizationDto?.description,
        assignTo: type,
        timeParameter: timeParamDetailInTimeFormat,
      });
      if (type === "users") {
        const editUserList: any = editIpDetailsForTime?.userTimeBasedAuthorizationWithUserKvDtoList?.map((item: any) => ({
          key: item?.userDto?.id,
        }));

        getUsersList(0, 10, "", false, setLoader, realmId, getUserListApi, setUsersSearchListForTime, true, editUserList, "", setListOfUsersForTime, true, setEditRecordData);
      } else if (type === "groups") {
        const editGroupList: any = editIpDetailsForTime?.userTimeBasedAuthorizationWithGroupKvDtoList?.map((item: any) => item?.groupKvDto?.id);
        getUserGroup(setLoader, getGroupsListApi, setGroupDataForTime, setUserSearchGroupDataForTime, editGroupList, true, setEditRecordData);
      }
    } else if (savedPayloadForTimeParam && Object.keys(savedPayloadForTimeParam)?.length > 0) {
      const timeParamDetailInTimeFormat = savedPayloadForTimeParam?.userTimeBasedAuthorizationRestrictionKvDto?.map((item: any) => ({
        timezone: item?.timezone,
        timeFrom: dayjs(item?.accessFrom, "hh:mm A"),
        timeTo: dayjs(item?.accessTo, "hh:mm A"),
        id: item?.userTimeBasedAuthorizationId,
      }));
      const timeParamDetailFromApi = savedPayloadForTimeParam?.userTimeBasedAuthorizationRestrictionKvDto?.map((item: any) => ({
        timezone: item?.timezone,
        accessFrom: item?.accessFrom,
        accessTo: item?.accessTo,
        userTimeBasedAuthorizationId: item?.userTimeBasedAuthorizationId,
      }));
      setNewForm(timeParamDetailFromApi);
      setInitialValues({
        name: savedPayloadForTimeParam?.userTimeBasedAuthorizationDto?.name,
        description: savedPayloadForTimeParam?.userTimeBasedAuthorizationDto?.description,
        assignTo: savedPayloadForTimeParam?.assignTo,
        timeParameter: timeParamDetailInTimeFormat,
      });
      setKey(key + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const constructPayload = (payload: any) => {
    if (type === "users") {
      payload.addUserTimeRestriction = listOfUsersForTime.map((item: any) => item?.key);
    } else if (type === "groups") {
      payload.addGroupTimeRestriction = groupDataForTime?.map((item: any) => item?.key);
    } else if (type === "organization") {
      payload.organisationTimeZoneCreation = true;
    }
    return payload;
  };
  const constructDeletePayload = (payload: any) => {
    if (deletedUsersForTime?.length > 0 && deleteIds?.length > 0) {
      payload.removeTimeRestriction = Array.from(new Set([...deletedUsersForTime, ...deleteIds]));
    } else if (deletedUsersForTime?.length > 0) {
      payload.removeTimeRestriction = deletedUsersForTime;
    } else {
      payload.removeTimeRestriction = deleteIds;
    }
    return payload;
  };
  const newlyAddedRowPayload = (object: any) => {
    if (type === "groups") {
      object.addGroupTimeRestriction = groupDataForTime?.map((item: any) => item?.key);
    } else if (type === "users") {
      object.addUserTimeRestriction = listOfUsersForTime?.map((item: any) => item?.key);
    } else if (type === "organization") {
      object.organisationTimeZoneCreation = true;
    }
    return object;
  };
  const showAtLeastOneModal = (condition: boolean) => {
    if (condition) {
      setAtLeastOneModalForTime(true);
      return true;
    }
    return false;
  };
  const addUserAndGroupListInPayload = (payload: any) => {
    if (addedUsersForTime?.length > 0 && type === "groups") {
      payload.addGroupTimeRestriction = addedUsersForTime;
      payload.organisationTimeZoneCreation = false;
    } else if (addedUsersForTime?.length > 0 && type === "users") {
      payload.addUserTimeRestriction = addedUsersForTime;
      payload.organisationTimeZoneCreation = false;
    }
    return payload;
  };
  const handleApiSuccessCall = (response: any) => {
    setLoader(false);
    setTimerSuccessModal(true);
    setResponseMessage(response?.data?.message);
    setTimeout(() => {
      handleCloseSuccessModal();
      navigate("/access-manager");
    }, 3000);
  };
  const updateTimeRestriction = async (values: any) => {
    const isUserTypeAndEmpty = type === "users" && listOfUsersForTime?.length === 0;
    const isGroupTypeAndEmpty = type === "groups" && groupDataForTime?.length === 0;

    if (showAtLeastOneModal(isUserTypeAndEmpty) || showAtLeastOneModal(isGroupTypeAndEmpty)) {
      return;
    }
    const pathParams: any = {
      id: editIpDetailsForTime?.userTimeBasedAuthorizationDto?.akkuUserTimeBasedAuthorizationId,
    };

    try {
      const payload: any = {
        userTimeBasedAuthorizationDto: {
          akkuUserTimeBasedAuthorizationId: editIpDetailsForTime?.userTimeBasedAuthorizationDto?.akkuUserTimeBasedAuthorizationId,
          realmId: realmId,
          name: values?.name,
          description: values?.description,
          isActive: editIpDetailsForTime?.userTimeBasedAuthorizationDto?.isActive,
        },
        userTimeBasedAuthorizationRestrictionKvDto: newForm,
      };

      addUserAndGroupListInPayload(payload);

      const differentLength = editIpDetailsForTime?.userTimeBasedAuthorizationRestrictionKvDtoList.length !== values.timeParameter.length;

      if (differentLength && newRowAdded) {
        constructPayload(payload);
      } else if (!differentLength && newRowAdded) {
        newlyAddedRowPayload(payload);
      }
      if (deletedUsersForTime?.length > 0 || deleteIds?.length > 0) {
        constructDeletePayload(payload);
      }
      try {
        const response: any = await updateTimeRestrictionApi(payload, {}, pathParams);
        const status = response.status;
        if (status === 200) {
          handleApiSuccessCall(response);
        }
      } catch (err: any) {
        handleRequestError(err);
      }
    } catch (err: any) {
      handleRequestError(err);
    }
  };
  const addRestrictionForParticularUser = async (payload: any) => {
    const timeRestrictedUser = deviceRestrictionList?.map((item: any) => item?.key);
    payload.userTimeBasedAuthorizationKeyValueDto = {
      userIdList: timeRestrictedUser,
    };

    try {
      const response: any = await createTimeRestrictionApi(payload);
      const status = response.status;
      if (status === 200) {
        handleApiSuccessCall(response);
      }
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };
  const onSubmit = async (values: any) => {
    if (!isActiveEdit) {
      const payload: any = {};

      payload.userTimeBasedAuthorizationDto = {
        realmId: realmId,
        name: values?.name,
        description: values?.description,
      };
      const transformedArray = newForm?.map((item: any) => ({
        timezone: item?.timezone,
        accessFrom: item?.accessFrom,
        accessTo: item?.accessTo,
      }));
      payload.userTimeBasedAuthorizationRestrictionKvDto = transformedArray;
      const savedPayload: any = { ...payload };
      savedPayload.assignTo = values?.assignTo;

      dispatch(setPayloadForTimeAndIp(savedPayload));
      if (!navigatedFrom) {
        const assignTo = values?.assignTo;
        if (assignTo === "groups" || assignTo === "users") {
          dispatch(setIpPayload(payload));
          const navigationPath = assignTo === "groups" ? "/access-manager/groups?parameter=time" : "/access-manager/assignUsers?parameter=time";
          navigate(navigationPath);
        } else if (values?.assignTo === "organization") {
          try {
            const response: any = await createTimeRestrictionApi(payload);
            const status = response.status;
            if (status === 200) {
              handleApiSuccessCall(response);
            }
          } catch (err: any) {
            handleRequestError(err);
            setLoader(false);
          }
        }
      } else {
        addRestrictionForParticularUser(payload);
      }
    } else {
      updateTimeRestriction(values);
    }
  };
  const handleCloseNecessaryModalForTime = () => {
    setAtLeastOneModalForTime(false);
  };
  const onSelectChangeForTime = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChangeForTime,
  };
  const unassignUserForTime = () => {
    if (type === "groups") {
      const shallowGroupsData = [...groupDataForTime];
      const reformedGroupList = shallowGroupsData?.filter((item: any) => !selectedRowKeys?.includes(item.key));
      const selectedAuthorizationGroupIds = editIpDetailsForTime?.userTimeBasedAuthorizationWithGroupKvDtoList
        ?.filter((item: any) => selectedRowKeys.includes(item?.groupKvDto?.id))
        .flatMap((item: any) => item?.userTimeBasedAuthorizationId.split(",").map((id: any) => Number(id.trim())));
      const mergeDeleteUserArray = [...deletedUsersForTime, ...selectedAuthorizationGroupIds];
      setDeletedUsersForTime(mergeDeleteUserArray);
      setGroupDataForTime(reformedGroupList);
    } else {
      const shallowListOfUsers = [...listOfUsersForTime];
      const filteredReformedListNew = shallowListOfUsers?.filter((item: any) => !selectedRowKeys?.includes(item.key));
      const selectedAuthorizationIds = editIpDetailsForTime?.userTimeBasedAuthorizationWithUserKvDtoList
        .filter((item: any) => selectedRowKeys.includes(item?.userDto?.id))
        .flatMap((item: any) => item?.userTimeBasedAuthorizationId.split(",").map((id: any) => Number(id.trim())));

      const mergeDeleteUserArray = [...deletedUsersForTime, ...selectedAuthorizationIds];
      setDeletedUsersForTime(mergeDeleteUserArray);
      setListOfUsersForTime(filteredReformedListNew);
    }
    setSelectedRowKeys([]);
  };

  const timeParamAssigningTo = (value: any, setFieldValue: any) => {
    setFieldValue("assignTo", value);
  };

  const onChangingZone = (value: any, setFieldValue: any, index?: number) => {
    const fieldName = `timeParameter[${index}].timezone`;
    setFieldValue(fieldName, value);

    if (index !== undefined) {
      setNewForm((prevState: any) => {
        const newState: any = [...prevState];
        newState[index] = { ...newState[index], timezone: value };
        return newState;
      });
    }
  };

  const handleAddUsersInTime = () => {
    if (selectedValues.length > 0) {
      const selectedObjects = usersSearchListForTime.filter((item: any) => selectedValues.includes(item.value));
      setAddedUsersForTime(selectedValues);
      setSelectedObjects(selectedObjects);
      const combinedArray = [...updatedUserList, ...selectedObjects];
      const uniqueMergedArray = Array.from(new Set(combinedArray?.map((obj) => obj?.value)))?.map((value) => {
        return combinedArray?.find((obj) => obj?.value === value);
      });
      setListOfUsersForTime(uniqueMergedArray);

      setDropdownVisibleForTime(false);
    } else {
      setDropdownVisibleForTime(false);
      setListOfUsersForTime(editRecordData);
    }
  };
  const handleChangeUsersForTime = (value: string) => {
    setSelectedValues(value);
    const previousValue = [...selectedObjects];
    const removingIds = previousValue?.filter((item) => !value.includes(item.value)).map((item) => item.value);
    const listOfUsersDetails = [...listOfUsersForTime];
    const needToDeleteForTime = listOfUsersDetails?.filter((item) => !removingIds.includes(item.value));
    setUpdatedUserList(needToDeleteForTime);
  };
  const handleAddGroupsInTime = () => {
    if (selectedValues.length > 0) {
      const selectedObjects = userSearchGroupDataForTime?.filter((item: any) => selectedValues?.includes(item.value));
      setAddedUsersForTime(selectedValues);
      setSelectedObjects(selectedObjects);
      const mergedArray = [...updatedUserList, ...selectedObjects];
      const uniqueMergedArray = Array.from(new Set(mergedArray?.map((obj) => obj?.value)))?.map((value) => {
        return mergedArray?.find((obj) => obj.value === value);
      });
      setGroupDataForTime(uniqueMergedArray);
      setDropdownVisibleForTime(false);
    } else {
      setDropdownVisibleForTime(false);
      setGroupDataForTime(editRecordData);
    }
  };

  const handleLabelClick = (e: any, value: any) => {
    e.stopPropagation();
    const shallowSelectedValues: any = [...selectedValues];
    if (shallowSelectedValues?.includes(value)) {
      shallowSelectedValues?.splice(shallowSelectedValues?.indexOf(value), 1);
    } else {
      shallowSelectedValues.push(value);
    }
    setSelectedValues(shallowSelectedValues);
  };
  const handleChangeGroups = (value: string) => {
    setSelectedValues(value);
    const previousValue = [...selectedObjects];
    const missingIdsForGroups = previousValue.filter((item) => !value.includes(item.value)).map((item) => item.value);
    const listOfGroupDetails = [...groupDataForTime];
    const needToDeleteForTime = listOfGroupDetails.filter((item) => !missingIdsForGroups.includes(item.value));
    setUpdatedUserList(needToDeleteForTime);
  };
  const handleCloseSuccessModal = () => {
    setTimerSuccessModal(false);
  };

  const handleOnChange = (type: any, date: any, setFieldValue: any, value: any, index?: number) => {
    const fieldName = `timeParameter[${index}].${type}`;
    setFieldValue(fieldName, value);
    let name = "";
    if (type === "timeFrom") {
      name = "accessFrom";
    } else if (type === "timeTo") {
      name = "accessTo";
    }
    if (index !== undefined) {
      setNewForm((prevState: any) => {
        const newState: any = [...prevState];
        newState[index] = { ...newState[index], [name]: date };
        return newState;
      });
    }
  };
  const handleRemoveList = (index: any, remove: any, values: any) => {
    const removedTimeZonesList = {
      accessFrom: dayjs(values?.timeFrom).format("hh:mm A"),
      accessTo: dayjs(values?.timeTo).format("hh:mm A"),
      timezone: values?.timezone,
      userTimeBasedAuthorizationId: values?.id,
    };

    const shallowNewForm = [...newForm];
    const updatedForm = shallowNewForm?.filter(
      (item: any) =>
        item.timezone !== removedTimeZonesList?.timezone ||
        item.accessFrom !== removedTimeZonesList?.accessFrom ||
        item.accessTo !== removedTimeZonesList?.accessTo ||
        item.userTimeBasedAuthorizationId !== removedTimeZonesList?.userTimeBasedAuthorizationId,
    );
    if (updatedForm && updatedForm.length > 0) {
      setNewForm(updatedForm);
    } else if (updatedForm && updatedForm.length === 0) {
      setNewForm([{ accessTo: "", accessFrom: "", timezone: "" }]);
    }
    if (values?.id && values?.id !== "") {
      const newIdValues = values?.id?.split(",").map(Number);

      setDeleteIds((prevDeleteIds: any) => {
        const flatPrevDeleteIds = prevDeleteIds.flat();
        const combinedIds = [...flatPrevDeleteIds, ...newIdValues];
        const uniqueIds = Array.from(new Set(combinedIds));
        return uniqueIds;
      });
    }
    remove(index);
  };
  return (
    <div className="akku-container">
      <div className="main-container">
        <div className="h-full dashboard-container time-parameter-wrapper ">
          <ul className="breadcrumb flex">
            {!navigatedFrom ? (
              <li className="font-Inter cursor-pointer pr-1" onClick={returnToTheAccessManager}>
                Access Manager /
              </li>
            ) : (
              <li className="cursor-pointer font-Inter pr-1" onClick={returnToTheUserManagement}>
                User Management /
              </li>
            )}
            <li className="font-Inter pr-1 active">Time Parameters</li>
          </ul>
          <div className={`mb-14 mt-4 ${isActiveEdit ? "selectIpEdit-title" : ""}`}>
            <AccessManagerBackNavigationArrow title={!isActiveEdit ? "Time Parameters" : "Edit - Time Parameters"} />
          </div>
          {!isActiveEdit ? (
            <TimeParameterAddForm
              props={{
                key: key,
                initialValues: initialValues,
                zonesList: zonesList,
                onSubmit: onSubmit,
                onChangingZone: onChangingZone,
                handleOnChange: handleOnChange,
                handleRemoveList: handleRemoveList,
                timeParamAssigningTo: timeParamAssigningTo,
                handleOpenConfirmationModal: handleOpenConfirmationModal,
              }}
            />
          ) : (
            <TimeParametersRestrictionEdit
              props={{
                initialValues: initialValues,
                onSubmit: onSubmit,
                onChangingZone: onChangingZone,
                handleOnChange: handleOnChange,
                handleRemoveList: handleRemoveList,
                setIsNewRowAdded: setIsNewRowAdded,
                handleChangeGroups: handleChangeGroups,
                setDropdownVisibleForTime: setDropdownVisibleForTime,
                handleAddUsersInTime: handleAddUsersInTime,
                handleAddGroupsInTime: handleAddGroupsInTime,
                handleChangeUsersForTime: handleChangeUsersForTime,
                handleLabelClick: handleLabelClick,
                unassignUserForTime: unassignUserForTime,
                userSearchGroupDataForTime: userSearchGroupDataForTime,
                selectedValues: selectedValues,
                zonesList: zonesList,
                dropdownVisibleForTime: dropdownVisibleForTime,
                usersSearchListForTime: usersSearchListForTime,
                selectedRowKeys: selectedRowKeys,
                groupDataForTime: groupDataForTime,
                listOfUsersForTime: listOfUsersForTime,
                rowSelection: rowSelection,
                loader: loader,
              }}
            />
          )}
        </div>
      </div>
      {openConfirmationModal && <CloseConfirmationModal closeModalOpen={openConfirmationModal} handleProceed={returnToBack} handleCancel={closeConfirmationModal} />}
      {atLeastOneModalForTime && (
        <GroupAndUserNecessaryModal handleCloseNecessaryModal={handleCloseNecessaryModalForTime} openNecessaryModal={atLeastOneModalForTime} type={type === "groups" ? "group" : "user"} />
      )}
      {timerSuccessModal && <SuccessMessageModal open={timerSuccessModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
    </div>
  );
};

export default TimeParameters;
