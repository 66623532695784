import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Button, Collapse } from "antd";
import { useNavigate } from "react-router-dom";
import useGetApiRequests from "../../../../../../services/axios/useApiRequests";
import { retrieveData } from "../../../../../../services/storage/Storage";
import {
  setActiveStep,
  setDeProvisionConnectorEndpointConfigId,
  setDeProvisioning,
  setProvisioningBackButtonClicked,
  setProvisioningButtonStatus,
} from "../../../../../../redux/slice/provisioning/ProvisioningSlice";
import { connectorDeProvisionSchema, endPointDescriptionPayload, transformHeaderParameters } from "../../helper/connectorHelper";
import { handleRequestError } from "../../../../../../layouts/toast/ErrorNotificationMessage";
import { setCurrentStep } from "../../../../../../redux/slice/app-store/AppDetailsSlice";
import UserDeprovision from "./user-deProvisioning/UserDeprovision";
import CustomButtonBack from "../../../../../../layouts/component/CustomButtonBack";
import SuccessMessageModal from "../../../../../access-manager/create/IpTimeSuccessModal";

const ConnectorProvision = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const generalDetails = useSelector((state: any) => state?.provisioning?.generalDetails);
  const deProvisionBackButtonClicked = useSelector((state: any) => state?.provisioning?.deProvisioningBackButtonClicked);
  const deProvisionConnectorEndpointConfigId = useSelector((state: any) => state.provisioning?.deProvisionConnectorEndpointConfigId);
  const akkuProvisioningConnectorId = useSelector((state: any) => state.provisioning?.akkuProvisioningConnectorId);
  const buttonStatus = useSelector((state: any) => state?.provisioning?.duplicateProvisioningButtons);

  const deProvisioningConnectorConfigure = useGetApiRequests("provisioningConnectorConfigure", "POST");
  const deProvisioningConnectorConfigureUpdate = useGetApiRequests("provisioningConnectorConfigureUpdate", "PUT");

  const realmId = retrieveData("realmId", true);
  const provisioning = useSelector((state: any) => state?.provisioning);
  const formInitialValues = useSelector((state: any) => state?.provisioning?.deProvisioning);
  const [activeKey, setActiveKey] = useState<string>("1");
  const [authType, setAuthType] = useState<string>("");
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [deProvisionDirty, setDeProvisionDirty] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>("");
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  useEffect(() => {
    if (buttonStatus.connectorDeprovisioning) {
      setIsDuplicate(true);
    }
  }, [buttonStatus?.connectorDeprovisioning]);

  const handleCloseSuccessModal = () => {
    setSuccessModal(false);
  };
  const handleSubmit = (values: any) => {
    setLoader(true);
    createPayloadConnectorConfigure(values);
    dispatch(setDeProvisioning(values));
  };

  const createPayloadConnectorConfigure = async (values: any) => {
    const { userDeProvision } = values;
    const deProvisionCreatePayload = {
      apiEndpointUrl: userDeProvision[0].apiEndpointURL,
      methodType: userDeProvision[0].methodType,
      endpointDescription: endPointDescriptionPayload(userDeProvision[0].methodType),
      requestPayload: userDeProvision[0]?.requestPayload || null,
      sampleResponse: userDeProvision[0]?.response || null,
      realmId: realmId,
      name: generalDetails.name,
      type: "request",
      headerParameters: transformHeaderParameters(values?.userDeProvision[0]?.headerParam),
      isPathParameter: userDeProvision[0]?.headerParametersType === "true",
    };
    const deProvisionPayloadUpdate: any = {
      apiEndpointUrl: userDeProvision[0]?.apiEndpointURL,
      methodType: userDeProvision[0]?.methodType,
      endpointDescription: endPointDescriptionPayload(userDeProvision[0].methodType),
      requestPayload: userDeProvision[0]?.requestPayload || null,
      sampleResponse: userDeProvision[0]?.response || null,
      realmId: realmId,
      name: generalDetails.name,
      type: "request",
      headerParameters: transformHeaderParameters(values?.userDeProvision[0]?.headerParam),
      isPathParameter: userDeProvision[0]?.headerParametersType === "true",
      akkuProvisioningConnectorEndpointConfigId:
        deProvisionConnectorEndpointConfigId !== "" ? deProvisionConnectorEndpointConfigId : formInitialValues?.userDeProvision[0]?.akkuProvisioningConnectorEndpointConfigId,
    };
    // const deProvisionPayload = provisioning.isActiveEdit || (deProvisionBackButtonClicked && deProvisionDirty)? deProvisionPayloadUpdate : deProvisionCreatePayload;
    const shouldUpdateDeProvision =
      !buttonStatus.connectorDeprovisioning && ((provisioning.isActiveEdit && provisioning.endPointResponseDtosLen > 1) || (deProvisionBackButtonClicked && deProvisionDirty));

    if (shouldUpdateDeProvision) {
      handleUpdateDeProvisionApiCall(deProvisionPayloadUpdate);
    } else {
      handleCreateDeProvisionApiCall(deProvisionCreatePayload);
    }
  };
  const handleCreateDeProvisionApiCall = async (payload: any) => {
    try {
      const response: any = await deProvisioningConnectorConfigure(payload);
      const deProvisionConnectorEndpointConfigId = response?.data?.data?.akkuProvisioningConnectorEndpointConfigId;
      dispatch(setDeProvisionConnectorEndpointConfigId(deProvisionConnectorEndpointConfigId));
      const status = response.status;
      if (status === 200) {
        setLoader(false);
        setResponseMessage(response.data.message);
        setSuccessModal(true);
        setTimeout(() => {
          if (provisioning?.isDuplicate) {
            dispatch(setProvisioningButtonStatus({ ...buttonStatus, connectorDeprovisioning: false }));
          }
          handleCloseSuccessModal();
          // navigate("/app-store");
          dispatch(setActiveStep(4));
        }, 3000);
      }
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  const handleUpdateDeProvisionApiCall = async (payload: any) => {
    const param = {
      akkuProvisioningConnectorId: provisioning.akkuProvisioningConnectorId ?? akkuProvisioningConnectorId,
    };
    try {
      const response: any = await deProvisioningConnectorConfigureUpdate(payload, {}, param);

      const status = response.status;
      if (status === 200) {
        setLoader(false);
        setResponseMessage(response.data.message);
        setSuccessModal(true);
        setTimeout(() => {
          handleCloseSuccessModal();
          // navigate("/app-store");
          dispatch(setActiveStep(4));
        }, 3000);
      }
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  const handleBack = () => {
    const updatedStepper = {
      activeStep: 2,
      childStepper: "",
    };
    dispatch(setProvisioningBackButtonClicked(true));
    dispatch(setCurrentStep(updatedStepper));
    dispatch(setActiveStep(2));
  };

  const handleHome = () => {
    navigate("/app-store");
  };
  const handleNext = () => {
    if (provisioning?.isDuplicate) {
      dispatch(setProvisioningButtonStatus({ ...buttonStatus, connectorMappingAttributes: true }));
    }
    dispatch(setActiveStep(4));
  };

  const handleOnChange = (setFieldValue: any, value: any, field: any) => {
    setAuthType(value);
    setFieldValue(field, value);
  };
  const handleCollapseChange = (key: any) => {
    setActiveKey(key);
  };

  const createProvisioningConnector = async (payload: any) => {
    try {
      const response: any = await deProvisioningConnectorConfigure(payload);
      const status = response.status;
      if (status === 200) {
      }
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  return (
    <div className="w-full px-10 pb-20 connector-provision">
      <p className="app-header py-8">Connector Details - Deprovisioning {provisioning?.isDuplicate ? "(Copy)" : null}</p>
      <div className="w-full mx-auto acc-provision">
        <Formik initialValues={formInitialValues} onSubmit={handleSubmit} validationSchema={connectorDeProvisionSchema} enableReinitialize>
          {({ values, setFieldValue, validateForm, errors, dirty }) => {
            setDeProvisionDirty(dirty);
            return (
              <Form>
                <div className=" w-full flex flex-wrap flex-col gap-2  justify-center">
                  <Collapse
                    accordion
                    activeKey={activeKey}
                    onChange={handleCollapseChange}
                    items={[
                      {
                        key: "1",
                        label: <p className="font-normal text-[#000] font-Inter text-[18px]">User Deprovisioning</p>,
                        children: <UserDeprovision values={values} setFieldValue={setFieldValue} />,
                      },
                      // {
                      //   key: "2",
                      //   label: <p className="font-normal text-[#000] font-Inter text-[18px]">Role Provisioning</p>,
                      //   children: <RoleProvision values={values} setFieldValue={setFieldValue} />,
                      // },
                      // {
                      //   key: "3",
                      //   label: <p className="font-normal text-[#000] font-Inter text-[18px]">Group Provisioning</p>,
                      //   children: <GroupProvision values={values} setFieldValue={setFieldValue} />,
                      // },
                    ]}
                    className="w-[95%] custom-header bg-[#fff]"
                    expandIconPosition="end"
                  />
                </div>

                <div className="footer flex items-center absolute bottom-0 right-0 w-full bg-[#fff] h-[100px]">
                  <div className="modal-footer w-full mx-auto ">
                    <div className="w-full flex justify-end pr-5">
                      <CustomButtonBack text={"Close"} onClick={handleHome} />
                      <CustomButtonBack text={"Back"} onClick={() => handleBack()} />
                      {!isDuplicate && ((provisioning.isActiveEdit && provisioning.endPointResponseDtosLen > 1) || deProvisionBackButtonClicked) ? (
                        dirty ? (
                          <Button loading={loader} type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn">
                            Update
                          </Button>
                        ) : (
                          <Button type="primary" onClick={handleNext} className="bg-[#5441DA] submit-btn">
                            Next
                          </Button>
                        )
                      ) : (
                        <Button loading={loader} type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn">
                          Next
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {successModal && <SuccessMessageModal open={successModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
    </div>
  );
};

export default ConnectorProvision;
