import React, { useEffect } from "react";
import ManageConnection from "./manage-connection/ManageConnection";
import ConnectorDetails from "./connector/ConnectorDetails";
import ProvisionMappingAttributes from "./mappers/ProvisionMappingAttributes";
import ProvisionGroupMapping from "./provision-group-mapping/ProvisionGroupMapping";
import MappingUserStatus from "./mapper-status/MappingUserStatus";
import ProvisionStepper from "./stepper/ProvisionStepper";
import { useDispatch, useSelector } from "react-redux";
import ConnectionAuthentication from "./manage-auth/ConnectionAuthentication";
import ConnectorProvision from "./provision-group-mapping/provisioning/ConnectorProvision";
import ProvisionRoleMapping from "./provision-role-mapping/ProvisionRoleMapping";
import FinalReviewUserData from "./modal/FinalReviewUserData";
import ConnectionDatabaseAuthentication from "./manage-auth/ConnectionDatabaseAuthentication";
import ConnectionDatabaseDetailsProvision from "./manage-connection/ConnectionDatabaseDetailsProvision";
import ConnectionDatabaseDetailsDeprovision from "./manage-connection/ConnectionDatabaseDetailsDeprovision";
import ConnectorDeProvision from "./provision-group-mapping/deProvisioning/ConnectordeProvision";

import { clearActiveStep, clearAllData } from "../../../../redux/slice/provisioning/ProvisioningSlice";
import "./AppProvisionConfiguration.scss";
import "./AppProvisionResponsive.scss";

const AppProvisionConfiguration = () => {
  const currentSteps = useSelector((state: any) => state?.provisioning);
  const dispatch = useDispatch();


  useEffect(() => {
    return () => {
      dispatch(clearAllData());
      dispatch(clearActiveStep());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="akku-container" data-testid="connector-detailsId">
      <div className="main-container app">
        <div className="w-full flex app-container">
          <div className="app-stepper bg-white">
            <ProvisionStepper />
          </div>
          <div className="app-form-container flex-1 ">
            {currentSteps?.activeStep === 0 && <ConnectorDetails />}
            {currentSteps?.activeStep === 1 && <ConnectionAuthentication />}
            {currentSteps?.activeStep === 2 && <ConnectorProvision />}
            {currentSteps?.activeStep === 3 && <ConnectorDeProvision />}
            {currentSteps?.activeStep === 4 && <ManageConnection />}
            {currentSteps?.activeStep === 5 && <ProvisionMappingAttributes />}
            {currentSteps?.activeStep === 6 && <ProvisionGroupMapping />}
            {currentSteps?.activeStep === 7 && <ProvisionRoleMapping />}
            {currentSteps?.activeStep === 8 && <FinalReviewUserData />}
            {currentSteps?.activeStep === 9 && <MappingUserStatus />}
            {currentSteps?.activeStep === 10 && <ConnectionDatabaseAuthentication />}
            {currentSteps?.activeStep === 11 && <ConnectionDatabaseDetailsProvision />}
            {currentSteps?.activeStep === 12 && <ConnectionDatabaseDetailsDeprovision />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppProvisionConfiguration;
