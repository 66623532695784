import React, { useEffect, useState } from "react";
import { Avatar, Tooltip } from "antd";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleRequestError } from "../toast/ErrorNotificationMessage";
import useGetApiRequests from "../../services/axios/useApiRequests";
import { hideOnRoutes, availableRoutes } from "./headerHelper";
import HeaderModal from "./HeaderModal";
import { handleLogout } from "../../User/UserDashboard/user-header/LogoutHelperFile";
import { retrieveData, retrieveDeviceData, storeData } from "../../services/storage/Storage";
import LogoutModal from "../modal/logout/LogoutModal";
import UnauthorizedModal from "../modal/unauthorized/UnauthorizedModal";
import checkWhitelistIP, { timeBasedRestriction } from "../../User/UserDashboard/checkWhitelistIP";
import { setRestrictionConfigure } from "../../redux/slice/restriction/RestrictionSlice";
import { setProfileDetails } from "../../redux/slice/profile/ProfileDetailsSlice";
import ScreenLoader from "../loader/ScreenLoader";
import PopupBlockModal from "../modal/popup-block/PopupBlockModal";
import "./Header.scss";
import PasswordAlertModal from "../../User/UserDashboard/password-modal/PasswordAlertModal";
import moment from "moment";
const Header = () => {
  const whiteLabelListUrl: any = process.env.REACT_APP_WHITE_LABEL_BASEURL;
  const timeBasedUrl: any = process.env.REACT_APP_TIME_BASED_BASEURL;
  const dispatch = useDispatch();
  const keycloak = useSelector((state: any) => state?.keycloak);
  const gettingLogoutUrls = useGetApiRequests("getLogoutUrls", "GET");
  const userSessionKilling = useGetApiRequests("logoutSessionKill", "POST");
  const location = useLocation();
  const [open, isOpen] = useState<boolean>(false);
  const [isActiveTimeBasedAccess, setIsActiveTimeBasedAccess] = useState<boolean>(false);
  const [isActiveIPBasedAccess, setIsActiveIPBasedAccess] = useState<boolean>(false);
  const [logoutLoader, setLogoutLoader] = useState<boolean>(false);
  const [openLogout, isOpenLogout] = useState<boolean>(false);
  const [isActivePopup, setIsActivePopup] = useState<boolean>(false);
  const [image, setImage] = useState("");
  const [userName, setUserName] = useState<any>({});
  const [profileUpdate, setProfileUpdate] = useState<boolean>(false);
  const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;
  const userId: string = keycloak?.KeycloakInfo?.subject || "";
  const profileDetails = useGetApiRequests("userProfileDetails", "GET");
  const [loader, setLoader] = useState(false);
  const deviceUrl = retrieveDeviceData("device");
  const realmName = retrieveData("realmName", true);
  const config = retrieveData("kcConfig", false);
  const [isPassword, setIsPassword] = useState<boolean>(false);
  const [forceSetPassword, setForceSetPassword] = useState<boolean>(false);

  const userLoginDetails = useSelector((state: any) => state?.dashboardDetails?.data?.userLoginKeyValueDto);

  const isVisitPopup = retrieveData("isVisitPopup", true);
  const checkPopupBlocker = () => {
    const popup = window.open("about:blank", "_blank");
    storeData("isVisitPopup", true, true);
    if (!popup || popup.closed || typeof popup.closed === "undefined") {
      setIsActivePopup(true);
    } else {
      popup?.close();
    }
  };

  useEffect(() => {
    const currentUrl = location.pathname;
    if ((!isVisitPopup && currentUrl === "/dashboard") || (!isVisitPopup && currentUrl === "/admin-dashboard") || ((!isVisitPopup || isVisitPopup == null) && currentUrl === "/")) {
      checkPopupBlocker();
    }
    storeData("isVisitPopup", true, true);
  }, [isVisitPopup]);

  useEffect(() => {
    userId && getDetails();
  }, [keycloak, profileUpdate]);

  const refreshTimeData = () => {
    handleTimeBasedRestriction();
  };

  const refreshIPData = () => {
    handleCheckWhitelistIP();
  };

  const handleTimeBasedRestriction = async () => {
    const isTimeAllowed = await timeBasedRestriction(realmName, userId, timeBasedUrl, setLoader);
    setIsActiveTimeBasedAccess(isTimeAllowed);
    dispatch(
      setRestrictionConfigure({
        isActive: isTimeAllowed,
        isActiveTime: true,
        restrictionType: "timeRestriction",
      }),
    );
  };

  /**
   * Handler function to check if an IP is whitelisted.
   * This function calls the checkWhitelistIP function with necessary parameters.
   */
  const handleCheckWhitelistIP = async () => {
    const isIpAllowed = await checkWhitelistIP(realmName, userId, whiteLabelListUrl, setLoader);

    if (!isIpAllowed) {
      if (config?.isTimeRestricitonEnabled) {
        handleTimeBasedRestriction();
        const intervalId = setInterval(refreshTimeData, 180000); // 3 minutes
        return () => clearInterval(intervalId);
      }
    }
    dispatch(
      setRestrictionConfigure({
        isActive: isIpAllowed,
        isActiveTime: false,
        restrictionType: "ipRestriction",
      }),
    );
    setIsActiveIPBasedAccess(isIpAllowed);
  };

  const logout = () => {
    handleLogoutModalClose();
    setLoader(true);
    handleLogout(dispatch, gettingLogoutUrls, userName, userSessionKilling, setLoader, deviceUrl, setLogoutLoader);
  };

  const redirectDashboard = () => {
    const url = "/dashboard";
    const newTab: any = window.open(url, "_blank");
    newTab.focus();
  };

  const handleModalOpen = () => {
    isOpen(true);
  };
  const handleModalClose = () => {
    isOpen(false);
  };
  const handleLogoutModal = () => {
    isOpenLogout(true);
  };
  const handleLogoutModalClose = () => {
    isOpenLogout(false);
  };

  const getDetails = async () => {
    const payload = {
      id: `${userId}/profile`,
    };
    try {
      const response: any = await profileDetails("", {}, payload);
      const status = response.status;

      if (status === 200) {
        const data = response.data.data;
        const userName = response?.data?.data?.username;
        storeData("userName", userName, true);
        setUserName(response.data.data);
        setIsPassword(!data?.passwordStatus);
        setForceSetPassword(data?.passwordLogin);
        dispatch(setProfileDetails(response.data.data));
        const profileImage = data.attributes.find((attribute: any) => attribute.name === "profileImage");
        setImage(profileImage?.value);
      }
    } catch (err: any) {
      handleRequestError(err);
    }
  };

  if (hideOnRoutes.includes(location.pathname)) {
    return null;
  }

  if (!availableRoutes.includes(location.pathname)) {
    return null;
  }
  const getUserInitial = (name: any) => {
    return name?.[0];
  };
  const handlePopupModalClose = () => {
    setIsActivePopup(false);
  };

  const formatWithOrdinal = (apiDate: any) => {
    // Function to get ordinal suffix (st, nd, rd, th)
    const getOrdinalSuffix = (day: any) => {
      if (day > 3 && day < 21) return 'th'; // 4th to 20th
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };

    const parsedDate = moment(apiDate, 'ddd DD MMMM YYYY, hh:mm A', true).isValid()
      ? moment(apiDate, 'ddd DD MMMM YYYY, hh:mm A')
      : moment();

    const day = parsedDate.date();
    const month = parsedDate.format('MMMM');
    const year = parsedDate.year();
    const time = parsedDate.format('hh:mm A');

    return `${day}${getOrdinalSuffix(day)} ${month} ${year}, ${time}`;
  };

  return (
    <div className="_header-container" data-testid="headerId">
      <div className="header-content">
        <div className="header-text">
          <h6 className="greet">
            Welcome <span>{(userName && userName?.firstName + " " + userName?.lastName) || "AKKU"}</span>
          </h6>
          <p className="last-seen">
            Last Login : <span>{formatWithOrdinal(userLoginDetails?.lastActivityLogin)}</span>
          </p>
        </div>
        <div className="header-profile">
          <div className="header-icon cursor-pointer" onClick={handleLogoutModal}>
            <Tooltip title="Logout">
              <span className="material-symbols-outlined profile-hover">power_settings_new</span>
            </Tooltip>
          </div>
          <div className="header-icon cursor-pointer  " onClick={redirectDashboard}>
            <Tooltip title="User Dashboard">
              <span className="material-symbols-outlined profile-hover">switch_account</span>
            </Tooltip>
          </div>
          <div className="header-user cursor-pointer">
            <Tooltip title="Profile">
              <Avatar
                onClick={handleModalOpen}
                className={`${image ? "cursor-pointer" : "bg-[#5441da]"}`}
                size="large"
                src={image ? imageBaseUrl + image : <p className="text-[#fff] font-semibold capitalize font-Inter initial">{getUserInitial(userName?.firstName)}</p>}
              />
            </Tooltip>
          </div>
        </div>
      </div>
      {loader && <ScreenLoader />}
      {isActivePopup && <PopupBlockModal open={true} handleModalClose={handlePopupModalClose} />}
      <LogoutModal refreshIPData={refreshIPData} handleModalClose={handleLogoutModalClose} open={openLogout} logout={logout} />
      {open && (
        <HeaderModal
          setFullPageLoader={setLoader}
          handleModalClose={handleModalClose}
          open={open}
          setProfileUpdate={setProfileUpdate}
          profileUpdate={profileUpdate}
          isOpen={isOpen}
          userHavePassword={isPassword}
          getDetails={getDetails}
        />
      )}

      {isActiveIPBasedAccess && <UnauthorizedModal loader={logoutLoader} modalType="ip" open={isActiveIPBasedAccess} handleModalClose={handleModalClose} logout={logout} isActiveBackButton={true} />}
      {isActiveTimeBasedAccess && (
        <UnauthorizedModal modalType="time" loader={logoutLoader} open={isActiveTimeBasedAccess} handleModalClose={handleModalClose} logout={logout} isActiveBackButton={true} />
      )}
      {isPassword && forceSetPassword && (
        <PasswordAlertModal setFullPageLoader={setLoader} isPassword={isPassword} forceSetPassword={forceSetPassword} handleClose={handlePopupModalClose} getDetails={getDetails} />
      )}
    </div>
  );
};
export default Header;
