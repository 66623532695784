import React from "react";
import ImageView from "../../../../../layouts/component/ImageView";
import { images } from "../../../../../assets/images/ImagePaths";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button, Select } from "antd";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import { setCurrentStep } from "../../../../../redux/slice/app-store/AppDetailsSlice";
import { useDispatch, useSelector } from "react-redux";

const ProvisionGroupMapping = () => {
  const dispatch = useDispatch();
  const provisioning = useSelector((state: any) => state?.provisioning);
  const formInitialValues = {
    forms: [
      { source: "Employee", target: "Employee" },
      { source: "Faculty", target: "Faculty" },
      { source: "Employee", target: "Employee" },
      { source: "Student", target: "Student" },
    ],
  };
  const adAttributeList: any = ["Employee", "Faculty"];
  const onSubmit = async (values: any) => {
    const updatedStepper = {
      activeStep: 4,
      childStepper: "",
    };
    dispatch(setCurrentStep(updatedStepper));
  };
  const handleOnChange = (setFieldValue: any, field: string, val: any, index: number, values: any) => {
    console.log(formInitialValues);
  };
  const handleBack = () => {
    const updatedStepper = {
      activeStep: 5,
      childStepper: "",
    };
    dispatch(setCurrentStep(updatedStepper));
  };
  const handleNext = () => {
    const updatedStepper = {
      activeStep: 7,
      childStepper: "",
    };
    dispatch(setCurrentStep(updatedStepper));
  };
  return (
    <div className="mapping-groups">
      <div className="w-full px-10 pb-5">
        <p className="app-header pt-8">Mapping - Groups {provisioning?.isDuplicate ? "(Copy)" : null}</p>
      </div>
      <div className="w-[80%] mx-auto pt-5 provision-group ">
        <Formik initialValues={formInitialValues} onSubmit={onSubmit}>
          {({ values, setFieldValue }) => (
            <Form>
              <div className="w-full px-[5%] flex provision-container">
                <div className="w-full flex">
                  <div className="justify-center w-[40%] ">
                    {values.forms.map((formItem: any, index: any) => (
                      <div className="w-full justify-center" key={formItem?.source}>
                        {index === 0 && <p className="group-title">SOURCE - Akku(4)</p>}
                        <div className=" w-full pb-6 mr-10 source relative">
                          <Field
                            as={Select}
                            suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                            className={" w-[100%] h-[56px] "}
                            name={`forms.${index}.source`}
                            values={values.forms[index]?.source}
                            onChange={(value: any) => handleOnChange(setFieldValue, "source", value, index, values)}
                          >
                            {adAttributeList?.map((option: any) => (
                              <Select.Option key={option} value={option}>
                                {option}
                              </Select.Option>
                            ))}
                          </Field>
                          <ErrorMessage name={`forms.${index}.source`} component="div" className="error-message custom-error" />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="w-[20%] flex justify-center ">
                    <div className="w-20 self-center img-arrow">
                      <ImageView src={images.rightArrow} />
                    </div>
                  </div>
                  <div className="w-[40%]  justify-center">
                    {values.forms.map((formItem: any, index: any) => (
                      <div className="w-full  justify-center" key={formItem?.source}>
                        {index === 0 && <p className="group-title">TARGET - Jira (3)</p>}
                        <div className=" w-full pb-6 mr-10 source relative">
                          <Field
                            as={Select}
                            suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                            className={" w-[100%] h-[56px] "}
                            name={`forms.${index}.target`}
                            values={values.forms[index]?.target}
                            onChange={(value: any) => handleOnChange(setFieldValue, "target", value, index, values)}
                          >
                            {adAttributeList?.map((option: any) => (
                              <Select.Option key={option} value={option}>
                                {option}
                              </Select.Option>
                            ))}
                          </Field>
                          <ErrorMessage name={`forms.${index}.source`} component="div" className="error-message custom-error" />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="footer">
                <div className="modal-footer w-full pr-5 mx-auto ">
                  <div className="w-full flex justify-end">
                    <CustomButtonBack onClick={handleBack} text={"Skip"} />
                    <CustomButtonBack onClick={handleBack} text={"Back"} />
                    <Button type="primary" onClick={handleNext} className="bg-[#5441DA] submit-btn">
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ProvisionGroupMapping;
