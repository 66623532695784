export const convertObjectToArray = (obj: any) => {
  if (!obj || typeof obj !== "object" || Object.keys(obj).length === 0) {
    return [{ key: "", value: "" }];
  }
  return Object.entries(obj).map(([key, value]) => ({ key: key, value: value || "" }));
};

export const initializeDeprovisioning = (deProvisionDetails: any, endpoint: any, headerParameters: any, pathVariable: any) => {
  const deProvisioningData = JSON.parse(JSON.stringify(deProvisionDetails));
  if (endpoint?.methodType.toLowerCase() === "delete") {
    deProvisioningData.userDeProvision[0].apiEndpointURL = endpoint.apiEndpointUrl;
    deProvisioningData.userDeProvision[0].methodType = endpoint.methodType;
    deProvisioningData.userDeProvision[0].akkuProvisioningConnectorEndpointConfigId = endpoint?.akkuProvisioningConnectorEndpointConfigId;
    deProvisioningData.userDeProvision[0].headerParam = headerParameters;
    deProvisioningData.userDeProvision[0].pathVariable = pathVariable;
    deProvisioningData.userDeProvision[0].requestPayload = endpoint?.requestPayload || "";
    deProvisioningData.userDeProvision[0].response = endpoint?.sampleResponse || "";
    return deProvisioningData;
  }
};

export const initializeProvisioning = (provisionDetails: any, endpoint: any, headerParameters: any, pathVariable: any) => {
  let provisioned = false;
  const provisionData = JSON.parse(JSON.stringify(provisionDetails));
  if (!provisioned && (endpoint?.endpointDescription.toLowerCase() === "create_user" || endpoint?.endpointDescription.toLowerCase() === "get_user")) {
    provisionData.userProvision[0].akkuProvisioningConnectorEndpointConfigId = endpoint?.akkuProvisioningConnectorEndpointConfigId;
    provisionData.userProvision[0].methodType = endpoint?.methodType;
    provisionData.userProvision[0].apiEndpointURL = endpoint?.apiEndpointUrl;
    provisionData.userProvision[0].tokenApiUrl = endpoint?.authToken;
    provisionData.userProvision[0].apiToken = endpoint?.tokenApiEndpoint;
    provisionData.userProvision[0].headerParam = headerParameters;
    provisionData.userProvision[0].pathVariable = pathVariable;
    provisionData.userProvision[0].requestPayload = endpoint?.requestPayload;
    provisionData.userProvision[0].response = endpoint?.sampleResponse;

    provisioned = true;
    return provisionData;
  }
};
