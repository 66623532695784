import { Button, Select } from "antd";
import { ErrorMessage, Field, FieldArray } from "formik";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentStep } from "../../../../../redux/slice/app-store/AppDetailsSlice";
import { setActiveStep } from "../../../../../redux/slice/provisioning/ProvisioningSlice";

const MappingConnectorAttributes = (props: any) => {
  const dispatch = useDispatch();

  const { values, setFormInitial, source, setFieldValue, target } = props;
  const provisioning = useSelector((state: any) => state?.provisioning);
  const handleOnChange = (field: string, index: number, val: any, values: any) => {
    setFieldValue(`forms.${index}.${field}`, val);

    const updatedForms = values?.forms?.map((formItem: any, ind: number) => {
      if (index === ind) {
        return {
          ...formItem,
          [field]: val,
        };
      }
      return formItem;
    });

    setFormInitial((prevValues: any) => ({
      ...prevValues,
      forms: updatedForms,
    }));
  };

  const handleRemove = (remove: any, index: number, value: any) => {
    remove(index);
  };

  const handleBack = () => {
    const updatedStepper = {
      childStepper: "",
      activeStep: 4,
    };
    dispatch(setCurrentStep(updatedStepper));
    dispatch(setActiveStep(4));
  };

  const handleFilterSource = (source: any) => {
    const storedSource = source || [];
    if (storedSource.length > 0) {
      const selectedSources = values?.forms?.map((formItem: any) => formItem?.source);
      const filteredSource = storedSource?.filter((option: any) => !selectedSources.includes(option));
      return filteredSource;
    }
    return storedSource;
  };

  const handleFilterTarget = (target: any) => {
    const storedTarget = target || [];
    if (storedTarget?.length > 0) {
      const selectedTargets = values?.forms?.map((formItem: any) => formItem?.target);
      const filteredTarget = storedTarget?.filter((option: any) => !selectedTargets?.includes(option));
      return filteredTarget;
    }
    return storedTarget;
  };

  return (
    <div>
      <FieldArray name="forms">
        {({ push, remove }) => (
          <div className="mapper mt-10 px-7 2xl:px-9 ms-3 2xl:ms-24 overflow-x-auto pb-5 2xl:mr-10 mr-2">
            {values?.forms?.map((formItem: any, index: any) => (
              <div key={formItem.userType} className="w-[500px] xl:w-full flex mapper-attributes items-center">
                <div className=" w-[50%] 2xl:w-[33%] max-w-[50%] min-w-[33%]   mr-10 source relative mt-0 2xl:mt-8 min-h-[75px] xl:min-h-[90px]">
                  {index === 0 && <p className="text-center absolute top-[-50px] w-full "> SOURCE - Akku</p>}

                  <Field
                    showSearch
                    allowClear={true}
                    suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                    name={`forms.${index}.source`}
                    as={Select}
                    data-testid="usersIt-input"
                    className={values.forms[index]?.isCheck ? " w-[100%] h-[56px] pointer-events-none cursor-not-allowed" : " w-[100%] h-[56px] "}
                    values={values.forms[index]?.source}
                    onChange={(value: any) => handleOnChange("source", index, value, values)}
                    filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {handleFilterSource(source)?.map((option: any) => (
                      <Select.Option key={option} value={option}>
                        {option}
                      </Select.Option>
                    ))}
                  </Field>
                  <ErrorMessage name={`forms.${index}.source`} component="div" className="error-message custom-error" />
                </div>
                <div className="w-[50%] 2xl:w-[33%] max-w-[50%] min-w-[33%]  mr-10 source relative mt-0 2xl:mt-8 min-h-[80px] xl:min-h-[90px] ">
                  {index === 0 && <p className="capitalize text-center absolute top-[-50px] w-full"> TARGET - {provisioning?.generalDetails?.name}</p>}

                  <Field
                    showSearch
                    allowClear={true}
                    suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                    as={Select}
                    name={`forms.${index}.target`}
                    className={values.forms[index]?.isCheck ? " w-[100%] h-[56px] pointer-events-none cursor-not-allowed" : " w-[100%] h-[56px] "}
                    values={values.forms[index]?.target}
                    onChange={(value: any) => handleOnChange("target", index, value, values)}
                    filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    data-testid="usersIe-input"
                  >
                    {handleFilterTarget(target)?.map((option: any) => (
                      <Select.Option key={option} value={option}>
                        {option}
                      </Select.Option>
                    ))}
                  </Field>
                  <ErrorMessage name={`forms.${index}.target`} component="div" className="error-message custom-error" />
                </div>

                <div className="w-[auto] flex justify-start ml-5 mt-0 2xl:mt-8 min-h-[75px] xl:min-h-[90px]">
                  <span
                    role="none"
                    className={`mapping-attributes-plus-icon add material-symbols-outlined ml-5 ${values.forms.length < target?.length ? "cursor-pointer" : "cursor-not-allowed"}`}
                    onClick={() => values.forms.length < target.length && push({ source: "", target: "", userType: "", isCheck: false, id: "" })}
                  >
                    add
                  </span>
                  {values.forms.length !== 1 && (
                    <span
                      role="none"
                      className={`mapping-attributes-plus-icon add remove material-symbols-outlined ml-5 ${values.forms.length === 1 ? "cursor-not-allowed text-gray-400" : "cursor-pointer"}`}
                      onClick={() => values.forms.length > 1 && handleRemove(remove, index, values.forms[index])}
                    >
                      remove
                    </span>
                  )}
                </div>
              </div>
            ))}
            <div className="footer">
              <div className="modal-footer w-full pr-5 mx-auto ">
                <div className="w-full flex justify-end">
                  <CustomButtonBack onClick={handleBack} text={"Back"} />

                  <Button type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn">
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  );
};

export default MappingConnectorAttributes;
