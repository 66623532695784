import React from "react";
import { Radio, Select, Button, Input } from "antd";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import CustomButtonBack from "../../../../layouts/component/CustomButtonBack";
import { validationSchemaForIp } from "./SelectIpValidationSchema";

const SelectIPAddForm = (props: any) => {
  const { isEdit, initialValues, onSubmit, chooseIpType, assignToValue, chooseAssigningTo, handleOpenCloseModal, ipErrorMessage, navigatedFrom } = props.props;
  return (
    <div className={`mt-5 select-ip ${isEdit && "hidden"} `}>
      <Formik initialValues={initialValues} validationSchema={validationSchemaForIp} onSubmit={onSubmit} enableReinitialize={true}>
        {({ handleSubmit, setFieldValue, values, errors }) => {
          return (
            <Form
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <div className="  w-full flex pt-0 selectIP-form flex-wrap">
                <div className=" w-[80%] flex pl-10 justify-between flex-wrap ">
                  <div className="mb-8 lg:mb-11 w-[100%] lg:w-[46%] relative">
                    <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Name*</p>
                    <Field as={Input} type="text" className="h-[48px] font-medium text-[18px]" name="name" maxLength={30} data-testid="selectIp-name" />
                    <ErrorMessage name="name" component="div" className="error-message" />
                  </div>
                  <div className="mb-8 lg:mb-11 w-[100%] lg:w-[46%] relative">
                    <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Description*</p>
                    <Field as={Input} type="text" className="h-[48px] font-medium text-[18px]" name="description" maxLength={100} data-testid="selectIp-description" />
                    <ErrorMessage name="description" component="div" className="error-message" />
                  </div>

                  <div className="mb-8 lg:mb-11 w-[100%] lg:w-[98%] relative">
                    <div className="md:w-[100%] lg:w-[46%]">
                      <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Choose type for IP*</p>
                      <Radio.Group name="authorizationType" value={values?.authorizationType} className="flex pt-3" onChange={(e) => chooseIpType(e.target.value, setFieldValue)}>
                        <Radio value={"Whitelist"}>
                          <div className="flex flex-col mr-5">
                            <p className="user-type ">Whitelist IP</p>
                          </div>
                        </Radio>
                        <Radio className="hidden" value={"Blacklist"}>
                          <div className="flex  flex-col">
                            <p className="user-type">Blacklist IP</p>
                          </div>
                        </Radio>
                      </Radio.Group>
                    </div>
                    <ErrorMessage name="authorizationType" component="div" className="error-message" />
                  </div>

                  {/* new design */}
                  <FieldArray name="ipDetails">
                    {({ push, remove }) => (
                      <>
                        {values.ipDetails.map((field: any, index: any) => {
                          let placeholder = "xxx.xxx.xxx.xxx";
                          let maxLength = 15;
                          let pattern = "^\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}$";
                          if (field.ipRange === "range16") {
                            placeholder = "xxx.xxx";
                            maxLength = 7;
                            pattern = "^\\d{1,3}\\.\\d{1,3}$";
                          } else if (field.ipRange === "range24") {
                            placeholder = "xxx.xxx.xxx";
                            maxLength = 11;
                            pattern = "^\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}$";
                          }
                          return (
                            <div className="w-full relative mt-4" key={field.id}>
                              <div className="w-full flex justify-between relative gap-2">
                                <div className="lg:mb-4 lg:w-[32%] md:w-[50%] relative lg:mt-0 mt-6">
                                  <p className={`${index === 0 ? "" : "lg:opacity-0"} text-[#1D1D24] text-[18px] font-Inter font-medium md:opacity-100`}>Range*</p>
                                  <Field
                                    as={Select}
                                    showSearch
                                    suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                                    className="w-[100%] h-[48px]"
                                    name={`ipDetails[${index}].ipRange`}
                                    value={field.ipRange}
                                    onChange={(value: any) => setFieldValue(`ipDetails[${index}].ipRange`, value)}
                                    filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    placeholder="Select"
                                  >
                                    <Select.Option value="exactIp">Exact IP</Select.Option>
                                    <Select.Option value="range16">Range 16</Select.Option>
                                    <Select.Option value="range24">Range 24</Select.Option>
                                  </Field>
                                  <ErrorMessage name={`ipDetails[${index}].ipRange`} component="div" className="error-message" />
                                </div>
                                <div className="lg:mb-4 lg:w-[32%] selectIP relative lg:mt-0 mt-6 ">
                                  {field.ipRange !== "" && field.ipRange !== "exactIp" && <div className="selectIP-highlighted">{field.ipRange === "range16" ? ".0.0/16" : ".0/24"}</div>}
                                  <p className={`${index === 0 ? "" : "lg:opacity-0"} text-[#1D1D24] text-[18px] font-Inter font-medium md:opacity-100`}>IPv4*</p>
                                  <Field
                                    placeholder={placeholder}
                                    data-testid="ip-address"
                                    as={Input}
                                    type="text"
                                    className="h-[48px] text-[18px] font-medium"
                                    name={`ipDetails[${index}].IPv4`}
                                    maxLength={maxLength}
                                    pattern={pattern}
                                    disabled={field.ipRange === ""}
                                    onChange={(event: any) => {
                                      const valueWithoutSpaces = event.target.value.replace(/\s+/g, "");
                                      setFieldValue(`ipDetails[${index}].IPv4`, valueWithoutSpaces);
                                    }}
                                  />
                                  <ErrorMessage name={`ipDetails[${index}].IPv4`} className="error-message" component="div" />
                                </div>

                                <div className="lg:mb-4 lg:w-[32%] selectIP relative lg:mt-0 mt-6">
                                  <p className={`${index === 0 ? "" : "lg:opacity-0"} text-[#1D1D24] text-[18px] font-Inter font-medium md:opacity-100`}>IPv6</p>
                                  <Field
                                    as={Input}
                                    disabled={field.ipRange !== "exactIp"}
                                    type="text"
                                    placeholder="xxxx:xxxx:xxxx:xxxx:xxxx:xxxx:xxxx:xxxx"
                                    className="h-[48px] text-[18px] font-medium"
                                    name={`ipDetails[${index}].IPv6`}
                                    onChange={(event: any) => {
                                      const valueWithoutSpaces = event.target.value.replace(/\s+/g, "");
                                      setFieldValue(`ipDetails[${index}].IPv6`, valueWithoutSpaces);
                                    }}
                                  />
                                  <ErrorMessage name={`ipDetails[${index}].IPv6`} className="error-message" component="div" />
                                </div>
                              </div>
                              <div className="flex right-[-130px] absolute top-7 items-center justify-center lg:mt-0 mt-6">
                                <span
                                  role="none"
                                  className={`${values.ipDetails.length === 1 ? "pointer-events-none" : ""} add-custom ip remove material-symbols-outlined cursor-pointer`}
                                  onClick={() => remove(index)}
                                >
                                  remove
                                </span>
                                {index === values.ipDetails.length - 1 && (
                                  <span role="none" className="cursor-pointer material-symbols-outlined add-custom ip" onClick={() => push({ ipRange: "", id: index, IPv4: "", IPv6: "" })}>
                                    add
                                  </span>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </FieldArray>

                  {/* end */}
                  {!navigatedFrom && (
                    <div className="mb-8 lg:my-11 w-[100%] lg:w-[98%] relative mt-16 lg:mt-8">
                      <div className="md:w-[100%] lg:w-[46%]">
                        <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Assign to*</p>
                        <Radio.Group value={assignToValue} className="flex pt-3" name="assignTo" onChange={(e: any) => chooseAssigningTo(e.target.value, setFieldValue)}>
                          <Radio value={"organization"}>
                            <div className="flex flex-col mr-5">
                              <p className="user-type ">Organization</p>
                            </div>
                          </Radio>
                          <Radio value="groups">
                            {/* <Radio value="groups" disabled={values?.authorizationType === "Blacklist"}> */}
                            <div className="flex  flex-col mr-5">
                              <p className="user-type ">Groups</p>
                            </div>
                          </Radio>
                          <Radio value="users">
                            {/* <Radio value="users" disabled={values?.authorizationType === "Blacklist"}> */}
                            <div className="flex  flex-col">
                              <p className="user-type ">Users</p>
                            </div>
                          </Radio>
                        </Radio.Group>
                      </div>
                      <ErrorMessage name="assignTo" component="div" className="error-message" />
                    </div>
                  )}
                </div>
                <hr />
                <div className="mr-4 flex justify-end w-full mt-2 pb-7 foot">
                  <CustomButtonBack text={"Cancel"} onClick={handleOpenCloseModal} />
                  <Button htmlType="submit" className="btn bg-[#5441DA] w-[192px] h-[55px] font-Inter text-[20px]" type="primary" disabled={ipErrorMessage !== ""}>
                    {values?.assignTo === "organization" || navigatedFrom ? "Save" : "Next"}
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SelectIPAddForm;
