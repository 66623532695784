import { Table } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import type { TableColumnsType } from "antd";
import DeleteRoles from "../modal/DeleteRoles";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../services/storage/Storage";
import { useNavigate } from "react-router-dom";
import { setRolesResponse } from "../../../redux/slice/roles-permission/RolesAndPermissionSlice";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
interface DataType {
  key: React.Key;
  role: string;
  description: string;
  action: string;
  isEdit: boolean;
}
const RoleManagementTable = (props: any) => {
  const { search } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const getAvailableRolesList = useGetApiRequests("getAvailableRolesList", "POST");
  const realmId = retrieveData("realmId", true);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [roleId, setRoleId] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [rolesTableDetails, setRolesTableDetails] = useState<DataType[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    setLoader(true);
    debouncedRoleList(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, isDeleted]);

  const handleDeletePopup = (record: any) => {
    setRoleId(record.key);
    setOpenModal(true);
  };

  // Debounced API call function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRoleList = useCallback(
    debounce((search) => {
      getRolesLists(search);
    }, 800),
    [],
  );

  const getRolesLists = async (search: any) => {
    const pathParam = {
      realmId: realmId,
      list: "list",
    };
    const payload = {
      roleNameSearch: search,
    };
    try {
      const response: any = await getAvailableRolesList(payload, {}, pathParam);
      const status = response.status;
      if (status === 200) {
        const responseData = response?.data?.data;
        const roleDetails = responseData.map((elm: any) => {
          return {
            key: elm.id,
            role: elm.name,
            description: elm.description,
            isEdit: elm.name.toLocaleLowerCase() === "admin",
            attributes: elm.attributes,
            clientRole: elm.clientRole,
          };
        });
        const defaultData = {
          key: 0,
          role: "User",
          description: "Default user",
          isEdit: true,
        };

        if (search.length === 0 || defaultData.role.toLowerCase().includes(search.toLowerCase())) {
          roleDetails.push(defaultData);
        }
        const compareRoles = (a: any, b: any) => {
          if (a.role.toLowerCase() === "user" || a.role.toLowerCase() === "admin") {
            return -1; // "User" or "Admin" comes first
          }
          if (b.role.toLowerCase() === "user" || b.role.toLowerCase() === "admin") {
            return 1; // "User" or "Admin" comes first
          }
          return 0; // Default order
        };
        const sortedData = [...roleDetails].sort(compareRoles);

        setRolesTableDetails(sortedData);
      }
      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const handleEditRole = (record: any) => {
    let editPermission = {
      ...record,
      isEdit: true,
    };

    dispatch(setRolesResponse(editPermission));
    navigate("/role-management/configure-role");
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: "Role",
      dataIndex: "role",
      render: (text: any, record: any) => (
        <span
          role="none"
          onClick={() => {
            handleEditRole(record);
          }}
          className={`${record.isEdit ? " pointer-events-none opacity-90" : "cursor-pointer"} text-[#5441da] capitalize`}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "50%",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "20%",
      render: (text: any, record: any) => (
        <span
          role="none"
          className={`${record.isEdit ? " pointer-events-none opacity-50" : "cursor-pointer"} material-icons-outlined`}
          onClick={() => {
            handleDeletePopup(record);
          }}
        >
          delete
        </span>
      ),
    },
  ];

  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <div className="roles-table">
      <p>Total roles count : {rolesTableDetails?.length}</p>
      <div className="text-[16px] font-medium font-Inter italic text-[#5441da] pt-3 pb-3">
        Note: <span className="text-[#3a3a3a]">Predefined roles like Admin and User are essential for system operation and cannot be changed.</span>
      </div>
      <Table columns={columns} dataSource={rolesTableDetails} data-testid="roles-table" loading={loader} />
      {openModal && <DeleteRoles setLoader={setLoader} isDeleted={isDeleted} setIsDeleted={setIsDeleted} roleId={roleId} openModal={openModal} handleClose={handleClose} />}
    </div>
  );
};

export default RoleManagementTable;
