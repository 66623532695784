import { useEffect, useState } from "react";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { retrieveData } from "../../../../services/storage/Storage";
import { AddUserProvider } from "./add-user-context/AddUserContext";
import ConnectedDirectory from "./adduser/connected-directory/ConnectedDirectory";
import { UserProvider } from "./userContext/UserContext";
import UserDashBoard from "./UserDashBoard";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { useDispatch, useSelector } from "react-redux";
import { setGroupEditAndDelete } from "../../../../redux/slice/GroupSlice";
import Loader from "../../../../layouts/component/Loader";
import { setUserManagementLoader } from "../../../../redux/slice/UserManagementSlice";

export default function UserManagement() {
  const getComponentList = useGetApiRequests("getComponentList", "GET");
  const realmName = retrieveData("realmName", true);

  const [connectionList, setConnectionList] = useState<any>([]);
  const toggleDashboard = useSelector((state: any) => state?.UserDashboardUpdateSlice?.updateDashboard);
  const userManagementLoader = useSelector((state: any) => state?.userManagementSlice?.userManagementLoader);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState<boolean>(userManagementLoader);
  useEffect(() => {
    getConnectionList();
  }, [toggleDashboard]);
  const getConnectionList = async () => {
    setLoader(true);
    let payload = {
      realmName: realmName,
    };
    try {
      const response: any = await getComponentList("", payload);
      const status = response.status;
      if (status === 200) {
        setConnectionList(response.data);
        const hasData = response?.data?.length > 0;
        dispatch(setGroupEditAndDelete(hasData));
      }
      setLoader(false);
      dispatch(setUserManagementLoader(false));
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
      dispatch(setUserManagementLoader(false));
    }
  };

  return (
    <div className="akku-container">
      {loader && <Loader />}
      <div className="main-container">
        {connectionList.length !== 0 && (
          <div className="dashboard-container mb-5 connected-directory rounded">
            <ConnectedDirectory connectionList={connectionList} />
          </div>
        )}
        <div className="dashboard-container _set-height rounded">
          <UserProvider>
            <AddUserProvider>
              <UserDashBoard />
            </AddUserProvider>
          </UserProvider>
        </div>
      </div>
    </div>
  );
}
